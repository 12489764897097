<template>
  <vSelect type="text" v-bind="$attrs" :options="options" @search="search" v-model="valor" @input="$emit('input', emitValue)">
    <template v-slot:no-options="{ search, searching }">
        <template v-if="searching && search.length >= 3">
            Nada encontrado com <em>{{ search }}</em>
        </template>
        <em style="opacity: 0.5;" v-else>Escreva pelo menos <strong>3</strong> caracteres</em>
    </template>
  </vSelect> 
</template>

<script>
import vSelect from 'vue-select'
import "vue-select/src/scss/vue-select.scss";

vSelect.props.components.default = () => ({  
  OpenIndicator: {
    render: createElement => createElement('i', {class: {'fas fa-caret-down': true}}),
  },
});

export default {  
  components:{
    vSelect
  },
  name: 'Select3', 
  props: ['options', 'search'], 
  data: () => {
    return {         
      valor:''     
    }
  },
  computed: {
    emitValue: function () {
      return this.valor?.value || '';
    }
  },
  mounted() {
    this.valor = this.options.filter((v) => v.value == this.$attrs.value)[0];
  },
  watch: {    
    '$attrs.value': function (newVal, oldVal) {
      if (newVal == '') {
        this.valor = null
      }      
    }
  }
}
</script>

<style>
.vs__search::placeholder,
  .vs__dropdown-toggle,
  .vs__dropdown-menu {    
    background: #FFF !important;
    border-color: #ced4da;
    /* min-height: 38px;         */
  } 

.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__clear,
.vs--disabled .vs__search,
.vs--disabled .vs__selected,
.vs--disabled .vs__open-indicato {
  background: #E9ECEF !important;
}
</style>
