var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    (_vm.inverso == null ? _vm.variavelBooleana : !_vm.variavelBooleana)
      ? _c("i", { staticClass: "fas fa-check text-success" })
      : _c("i", { staticClass: "fas fa-times text-danger" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }