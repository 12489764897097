var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "form-group col-md-" + _vm.column },
    [
      _c("label", { staticClass: "text-black", attrs: { for: _vm.name } }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      !_vm.input
        ? _c(
            "select",
            _vm._b(
              {
                class: "form-control " + (_vm.errorMessage ? "is-invalid" : ""),
                attrs: { id: _vm.name, name: _vm.name },
                on: {
                  input: function ($event) {
                    return _vm.$emit("input", $event.target.value)
                  },
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.enterKey.apply(null, arguments)
                  },
                },
              },
              "select",
              _vm.$attrs,
              false
            ),
            [
              _vm.comEscolhaVazia ? _c("option") : _vm._e(),
              _vm._l(_vm.options, function (option) {
                return _c(
                  "option",
                  {
                    key: _vm.name + "-" + option.id,
                    domProps: { value: option.id },
                  },
                  [_vm._v(" " + _vm._s(option.name) + " ")]
                )
              }),
            ],
            2
          )
        : _c(
            "select-3",
            _vm._b(
              {
                attrs: { options: _vm.optionsForSelect3, search: _vm.search },
                model: {
                  value: _vm.val,
                  callback: function ($$v) {
                    _vm.val = $$v
                  },
                  expression: "val",
                },
              },
              "select-3",
              _vm.$attrs,
              false
            )
          ),
      _vm.errorMessage
        ? _c("div", { staticClass: "invalid-feedback d-block" }, [
            _vm._v(" " + _vm._s(_vm.errorMessage[0]) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }