<template>
  <div class="home">
    <Topbar />
    <div class="container-fluid text-left" style="margin-top: 90px">
      <!-- Content Row -->

      <div class="card shadow mb-4">
        <div class="card-header bg-secondary py-3">
          <h6 class="m-0 font-weight-bold text-white">
            <i :class="`${title.icon} mr-2`"></i>{{ title.text }}
            <div v-show="carregando" class="spinner-grow" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </h6>
        </div>
        <fieldset :disabled="carregando">
          <div class="card-body bg-light text-primary-dark">
            <p>
              <a
                class="btn btn-link"
                data-toggle="collapse"
                href="#filtrocard"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <i class="fas fa-filter"></i>
                Filtro
              </a>
              <router-link class="btn btn-link" :to="`/${baseUrl}/insert`"
                ><i class="fa fa-fw fa-plus"></i> Adicionar
              </router-link>
            </p>
            <div class="collapse show" id="filtrocard">
              <div class="card card-body bg-light">
                <div class="form-row align-items-center">
                  <InputAndLabel
                      column="3"
                      name="pesquisa"
                      label="Pesquisa"
                      type="text"
                      v-model="filtro.pesquisa"
                  />                  
                  <div class="form-group col-md-auto pt-md-2">
                    <button
                      type="submit"
                      class="btn btn-primary form-control mt-md-4"
                      id="pesquisar"
                      @click="list"
                    >
                      Pesquisar
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div class="row">
              <div class="col-lg-12 table-responsive">
                <table class="table table-sm table-striped table-bordered">
                  <thead>
                    <tr style="white-space: nowrap">
                      <th></th>
                      <TableHeader db="nome" label="Nome" />
                      <TableHeader db="login" label="Login" />
                      <TableHeader db="inativo" label="Ativo" />
                      <TableHeader db="perfil" label="Perfil" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="line in dados" :key="line[idname]">
                      <td>
                        <router-link
                          :to="`/${baseUrl}/${line[idname]}`"
                          data-toggle="tooltip"
                          title="Editar/Visualizar"
                          ><i class="fa fa-edit"></i
                        ></router-link>
                      </td>
                      <td>{{ line.nome }}</td>
                      <td>{{ line.login }}</td>
                      <td><CertoErrado :variavel="line.inativo" inverso /></td>
                      <td>{{ line.perfil }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <TableFooter />
          </div>
        </fieldset>
      </div>
    </div>
    <ForbiddenModal />
    <LogoutModal />
  </div>
</template>

<script>
// @ is an alias to /src
import Topbar from "@/components/Topbar.vue";
import LogoutModal from "@/components/LogoutModal.vue";
import TableHeader from "@/components/TableHeader.vue";
import ViewMixin from "./ViewMixin";
import api from "../services/api";
import $ from "jquery";
import {
    ClientErrorException,
  DEFAULT_ERROR_MESSAGE,
  PermissaoException,
  ServerErrorException,
  TokenInvalidoException,
} from "../services/exceptions";
import { enterKey } from "../utils";
import ForbiddenModal from '../components/ForbiddenModal.vue';
import CertoErrado from '../components/CertoErrado.vue';
import TableFooter from '../components/TableFooter.vue';
import InputAndLabel from "../components/InputAndLabel.vue";

export default {
  name: "Usuarios",
  mixins: [ViewMixin],
  components: {
    Topbar,
    LogoutModal,
    TableHeader,
    ForbiddenModal,
    CertoErrado,
    TableFooter,
    InputAndLabel
  },
  data: () => {
    return {
      idname: 'idusuario',
      baseUrl: "usuarios",
      title: {
        icon: "fas fa-users",
        text: "Usuários"
      },      
      sort: "nome",
      sortDirection: "asc",
      filtro: {
        pesquisa: "",
      },
    };
  },
  mounted() {    
    this.list();
  },
  methods: {
    enterKey,    

    async list() {
      this.carregando = true;

      try {
        // $("#filtrocard").collapse("hide");

        let { baseUrl, sort, sortDirection, filtro, perPage, pular } = this;
        let response = await api.get(`/${baseUrl}`, {
          params: {
            sort: `${sort} ${sortDirection}`,
            ...filtro,
            perPage,
            pular
          },
        });

        this.dados = response.data.dados;
        this.totalRows = response.data.totalRows;
      } catch (e) {
        if (e instanceof TokenInvalidoException) {
          this.handleTokenInvalidoException();
        } else if (e instanceof PermissaoException) {
          this.handlePermissaoException();
        } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
          alert(e.message);
        } else {
          alert(DEFAULT_ERROR_MESSAGE);
        }
      }

      this.carregando = false;
    },
  },
};
</script>

<style scoped lang="scss">

.spinner-grow {
  width: 1em;
  height: 1em;
}

@media only screen and (min-width: 768px) {
}
</style>
