var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: "form-group col-md-" + _vm.column }, [
    _c("label", { staticClass: "text-black", attrs: { for: _vm.name } }, [
      _vm._v(_vm._s(_vm.label)),
    ]),
    _c(
      "textarea",
      _vm._b(
        {
          class: "form-control " + (_vm.errorMessage ? "is-invalid" : ""),
          attrs: { id: _vm.name, name: _vm.name, rows: _vm.rows },
          on: {
            input: function ($event) {
              return _vm.$emit("input", $event.target.value)
            },
          },
        },
        "textarea",
        _vm.$attrs,
        false
      )
    ),
    _vm._v(" "),
    _vm.errorMessage
      ? _c("div", { staticClass: "invalid-feedback d-block" }, [
          _vm._v(" " + _vm._s(_vm.errorMessage[0]) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }