import { deleteAdminToken } from "./api";

export const DEFAULT_ERROR_MESSAGE = "Ocorreu um erro, tente novamente mais tarde";

export class CheckedException {

    message;
    name;

    constructor(message = "", name = null) {
        this.message = message;
        this.name = name;
    }
}

export class ValidationException extends CheckedException{

    errors = [];
    errorMessage = "";

    constructor(errors, errorMessage = ''){   
        super();     
        this.errors = errors;
        this.errorMessage = errorMessage;
    }
}

export class SenhaInvalidaException extends ValidationException{    

    constructor(errors, errorMessage = ''){   
        super(errors, errorMessage);        
    }
}

export class ClientErrorException extends CheckedException {      

    constructor(message){        
        super(message || DEFAULT_ERROR_MESSAGE);        
    }
}

export class ServerErrorException extends CheckedException {      

    constructor(message){        
        super(message || DEFAULT_ERROR_MESSAGE);        
    }
}

export class TokenInvalidoException extends CheckedException {    

    constructor(){                
        super("Sua sessão expirou");
        deleteAdminToken();
    }
}

export class PermissaoException extends CheckedException {    

    constructor(){                
        super("Acesso Negado");        
    }
}

export class VoltarParaPrimeiraPerguntaException extends CheckedException {
    constructor(){        
        super();
    }
}