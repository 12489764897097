import axios from "axios";
import { getEnv } from "../utils";
import { ServerErrorException, ValidationException, TokenInvalidoException, PermissaoException, ClientErrorException } from "./exceptions";

const api = axios.create({ baseURL: getEnv('VUE_APP_API_BASE_URL') });

api.interceptors.request.use(async config => {
    const token = getAdminToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    let exception;
    let response = error.response;

    if (response) {
        let status = response.status;

        if (status >= 400 || status <= 499) {

            if (status == 401) {
                exception = new TokenInvalidoException();
            } else if (status == 403) {
                exception = new PermissaoException();
            } else {
                var data = response.data;

                if (data.exception) {
                    switch (data.exception) {
                    case "ValidationException":
                        exception = new ValidationException(data.errors, data.errorMessage || data.message || "");
                        break;
                    }
                } else {
                    exception = new ClientErrorException(data.errorMessage || null);
                }
            }

        } else {
            exception = new ServerErrorException(data.errorMessage || null);
        }
    }


    return Promise.reject(exception || error);
});

export default api;

//54.233.253.39
//api.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
//api.defaults.headers.post['Access-Control-Allow-Origin'] = '*';


export var ADMIN_TOKEN = "";
export var USERNAME = "";
export var ROTA_DE_RETORNO = "";

export function getAdminToken() {
    let token = ADMIN_TOKEN || localStorage.getItem('admin-token');
    return token;
}
export function setAdminToken(token) {
    ADMIN_TOKEN = token;
    localStorage.setItem('admin-token', ADMIN_TOKEN);
}
export function deleteAdminToken() {
    ADMIN_TOKEN = null;
    localStorage.removeItem('admin-token');
}

export function getUsername() {
    let username = USERNAME || localStorage.getItem('admin-username');
    return username;
}
export function setUsername(username) {
    USERNAME = username;
    localStorage.setItem('admin-username', USERNAME);
}
export function deleteUsername() {
    USERNAME = null;
    localStorage.removeItem('admin-username');
}

export function getRotaDeRetorno() {
    return ROTA_DE_RETORNO;
}

export function setRotaDeRetorno(rota) {
    if (rota != '/login') {
        ROTA_DE_RETORNO = rota;
    }    
}