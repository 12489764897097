<template>
  <div class="modal fade show" :id="id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
    <div :class="`modal-dialog ${modalSize}`" role="document">
      <div class="modal-content">
        <div class="modal-header bg-secondary text-primary">
          <h5 class="modal-title text-white" id="exampleModalLabel">
            {{title}}
            <Carregando v-show="carregando"/>
          </h5>
          <button class="close" type="button" @click="completeCancelFunction" aria-label="Close">
            <span class="text-white" aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body" style="overflow-x: hidden;">
          <slot />
        </div>
        <div class="modal-footer" v-show="showFooter">
          <button class="btn btn-secondary" type="button" @click="completeCancelFunction">Cancelar</button>
          <!-- <button v-else class="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>           -->

          <button v-if="!buttonList" :class="confirmClass || 'btn btn-primary'" @click="confirmFunction">
            {{confirmText || 'Confirmar'}}
          </button>
          <button v-for="(button, index) in buttonList" :key="id+'-button-'+index" :class="button.confirmClass || 'btn btn-primary'" @click="button.confirmFunction">
            {{button.confirmText}}
          </button>
        </div>
      </div>
    </div>
  </div> 
</template>

<script>
import Carregando from './Carregando.vue';
import $ from 'jquery';
export default {
  components: { Carregando },
  name: "Modal",
  props: ['id', 'title', 'message', 'confirmText', 'confirmFunction', 'confirmClass', 'buttonList', 'large', 'small', 'carregando', 'cancelFunction', 'noFooter'],
  computed: {
    modalSize: function(){
      if (this.large != null) {
        return 'modal-lg'
      } if (this.small != null) {
        return 'modal-sm'
      } else {
        return ''
      }
    },
    showFooter: function(){
      return this.noFooter == null;
    }
  },
  methods: {
    completeCancelFunction(){
      $("#"+this.id).modal("hide");
      if (this.cancelFunction) {
        this.cancelFunction();
      }      
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-dialog{
    overflow-y: initial !important
}
.modal-body{
  max-height: 70vh;
  overflow-y: auto;
}
</style>