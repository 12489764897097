<template>
  <Modal
    id="logoutModal"
    title="Pronto para partir?"
    confirmText="Sair"
    :confirmFunction="$parent.logout"
  >
    Selecione "Sair" abaixo se você estiver pronto para encerrar sua sessão
    atual.
  </Modal>
</template>

<script>
import Modal from "./Modal";
export default {
  name: "LogoutModal",
  components: {
    Modal,
  },
};
</script>

<style>
</style>