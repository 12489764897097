var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: "form-group col-md-" + _vm.column }, [
    _c("label", { staticClass: "text-black", attrs: { for: _vm.name } }, [
      _vm._v(_vm._s(_vm.label)),
    ]),
    _c(
      "input",
      _vm._b(
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.date,
              expression: "date",
            },
            {
              name: "mask",
              rawName: "v-mask",
              value: "##/##/####",
              expression: "'##/##/####'",
            },
          ],
          class: "form-control " + (_vm.errorMessage ? "is-invalid" : ""),
          attrs: {
            id: _vm.name,
            name: _vm.name,
            type: "text",
            maxlength: "10",
          },
          domProps: { value: _vm.date },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.date = $event.target.value
              },
              function ($event) {
                return _vm.$emit("input", _vm.dateFormated)
              },
            ],
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.enterKey.apply(null, arguments)
            },
          },
        },
        "input",
        _vm.$attrs,
        false
      )
    ),
    _vm.errorMessage
      ? _c("div", { staticClass: "invalid-feedback d-block" }, [
          _vm._v(" " + _vm._s(_vm.errorMessage[0]) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }