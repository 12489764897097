<script>
import {
  deleteAdminToken,
  deleteUsername,
  setRotaDeRetorno,
} from "../services/api";
import $ from "jquery";
import { focar, clicar, enterKey, maskCNPJCPF, getEnv, dateFormat, moneyFormat, decimalFormat, intFormat, getDate, encodeQueryData } from "../utils";

export default {
  data: function () {
    return {
      idname: "",      
      operation: "insert",
      baseUrl: "",
      title: {
        icon: "",
        text: ""
      },
      needConfirmToLeave: false,
      carregando: false,
      errors: [],
      messageType: "",
      message: "",
      dados: {},
      totais: {},
      combos: {},
      filtro: {},
      perPage: 25,
      pular: 0,
      totalRows: 0,
    };
  },
  methods: {
    $,
    clicar,
    focar,
    enterKey,
    maskCNPJCPF,
    dateFormat,
    moneyFormat,
    decimalFormat,
    intFormat,
    getDate,
    encodeQueryData,

    populateFilterFromQueryString() {
      const params = new URLSearchParams(window.location.href.split("?")[1]);

      params.forEach((value, key) => {
          if (this[key] != null) {
              if (key == 'sort') {
                  this.sort = value.split(" ")[0];
                  this.sortDirection = value.split(" ")[1];
              } else {
                  this[key] = value;
              }
          } else {
              this.filtro[key] = value;
          }
      });
    },

    defineOperation(id) {
      if (id === 'insert') {
        this.operation = 'insert';
      } else {
        this.operation = 'update';
      }
    },

    isDataLoaded() {
      return Object.keys(this.dados).length > 0;
    },
    getDataToInsert(fieldsToDelete = []) {
      let data = this.getRawData(fieldsToDelete);
      delete data[this["idname"]];
      return data;
    },
    getDataToUpdate(fieldsToDelete = []) {
      let retorno = {},
        data = this.dados;
      for (var key in data) {
        let campoDataSet = data[key];
        if (
          !fieldsToDelete.find((element) => element == key)
          && campoDataSet.value != campoDataSet.oldValue
        ) {
          retorno[key] = campoDataSet.value;
        }
      }
      delete retorno[this.idname];
      return retorno;
    },
    getRawData(fieldsToDelete = []) {
      let rawData = {};
      for (var key in this.dados) {
        if (!fieldsToDelete.find((element) => element == key)) {
          rawData[key] = this.dados[key].value;
        }
      }
      return rawData;
    },
    getValueData(data) {
      return this.dados[data]?.value;
    },
    logout() {
      $("#logoutModal").modal("hide");
      deleteAdminToken();
      deleteUsername();
      this.$router.push("/login");
    },
    setData(data, nonDSFields = []) {
      for (var key in data) {
        const value = data[key];
        let ds
        if (nonDSFields.find((element) => element == key)) {
          ds = value;
        } else {
          ds = { value, oldValue: value };
        }        
        this.registrarData(this.dados, key, ds);
        //this.dados[key] = ds;
        //this.$set(this.dados[key].value, key);
        //this[key] = this.dados[key].value;
      }
    },
    updateData(data) {
      this.setData(data);
    },
    registrarData(obj, key, value) {     
      this.$set(obj, key, value);      
    },
    setMessage(message, type) {
      this.message = message;
      this.messageType = type;
    },
    handleValidationException(e) {
      this.errors = e.errors;
      this.setMessage(e.errorMessage, "danger");

      let campos = Object.keys(e.errors);
      if (campos.length > 1) {
        focar("#" + campos[0]);
      }
    },
    handlePermissaoException() {
      $("#forbiddenModal").modal("show");
    },
    handleTokenInvalidoException() {
      alert("Sua sessão expirou, redirecionando para tela de login");
      $(".modal").modal("hide"); // closes all active pop ups.
      $(".modal-backdrop").remove(); // removes the grey overlay.
      setRotaDeRetorno(this.$route.path);
      this.$router.push("/login");
    },
    changeURL(url, push = false) {
      let newUrl =
        location.protocol + "//" + location.hostname + ":" + location.port;
      if (process.env.NODE_ENV === "production") {
        newUrl += getEnv('VUE_APP_APP_FOLDER');
      }
      if (this.$router.mode == "hash") {
        newUrl += "/#";
      }
      newUrl += url;

      if (newUrl != location.href) {
        if (push) {
          history.pushState({}, null, newUrl);
        } else {
          history.replaceState({}, null, newUrl);
        }
      }
    },
    voltarHistorico() {
      $(".modal").modal("hide"); // closes all active pop ups.
      $(".modal-backdrop").remove(); // removes the grey overlay.
      this.$router.go(-1);
    },
    areYouSure(next) {
      if (this.needConfirmToLeave) {
          next(confirm('Você tem alterações não salvas, tem certeza que deseja sair?'));
      } else {
          next();
      }
    }
  },

  watch: {
    filtro: {
      deep: true,
      handler: function(){
        this.dados = [];
        this.totais = [];
      }
    },
    dados: {
      deep: true,
      handler: function(newVal, oldVal) {
        if (Object.keys(this.getDataToUpdate()).length > 0) {
          this.needConfirmToLeave = true;
        } else {
          this.needConfirmToLeave = false;
        }
      }
    }
  }
};
</script>

<style >
html,
body {
  overflow: auto !important;
}

.spinner-grow {
  width: 1em !important;
  height: 1em !important;
}
</style>