<template>
    <div>
        <Topbar />
        <div class="card shadow mx-3 conteudo text-left" style="margin-top: 90px">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary-dark">
                    <i :class="`${title.icon} mr-2`"></i>{{ title.text }}
                    <div v-show="carregando" class="spinner-grow" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </h6>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-12">
                        <fieldset v-if="isDataLoaded()" :disabled="carregando">
                            <Quadro :type="messageType" :message="message" />
                            <form>
                                <div class="d-print-none">
                                    <router-link
                                        :to="`/${this.baseUrl}`"
                                        id="btn_voltar"
                                        class="btn btn-secondary mr-1"
                                    >
                                        <i class="fa fa-arrow-left"></i> Voltar</router-link
                                    >
                                    <button type="button" id="btnSave" class="btn btn-primary mr-1" @click="salvar">
                                        Salvar
                                    </button>
                                    <button v-if="operation == 'update'" type="button" id="btnDuplicate" class="btn btn-primary mr-1" @click="duplicar">
                                        <i class="fas fa-copy"></i> Duplicar
                                    </button>
                                    <button
                                        type="button"
                                        id="btn_del"
                                        class="btn btn-danger mr-1"
                                        :disabled="operation === 'insert'"
                                        data-toggle="modal"
                                        data-target="#modalExcluir"
                                    >
                                        Excluir
                                    </button>
                                    <hr />
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <fieldset>
                                            <div class="form-row">
                                                <InputAndLabel
                                                    column="1"
                                                    name="id"
                                                    label="ID"
                                                    type="text"
                                                    v-model="dados[idname].value"
                                                    readonly
                                                />
                                                <InputAndLabel
                                                    column="4"
                                                    name="nome"
                                                    label="Nome"
                                                    type="text"
                                                    v-model="dados.nome.value"
                                                    :errorMessage="errors.nome"
                                                />
                                                <SelectAndLabel
                                                    input
                                                    column="2"
                                                    name="set_qtde"
                                                    label="Definição Qtde."
                                                    :options="combos.set_qtde"
                                                    v-model="dados.set_qtde.value"
                                                    :errorMessage="errors.set_qtde"
                                                />
                                                <SelectAndLabel
                                                    input
                                                    column="2"
                                                    name="set_valorunit"
                                                    label="Definição Valor Unit."
                                                    :options="combos.set_valorunit"
                                                    v-model="dados.set_valorunit.value"
                                                    :errorMessage="errors.set_valorunit"
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="valor"
                                                    label="Valor Padrão"
                                                    type="number"
                                                    v-model="dados.valor.value"
                                                    :errorMessage="errors.valor"
                                                    right
                                                />
                                                <div class="form-group mt-md-2 pt-md-4 ml-md-3 ml-2">
                                                    <CheckboxAndLabel
                                                        name="val-inativo"
                                                        v-model="dados.inativo.value"
                                                        label="Inativo"
                                                        :errorMessage="errors.inativo"
                                                    />
                                                </div>
                                            </div>
                                            <hr />
                                        </fieldset>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="card shadow" v-if="operation == 'update'">
                                            <div class="card-header bg-secondary py-3">
                                                <h6 class="m-0 font-weight-bold text-white row">
                                                    <div class="align-self-center">
                                                        <i class="fas fa-ruler-horizontal"></i> Valores
                                                    </div>
                                                    <div v-show="carregando" class="spinner-grow" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </h6>
                                            </div>

                                            <div class="p-0 card-body">
                                                <div class="col-lg-12 table-responsive">
                                                    <table class="table table-sm table-striped table-bordered my-3">
                                                        <thead>
                                                            <tr style="white-space: nowrap">
                                                                <th>
                                                                    <button
                                                                        type="button"
                                                                        class="btn btn-primary"
                                                                        @click="() => abrirModalVal('insert')"
                                                                    >
                                                                        Lançar
                                                                    </button>
                                                                </th>
                                                                <th>Base</th>
                                                                <th class="text-right">Valor</th>
                                                                <th>Ativo</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                v-for="line in dados.vals"
                                                                :key="'val-' + line.idocorrencias_val"
                                                            >
                                                                <td>
                                                                    <a
                                                                        data-toggle="tooltip"
                                                                        title="Editar/Visualizar"
                                                                        role="button"
                                                                        class="text-primary"
                                                                        @click="
                                                                            () =>
                                                                                abrirModalVal(
                                                                                    line.idocorrencias_val
                                                                                )
                                                                        "
                                                                    >
                                                                        <i class="fa fa-edit"></i>
                                                                    </a>
                                                                    <a
                                                                        data-toggle="tooltip"
                                                                        title="Excluir"
                                                                        role="button"
                                                                        class="text-danger"
                                                                        @click="
                                                                            () =>
                                                                                abrirModalDeleteVal(
                                                                                    line.idocorrencias_val
                                                                                )
                                                                        "
                                                                    >
                                                                        <i class="fas fa-trash-alt"></i>
                                                                    </a>
                                                                </td>
                                                                <td>{{ line.base }}</td>
                                                                <td class="text-right">{{ line.valor }}</td>
                                                                <td><CertoErrado :variavel="line.inativo" inverso /></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            v-if="operation == 'update'"
            id="modalVal"
            title="Val"
            confirmClass="btn btn-danger d-none"
            :confirmFunction="() => null"
            noFooter
        >
            <OcorrenciasVal v-if="isDataLoaded()" :id="valSelecionada" :idparent="dados[idname].value" :parentBaseUrl="baseUrl" :parentGetData="getData" :parentSetValSelecionada="setValSelecionada"/>
        </Modal>
        <Modal
            id="modalExcluir"
            title="Confirmar Exclusão"
            confirmClass="btn btn-danger"
            confirmText="Excluir"
            :confirmFunction="deletar"
        >
            Tem certeza que deseja excluir esta tabela de cobrança?
        </Modal>
        <Modal
            id="modalDeleteVal"
            title="Confirmar Exclusão"
            confirmClass="btn btn-danger"
            confirmText="Excluir"
            :confirmFunction="deleteVal"
        >
            Tem certeza que deseja excluir este valor?
        </Modal>
        <ForbiddenModal />
        <LogoutModal />
    </div>
</template>

<script>
import api, { getUsername } from "../services/api";
import Topbar from "@/components/Topbar";
import InputAndLabel from "@/components/InputAndLabel";
import CertoErrado from "@/components/CertoErrado";
import SelectAndLabel from "@/components/SelectAndLabel";
import DateAndLabel from "@/components/DateAndLabel";
import Quadro from "@/components/Quadro";
import LogoutModal from "@/components/LogoutModal.vue";
import Modal from "@/components/Modal";
import OcorrenciasVal from "@/views/OcorrenciasVal";
import ViewMixin from "./ViewMixin";
import $ from "jquery";
import {
    ClientErrorException,
    DEFAULT_ERROR_MESSAGE,
    PermissaoException,
    ServerErrorException,
    TokenInvalidoException,
    ValidationException,
} from "../services/exceptions";
import ForbiddenModal from "../components/ForbiddenModal.vue";
import CheckboxAndLabel from "../components/CheckboxAndLabel.vue";
import TextareaAndLabel from "../components/TextareaAndLabel.vue";
import { maskCNPJCPF } from "../utils";
import { RouterLink } from 'vue-router'
import Vue from 'vue';

export default {
    name: "TabelaDeCobranca",
    mixins: [ViewMixin],
    components: {
        Topbar,
        LogoutModal,
        Modal,
        InputAndLabel,
        SelectAndLabel,
        Quadro,
        ForbiddenModal,
        CheckboxAndLabel,
        DateAndLabel,
        TextareaAndLabel,
        OcorrenciasVal,
        CertoErrado
    },
    props: ["id"],
    data: function () {
        return {
            idname: "idocorrencia",
            baseUrl: "ocorrencias",
            title: {
                icon: "fas fa-tags",
                text: "Ocorrência",
            },
            combos: {
                idcliente: [],
            },
            valSelecionada: "insert",
            // dados: {
            //   documento: ''
            // },
            mensagem: "",
        };
    },
    beforeRouteLeave(to, from, next) {
        this.areYouSure(next);
    },
    mounted() {
        this.defineOperation(this.id);
        this.getCombo().then(this.getData);
    },
    methods: {
        async getCombo() {
            this.carregando = true;

            try {
                let response = await api.get(`/${this.baseUrl}/combosToSave`);
                let data = response.data;
                this.combos = data;
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
        async getData(id = this.id) {
            this.carregando = true;

            try {
                let response = await api.get(`/${this.baseUrl}/${id}`);
                let data = response.data;
                this.setData(data, ["vals"]);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
        async salvar() {
            this.carregando = true;

            try {
                let response;
                let id = this.dados[this.idname].value;
                let newData;
                let fieldsToDelete = ["vals"];
                if (this.operation === "update") {
                    newData = this.getDataToUpdate(fieldsToDelete);
                    response = await api.put(`/${this.baseUrl}/${id}`, newData);
                } else {
                    newData = this.getDataToInsert(fieldsToDelete);
                    response = await api.post(`/${this.baseUrl}`, newData);
                }
                let data = response.data;
                id = data.id;
                this.dados[this.idname] = { value: id, oldValue: id };
                this.operation = "update";

                this.updateData(newData);

                this.changeURL(`/${this.baseUrl}/${id}`);

                this.setMessage(data.successMessage, "success");
                this.errors = {};
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
        async duplicar() {
            this.carregando = true;

            try {
                let response;
                let id = this.dados[this.idname].value;
                response = await api.post(`/${this.baseUrl}/${id}/duplicate`);
                let data = response.data;
                id = data.id;

                this.setMessage(data.successMessage + ` [Clique aqui para acessar](/${this.baseUrl}/${id})`, "success");
                this.errors = {};
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
                console.error(e);
            }

            this.carregando = false;
        },
        async deletar() {
            this.carregando = true;

            $("#modalExcluir").modal("hide");

            try {
                let id = this.dados[this.idname].value;
                await api.delete(`/${this.baseUrl}/${id}`);

                this.$router.push(`/${this.baseUrl}`);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
        setValSelecionada(v) {
            this.valSelecionada = v;
        },

        abrirModalVal(id) {
            this.valSelecionada = id;
            $("#modalVal").modal("show");
        },

        abrirModalDeleteVal(id) {
            this.valSelecionada = id;
            $("#modalDeleteVal").modal("show");
        },

        async deleteVal() {
            this.carregando = true;

            $("#modalDeleteVal").modal("hide");

            try {
                let id = this.valSelecionada;
                await api.delete(`/${this.baseUrl}/${this.dados[this.idname].value}/val/${id}`);
                this.dados.vals = this.dados.vals.filter((el) => el.idocorrencias_val != id);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
    },

    watch: {
        "dados.documento.value": {
            handler: function (newVal, oldVal) {
                // if (oldVal == undefined || newVal.length > oldVal.length) {
                //   this.dados.documento.value = maskCNPJCPF(newVal, true);
                // }
            },
        },
    },
};
</script>

<style></style>
