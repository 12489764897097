var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _vm.isDataLoaded()
          ? _c(
              "fieldset",
              { attrs: { disabled: _vm.carregando } },
              [
                _c("Quadro", {
                  attrs: { type: _vm.messageType, message: _vm.message },
                }),
                _c("form", { staticClass: "text-left" }, [
                  _c("div", { staticClass: "d-print-none" }, [
                    _vm._m(0),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary mr-1",
                        attrs: { type: "button", id: "btnSave" },
                        on: { click: _vm.salvar },
                      },
                      [_vm._v(" Salvar ")]
                    ),
                    _c("hr"),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-12" }, [
                      _c("fieldset", [
                        _c(
                          "div",
                          { staticClass: "form-row" },
                          [
                            _c("InputAndLabel", {
                              attrs: {
                                column: "3",
                                name: "id",
                                label: "ID",
                                type: "text",
                                readonly: "",
                              },
                              model: {
                                value: _vm.dados[_vm.idname].value,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dados[_vm.idname], "value", $$v)
                                },
                                expression: "dados[idname].value",
                              },
                            }),
                            _c("SelectAndLabel", {
                              attrs: {
                                column: "4",
                                name: "tipo",
                                label: "Tipo",
                                options: _vm.combos.tipo,
                                errorMessage: _vm.errors.tipo,
                              },
                              model: {
                                value: _vm.dados.tipo.value,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dados.tipo, "value", $$v)
                                },
                                expression: "dados.tipo.value",
                              },
                            }),
                            _c("SelectAndLabel", {
                              attrs: {
                                column: "5",
                                name: "nome",
                                label: "Nome",
                                options: _vm.combos.nome,
                                errorMessage: _vm.errors.nome,
                              },
                              model: {
                                value: _vm.dados.nome.value,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dados.nome, "value", $$v)
                                },
                                expression: "dados.nome.value",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-row" },
                          [
                            _c("InputAndLabel", {
                              attrs: {
                                column: "4",
                                name: "custo",
                                label: "Custo",
                                type: "number",
                                errorMessage: _vm.errors.custo,
                                right: "",
                              },
                              model: {
                                value: _vm.dados.custo.value,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dados.custo, "value", $$v)
                                },
                                expression: "dados.custo.value",
                              },
                            }),
                            _c("InputAndLabel", {
                              attrs: {
                                column: "4",
                                name: "qtdeval",
                                label: "Qtde Valor",
                                type: "number",
                                errorMessage: _vm.errors.qtdeval,
                                right: "",
                              },
                              model: {
                                value: _vm.dados.qtdeval.value,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dados.qtdeval, "value", $$v)
                                },
                                expression: "dados.qtdeval.value",
                              },
                            }),
                            _c("InputAndLabel", {
                              attrs: {
                                column: "4",
                                name: "total",
                                label: "Total",
                                type: "number",
                                errorMessage: _vm.errors.total,
                                right: "",
                                readonly: "",
                              },
                              model: {
                                value: _vm.dados.total.value,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dados.total, "value", $$v)
                                },
                                expression: "dados.total.value",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-secondary mr-1",
        attrs: {
          id: "btn_voltar",
          "data-toggle": "modal",
          "data-target": "#modalMov",
        },
      },
      [_c("i", { staticClass: "fa fa-arrow-left" }), _vm._v(" Voltar")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }