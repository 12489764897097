var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Topbar"),
      _c(
        "div",
        {
          staticClass: "card shadow mx-3 conteudo text-left",
          staticStyle: { "margin-top": "90px" },
        },
        [
          _c("div", { staticClass: "card-header py-3" }, [
            _c(
              "h6",
              { staticClass: "m-0 font-weight-bold text-primary-dark" },
              [
                _c("i", { class: _vm.title.icon + " mr-2" }),
                _vm._v(_vm._s(_vm.title.text) + " "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.carregando,
                        expression: "carregando",
                      },
                    ],
                    staticClass: "spinner-grow",
                    attrs: { role: "status" },
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Loading..."),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _vm.isDataLoaded()
                  ? _c(
                      "fieldset",
                      { attrs: { disabled: _vm.carregando } },
                      [
                        _c("Quadro", {
                          attrs: {
                            type: _vm.messageType,
                            message: _vm.message,
                          },
                        }),
                        _c("form", [
                          _c(
                            "div",
                            { staticClass: "d-print-none" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "btn btn-secondary mr-1",
                                  attrs: {
                                    to: "/" + this.baseUrl,
                                    id: "btn_voltar",
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-arrow-left" }),
                                  _vm._v(" Voltar"),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary mr-1",
                                  attrs: { type: "button", id: "btnSave" },
                                  on: { click: _vm.salvar },
                                },
                                [_vm._v(" Salvar ")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger mr-1",
                                  attrs: {
                                    type: "button",
                                    id: "btn_del",
                                    disabled: _vm.operation === "insert",
                                    "data-toggle": "modal",
                                    "data-target": "#modalExcluir",
                                  },
                                },
                                [_vm._v(" Excluir ")]
                              ),
                              _c("hr"),
                            ],
                            1
                          ),
                          _c("fieldset", [
                            _c(
                              "div",
                              { staticClass: "form-row" },
                              [
                                _c("InputAndLabel", {
                                  attrs: {
                                    column: "1",
                                    name: "id",
                                    label: "ID",
                                    type: "text",
                                    readonly: "",
                                  },
                                  model: {
                                    value: _vm.dados[_vm.idname].value,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dados[_vm.idname],
                                        "value",
                                        $$v
                                      )
                                    },
                                    expression: "dados[idname].value",
                                  },
                                }),
                                _c("InputAndLabel", {
                                  attrs: {
                                    column: "5",
                                    name: "nome",
                                    label: "Nome",
                                    type: "text",
                                    maxlength: "100",
                                    errorMessage: _vm.errors.nome,
                                  },
                                  model: {
                                    value: _vm.dados.nome.value,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dados.nome, "value", $$v)
                                    },
                                    expression: "dados.nome.value",
                                  },
                                }),
                                _c("InputAndLabel", {
                                  attrs: {
                                    column: "3",
                                    name: "login",
                                    label: "Login",
                                    type: "text",
                                    maxlength: "100",
                                    errorMessage: _vm.errors.login,
                                  },
                                  model: {
                                    value: _vm.dados.login.value,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dados.login, "value", $$v)
                                    },
                                    expression: "dados.login.value",
                                  },
                                }),
                                _c("SelectAndLabel", {
                                  attrs: {
                                    column: "3",
                                    name: "idperfil",
                                    label: "Perfil",
                                    options: _vm.combos.idperfil,
                                    errorMessage: _vm.errors.idperfil,
                                  },
                                  model: {
                                    value: _vm.dados.idperfil.value,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dados.idperfil, "value", $$v)
                                    },
                                    expression: "dados.idperfil.value",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "form-row" },
                              [
                                _c("InputAndLabel", {
                                  attrs: {
                                    column: "4",
                                    name: "senha",
                                    label: "Senha",
                                    type: "password",
                                    maxlength: "50",
                                    errorMessage: _vm.errors.senha,
                                    enterKey: function (e) {
                                      return _vm.clicar("#btnSave")
                                    },
                                  },
                                  model: {
                                    value: _vm.dados.senha.value,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dados.senha, "value", $$v)
                                    },
                                    expression: "dados.senha.value",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group mt-md-4 pt-md-3 ml-md-0 ml-2",
                                  },
                                  [
                                    _c("CheckboxAndLabel", {
                                      attrs: {
                                        name: "inativo",
                                        label: "Inativo",
                                        errorMessage: _vm.errors.inativo,
                                      },
                                      model: {
                                        value: _vm.dados.inativo.value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dados.inativo,
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "dados.inativo.value",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "Modal",
        {
          attrs: {
            id: "modalExcluir",
            title: "Confirmar Exclusão",
            confirmClass: "btn btn-danger",
            confirmText: "Excluir",
            confirmFunction: _vm.deletar,
          },
        },
        [_vm._v(" Tem certeza que deseja excluir este usuário? ")]
      ),
      _c("ForbiddenModal"),
      _c("LogoutModal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }