<template>
  <div class="form-check d-inline-block">
    <input
      :class="'form-check-input ' + (errorMessage ? 'is-invalid' : '')"
      type="checkbox"
      :id="name"
      :name="name"
      v-bind="$attrs"
      @change="$emit('input', $event.target.checked)"
      value="1"
      :checked="checked"
      @keydown.enter="enterKey"
    />
    <label class="form-check-label" :for="name">
      {{ label }}
    </label>
    <div v-if="errorMessage" class="invalid-feedback d-block">
      {{ errorMessage[0] }}
    </div>
  </div>
</template>

<script>
import { enterKey } from "../utils";
export default {
  name: "CheckboxAndLabel",
  props: {
    name: String,
    label: String,    
    errorMessage: Array, 
    enterKey: {
      type: Function,
      default: function (e) {
        enterKey(e);
      },
    },   
  },
  computed: {
    checked: function() {
      switch (this.$attrs.value) {
      case 1:
      case "1":
      case true:
        return true;        
    
      default:
        return false;        
    }  
    }
  },
  mounted(){
    
    // switch (this.$attrs.value) {
    //   case 1:
    //   case "1":
    //   case true:
    //     document.querySelector('#'+this.name).checked = true;
    //     break;
    
    //   default:
    //     document.querySelector('#'+this.name).checked = false;
    //     break;
    // }    
  }  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media only screen and (min-width: 768px) {
}
</style>
