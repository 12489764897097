var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("th", { class: _vm.classe }, [
    _c(
      "button",
      {
        staticClass: "btn btn-link pl-0",
        attrs: { type: "button", id: _vm.db },
        on: { click: _vm.sort },
      },
      [
        _vm.$parent.sort == _vm.db && _vm.$parent.sortDirection == "asc"
          ? _c("i", { staticClass: "fas fa-sort-up" })
          : _vm._e(),
        _vm.$parent.sort == _vm.db && _vm.$parent.sortDirection == "desc"
          ? _c("i", { staticClass: "fas fa-sort-down" })
          : _vm._e(),
        _vm._v(" " + _vm._s(_vm.label) + " "),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }