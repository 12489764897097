<template>
    <div class="home">
        <Topbar />
        <div class="container-fluid text-left" style="padding-top: 80px">
            <!-- Content Row -->
            <div class="card shadow mb-4" style="height: 84vh">
                <div class="card-header bg-secondary py-3">
                    <h6 class="m-0 font-weight-bold text-white">
                        <i :class="`${title.icon} mr-2`"></i>{{ title.text }}
                        <div v-show="carregando" class="spinner-grow" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </h6>
                </div>
                <fieldset :disabled="carregando">
                    <div class="card-body bg-light text-primary-dark">
                        <p>
                            <!-- <a
                                class="btn btn-link"
                                data-toggle="collapse"
                                href="#filtrocard"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                            >
                                <i class="fas fa-filter"></i>
                                Filtro
                            </a> -->
                            <!-- <a
                                class="btn btn-link"
                                data-toggle="collapse"
                                href="#filtrocardImportacao"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                            >
                                <i class="fas fa-file-import"></i>
                                Importação
                            </a> -->
                            <router-link class="btn btn-link" :to="`/${baseUrl}/insert`"
                                ><i class="fa fa-fw fa-plus"></i> Adicionar
                            </router-link>
                            <button type="button" class="btn btn-primary mr-1" @click="exportar">
                                <i class="fas fa-file-csv"></i>
                                Exportar Fechamento
                            </button>
                            <button type="button" class="btn btn-primary mr-1" @click="abrirModalBaixarFechamento">
                                <i class="fas fa-file-download"></i>
                                Exportar All Clientes
                            </button>
                        </p>
                        <div class="collapse show" id="filtrocard">
                            <div class="card card-body bg-light">
                                <div class="form-row align-items-center">
                                    <InputAndLabel
                                        column="3"
                                        name="pesquisa"
                                        label="Pesquisa"
                                        type="text"
                                        v-model="filtro.pesquisa"
                                    />
                                    <!-- <SelectAndLabel
                                        column="2"
                                        name="tipocob"
                                        label="Tipo Cobrança"
                                        v-model="filtro.tipocob"
                                        :options="combos.tipocob"
                                    /> -->
                                    <!-- <SelectAndLabel
                                        column="2"
                                        name="idcotacao"
                                        label="Cotação"
                                        v-model="filtro.idcotacao"
                                        :options="combos.cotacoes"
                                    />
                                    <SelectAndLabel
                                        column="2"
                                        name="idstatus"
                                        label="Status"
                                        v-model="filtro.idstatus"
                                        :options="combos.status"
                                    /> -->
                                    <input-and-label
                                        column="2"
                                        name="dataini"
                                        label="Data Início"
                                        v-model="filtro.dataini"
                                        type="date"
                                    />
                                    <input-and-label
                                        column="2"
                                        name="datafim"
                                        label="Data Fim"
                                        v-model="filtro.datafim"
                                        type="date"
                                    />
                                    <div class="form-group col-md-auto pt-md-2">
                                        <button
                                            type="submit"
                                            class="btn btn-primary form-control mt-md-4"
                                            id="pesquisar"
                                            @click="list"
                                        >
                                            Pesquisar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="collapse" id="filtrocardImportacao">
                            <div class="card card-body bg-light">
                                <div class="row">
                                    <div class="custom-file form-group col-md-5 ml-2 mt-2">
                                        <input type="hidden" name="arquivo" id="arquivo" v-model="arquivoNome" />
                                        <input
                                            type="file"
                                            class="custom-file-input"
                                            name="files[]"
                                            @change="handleEscolhaArquivo"
                                            id="userfile"
                                            multiple
                                        />
                                        <label
                                            class="custom-file-label"
                                            for="userfile"
                                            data-browse="Buscar"
                                            id="etiqueta"
                                        >
                                            {{ arquivoLabel }}</label
                                        >
                                    </div>
                                    <button
                                        type="submit"
                                        id="btn_save"
                                        name="btn_save"
                                        value="UPLOAD"
                                        class="btn btn-success ml-2 mt-2"
                                        @click="importarPlanilha"
                                        :disabled="arquivos == null"
                                    >
                                        Importar
                                    </button>
                                </div>
                            </div>
                        </div>

                        <hr />
                        <div class="row">
                            <div class="col-lg-12 tableFixHead">
                                <table class="table table-sm table-striped table-bordered">
                                    <thead>
                                        <tr style="white-space: nowrap">
                                            <th></th>
                                            <TableHeader :db="idname" label="ID" />
                                            <th class="text-center">
                                                <span class="btn btn-link">Aprovado</span>
                                            </th>
                                            <TableHeader db="data" label="Data" />
                                            <TableHeader db="contrato" label="Contrato" />
                                            <TableHeader db="tabcob" label="Tabela" />
                                            <TableHeader db="tabcob" label="Cliente Cobrança" />
                                            <TableHeader db="tabcob" label="CNPJs" />

                                            <TableHeader classe="text-right" db="gmv" label="Valor GMV" />
                                            <TableHeader classe="text-right" db="takerate" label="Take Rate %" />
                                            <TableHeader
                                                classe="text-right"
                                                db="ticketmedio"
                                                label="Valor Ticket Médio"
                                            />
                                            <TableHeader
                                                classe="text-right"
                                                db="itensrecebidos"
                                                label="Qtde Itens Recebidos"
                                            />
                                            <TableHeader
                                                classe="text-right"
                                                db="totalitensestoque"
                                                label="Qtde Total Itens Estoque"
                                            />
                                            <TableHeader
                                                classe="text-right"
                                                db="valortotalestoque"
                                                label="Valor Total Estoque"
                                            />
                                            <TableHeader classe="text-right" db="valorreversa" label="Valor Reversa" />
                                            <TableHeader classe="text-right" db="qtdereversa" label="Qtde Reversa" />
                                            <TableHeader
                                                classe="text-right"
                                                db="valoritensclass"
                                                label="Valor Itens Class."
                                            />
                                            <TableHeader
                                                classe="text-right"
                                                db="qtdeitensclass"
                                                label="Qtde Itens Class."
                                            />
                                            <TableHeader
                                                classe="text-right"
                                                db="totalpicking"
                                                label="Qtde Total Picking"
                                            />
                                            <TableHeader
                                                classe="text-right"
                                                db="totalpacking"
                                                label="Qtde Total Packing"
                                            />

                                            <TableHeader classe="text-right" db="faturamento" label="Faturamento" />
                                            <TableHeader classe="text-right" db="insumos" label="Insumos" />
                                            <TableHeader classe="text-right" db="totalreversa" label="Total Reversa" />
                                            <TableHeader
                                                classe="text-right"
                                                db="totalitensclass"
                                                label="Total Itens Classificados"
                                            />
                                            <TableHeader classe="text-right" db="excedente" label="Excedente" />
                                            <TableHeader
                                                classe="text-right"
                                                db="movarm"
                                                label="Movimento / Armazenagem"
                                            />
                                            <TableHeader
                                                classe="text-right"
                                                db="valoradicional"
                                                label="Valor Adicional"
                                            />
                                            <TableHeader classe="text-right" db="total" label="Total" />
                                            <!-- <TableHeader db="tipocob" label="Tipo Cobrança" /> -->
                                        </tr>
                                        <tr class="table-success">
                                            <td></td>
                                            <td class="text-center" colspan="7">Totais</td>

                                            <td class="text-right">{{ decimalFormat(totais.gmv || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(totais.takerate || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(totais.ticketmedio || 0) }}</td>
                                            <td class="text-right">{{ intFormat(totais.itensrecebidos || 0) }}</td>
                                            <td class="text-right">{{ intFormat(totais.totalitensestoque || 0) }}</td>
                                            <td class="text-right">
                                                {{ decimalFormat(totais.valortotalestoque || 0) }}
                                            </td>
                                            <td class="text-right">{{ intFormat(totais.valorreversa || 0) }}</td>
                                            <td class="text-right">{{ intFormat(totais.qtdereversa || 0) }}</td>
                                            <td class="text-right">{{ intFormat(totais.valoritensclass || 0) }}</td>
                                            <td class="text-right">{{ intFormat(totais.qtdeitensclass || 0) }}</td>
                                            <td class="text-right">{{ intFormat(totais.totalpicking || 0) }}</td>
                                            <td class="text-right">{{ intFormat(totais.totalpacking || 0) }}</td>

                                            <td class="text-right">{{ decimalFormat(totais.faturamento || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(totais.insumos || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(totais.totalreversa || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(totais.totalitensclass || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(totais.excedente || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(totais.movarm || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(totais.valoradicional || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(totais.total || 0) }}</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="line in dados"
                                            :key="line[idname]"
                                            :style="{
                                                backgroundColor: '#' + line.statusbackground,
                                                color: '#' + line.statuscolor,
                                            }"
                                        >
                                            <td>
                                                <router-link
                                                    :to="`/${baseUrl}/${line[idname]}`"
                                                    data-toggle="tooltip"
                                                    title="Editar/Visualizar"
                                                    ><i class="fa fa-edit"></i
                                                ></router-link>
                                            </td>
                                            <td>{{ line[idname] }}</td>
                                            <td class="text-center">
                                                <AprovacaoCobranca :id="line[idname]" :aprov="line.aprov" />
                                            </td>
                                            <td>{{ dateFormat(line.data) }}</td>
                                            <td>{{ line.contrato }}</td>
                                            <td>{{ line.tabcob }}</td>

                                            <td class="text-right">{{ line.clientecob }}</td>
                                            <td class="text-right">{{ line.cnpjs }}</td>
                                            <td class="text-right">{{ decimalFormat(line.gmv || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(line.takerate || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(line.ticketmedio || 0) }}</td>
                                            <td class="text-right">{{ intFormat(line.itensrecebidos || 0) }}</td>
                                            <td class="text-right">{{ intFormat(line.totalitensestoque || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(line.valortotalestoque || 0) }}</td>
                                            <td class="text-right">{{ intFormat(line.valorreversa || 0) }}</td>
                                            <td class="text-right">{{ intFormat(line.qtdereversa || 0) }}</td>
                                            <td class="text-right">{{ intFormat(line.valoritensclass || 0) }}</td>
                                            <td class="text-right">{{ intFormat(line.qtdeitensclass || 0) }}</td>
                                            <td class="text-right">{{ intFormat(line.totalpicking || 0) }}</td>
                                            <td class="text-right">{{ intFormat(line.totalpacking || 0) }}</td>

                                            <td class="text-right">{{ decimalFormat(line.faturamento || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(line.insumos || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(line.totalreversa || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(line.totalitensclass || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(line.excedente || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(line.movarm || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(line.valoradicional || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(line.total || 0) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <TableFooter />
                    </div>
                </fieldset>
            </div>
        </div>
        <Modal
            id="modalBaixarFechamento"
            title="Baixar Fechamento"
            confirmClass="btn btn-primary"
            :confirmFunction="baixarFechamento"
            small
        >
            <div class="row text-left">
                <div class="col-lg-12">
                    <fieldset>
                        <div class="form-row">
                            <InputAndLabel
                                column="12"
                                name="dataBaixarFechamento"
                                label="Data"
                                type="date"
                                v-model="dataBaixarFechamento"
                            />
                        </div>
                    </fieldset>
                </div>
            </div>
        </Modal>
        <ForbiddenModal />
        <LogoutModal />
    </div>
</template>

<script>
// @ is an alias to /src
import Topbar from "@/components/Topbar.vue";
import LogoutModal from "@/components/LogoutModal.vue";
import TableHeader from "@/components/TableHeader.vue";
import Modal from "@/components/Modal.vue";
import ViewMixin from "./ViewMixin";
import api from "../services/api";
import $ from "jquery";
import {
    ClientErrorException,
    DEFAULT_ERROR_MESSAGE,
    PermissaoException,
    ServerErrorException,
    TokenInvalidoException,
} from "../services/exceptions";
import { enterKey, dateFormat, getDate } from "../utils";
import ForbiddenModal from "../components/ForbiddenModal.vue";
import CertoErrado from "../components/CertoErrado.vue";
import TableFooter from "../components/TableFooter.vue";
import InputAndLabel from "../components/InputAndLabel.vue";
import SelectAndLabel from "../components/SelectAndLabel.vue";
import DateAndLabel from "../components/DateAndLabel.vue";
import AprovacaoCobranca from "../components/AprovacaoCobranca.vue";

export default {
    name: "Cobrancas",
    mixins: [ViewMixin],
    components: {
        Topbar,
        LogoutModal,
        TableHeader,
        ForbiddenModal,
        CertoErrado,
        TableFooter,
        InputAndLabel,
        SelectAndLabel,
        DateAndLabel,
        Modal,
        AprovacaoCobranca
    },
    data: () => {
        return {
            idname: "idcobranca",
            baseUrl: "cobrancas",
            title: {
                icon: "fas fa-money-check-alt",
                text: "Cobranças",
            },
            sort: "idcobranca",
            sortDirection: "desc",
            perPage: "all",
            filtro: {
                pesquisa: "",
            },
            arquivoLabel: "Selecionar Arquivo",
            arquivoNome: "",
            arquivos: null,
            dataBaixarFechamento: null,
            aprovados: [],
        };
    },
    mounted() {
        this.populateFilterFromQueryString();

        (this.filtro.dataini = this.filtro.dataini ?? getDate("firstDayOfLastMonth")),
            (this.filtro.datafim = this.filtro.datafim ?? getDate("lastDayOfMonth")),
            this.getCombos();
        this.list();
    },
    methods: {
        enterKey,
        dateFormat,

        getTipocob(cob) {
            let arr = [];
            arr["f"] = "Fixo";
            arr["m"] = "Movimentação";
            arr["c"] = "Composto";
            return arr[cob];
        },

        handleEscolhaArquivo(e) {
            if (e.target.files.length > 0) {
                this.arquivoLabel = "";
                for (let i = 0; i < e.target.files.length; i++) {
                    const file = e.target.files[i];
                    this.arquivoLabel += '"' + file.name + '" ';
                }
                this.arquivoNome = e.target.files[0].name;
                this.arquivos = e.target.files;
            } else {
                this.arquivoLabel = "Selecionar Arquivo";
                this.arquivos = null;
            }
        },

        async getCombos() {
            this.carregando = true;

            try {
                // $("#filtrocard").collapse("hide");

                let { baseUrl } = this;
                let response = await api.get(`/${baseUrl}/combos`);

                this.combos = response.data;
            } catch (e) {
                if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    alert(e.message);
                } else {
                    alert(DEFAULT_ERROR_MESSAGE);
                }
            }

            this.carregando = false;
        },

        async list() {
            this.carregando = true;

            try {
                // $("#filtrocard").collapse("hide");

                let { baseUrl, sort, sortDirection, filtro, perPage, pular } = this;
                let params = {
                    sort: `${sort} ${sortDirection}`,
                    ...filtro,
                    perPage,
                    pular,
                };
                let response = await api.get(`/${baseUrl}`, { params });

                this.dados = response.data.dados;
                this.totalRows = response.data.totalRows;
                this.totais = response.data.totais;

                this.changeURL(`/${this.baseUrl}?${this.encodeQueryData(params)}`);
            } catch (e) {
                if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    alert(e.message);
                } else {
                    alert(DEFAULT_ERROR_MESSAGE);
                }
            }

            this.carregando = false;
        },

        async importarPlanilha() {
            this.carregando = true;

            try {
                $("#filtrocardImportacao").collapse("hide");

                let { baseUrl, arquivos } = this;
                const formData = new FormData();
                for (let i = 0; i < arquivos.length; i++) {
                    formData.append(`files[${i}]`, arquivos[i]);
                }

                await api.post(`/${baseUrl}/importar`, formData, {
                    headers: { "content-type": "multipart/form-data" },
                });

                this.dados = [];
                this.totais = {};
                this.list();
            } catch (e) {
                if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    alert(e.message);
                } else {
                    alert(DEFAULT_ERROR_MESSAGE);
                    console.error(e);
                }
            }

            this.carregando = false;
        },

        async exportar() {
            try {
                const response = await this.apiExport();
                const today = new Date().toISOString().slice(0, 10);
                const fileName = `${this.title.text}_${today}.xlsx`;
                const blobURL = URL.createObjectURL(new File([response.data], fileName));
                const a = document.createElement("a");
                a.href = blobURL;
                a.style = "display: none";
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
            } catch (e) {
                console.error(e);
                if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ServerErrorException) {
                    alert(e.message);
                } else {
                    alert(DEFAULT_ERROR_MESSAGE);
                }
            }
            this.carregando = false;
        },

        async apiExport() {
            this.carregando = true;

            let { pular, perPage } = this;

            const response = await api.get(`/${this.baseUrl}/exportar`, {
                responseType: "blob",
                params: {
                    pular,
                    perPage,
                    sort: `${this.sort} ${this.sortDirection}`,
                    ...this.filtro,
                },
            });

            this.carregando = false;

            return response;
        },

        abrirModalBaixarFechamento() {
            (this.dataBaixarFechamento = this.dataBaixarFechamento ?? getDate("lastDayOfLastMonth")),
                $("#modalBaixarFechamento").modal("show");
        },

        async baixarFechamento() {
            $("#modalBaixarFechamento").modal("hide");
            try {
                const response = await this.apiBaixarFechamento();
                // const today = new Date().toISOString().slice(0, 10);
                const fileName = `Fechamento${this.dataBaixarFechamento}.xlsx`;
                const blobURL = URL.createObjectURL(new File([response.data], fileName));
                const a = document.createElement("a");
                a.href = blobURL;
                a.style = "display: none";
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
            } catch (e) {
                console.error(e);
                if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ServerErrorException) {
                    alert(e.message);
                } else {
                    alert(DEFAULT_ERROR_MESSAGE);
                }
            }
            this.carregando = false;
        },

        async apiBaixarFechamento() {
            this.carregando = true;

            let { dataBaixarFechamento } = this;

            const response = await api.get(`/fechamento/export`, {
                responseType: "blob",
                params: {
                    data: dataBaixarFechamento,
                },
            });

            this.carregando = false;

            return response;
        },
    },
};
</script>

<style scoped lang="scss">
.spinner-grow {
    width: 1em;
    height: 1em;
}

.tableFixHead {
    overflow: auto;
    height: 42vh;
}
.tableFixHead thead {
    position: sticky;
    top: -1px;
    z-index: 1;
}
.tableFixHead thead * {
    border-top: 0;
    border-bottom: 0;
}
.tableFixHead tbody th {
    position: sticky;
    left: 0;
}
.tableFixHead tbody tr {
    top: 2px;
}
.tableFixHead.table-bordered {
    border: 0;
}

/* Just common table stuff. Really. */
table {
    border-collapse: collapse;
}
th {
    background: #eee;
}

@media only screen and (min-width: 768px) {
}
</style>
