var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        id: "forbiddenModal",
        title: "Acesso Negado",
        confirmText: "Ok",
        confirmFunction: _vm.fechar,
      },
    },
    [_vm._v(" Você não possui permissão para utilizar esse recurso. ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }