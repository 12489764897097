export async function wait(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function focar(querySelector){
  setTimeout(() => {
    document.querySelector(querySelector).focus();
  }, 10);
}

export function clicar (querySelector){
  
  document.querySelector(querySelector).click();
  
}

export function enterKey (e){
  let focaveis = document.querySelectorAll('input, button, select, textarea');
  focaveis = Array.prototype.slice.call(focaveis);
  let inputAtualIndex = focaveis.indexOf(e.target);  
  let proximoInput = focaveis[inputAtualIndex + 1];
  
  if (proximoInput.type == 'button') {
    proximoInput.click();
  } else {
    proximoInput.focus();
  }
}

export function scrollToTop(querySelector = 'body'){
  document.querySelector(querySelector).scrollTo(0, 0);
}

export function arrayColumn(arr, column) {
  return arr.map(x => x[column])
}


export function objectColumn(obj, column) {
  let retorno = {};
  for (var key in obj) {  
    retorno[key] = obj[key][column];
  }
  return retorno
}

export function moneyFormat(number) {
  return Number(number).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
  //return Number(number).toFixed(2).toString().replace(".", ",");
}

export function decimalFormat(number) {
  return parseFloat(number || 0).toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function intFormat(number) {
  return parseInt(number).toLocaleString("pt-BR", {
    maximumFractionDigits: 2,
  });
}

export function dateFormat(date) {
  return date ? date.replace(/(\d{4})-(\d{2})-(\d{2})/g, "$3/$2/$1") : '';
  //return Number(number).toFixed(2).toString().replace(".", ",");
}

export function createQueryUrl(obj) {
  let queryString = '?';
  for (const key in obj) {
    queryString += `${key}=${obj[key]}&`;
  }
  queryString = queryString.slice(0, -1);
  return queryString;
}

export function maskCNPJ(v){
    v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d)/,"$1.$2")             //Coloca ponto entre o segundo e o terceiro dígitos
    v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    return v
}

export function maskCPF(v){
    v=v.replace(/\D/g,"")                    //Remove tudo o que não é dígito
    v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
    v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
                                             //de novo (para o segundo bloco de números)
    v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
    return v
}

export function maskCNPJCPF(v, inputando = false){
  let delimiter = (inputando ? 14 : 11);
  return ((v.length > delimiter) ? maskCNPJ(v) : maskCPF(v));
}

export function getEnv(name) {
  return process.env[name];
}

export function getDate(relativeDate, date = null) {
  let retorno = '';
  const objDate = date ? new Date(date) : new Date();
  switch (relativeDate) {
    case 'firstDayOfMonth':
      retorno = new Date(objDate.getFullYear(), objDate.getMonth(), 1)
      break;
    case 'lastDayOfMonth':
      retorno = new Date(objDate.getFullYear(), objDate.getMonth() + 1, 0)
      break;
    case 'lastDayOfLastMonth':
      retorno = new Date(objDate.getFullYear(), objDate.getMonth(), 0)
      break;
    case 'firstDayOfLastMonth':
      retorno = new Date(objDate.getFullYear(), objDate.getMonth() -1, 1)
      break;
  }
  retorno = retorno.getFullYear()
    + '-' + (retorno.getMonth() + 1).toString().padStart('2', '0')
    + '-' + retorno.getDate().toString().padStart('2', '0');
    
  return retorno;
}

export function encodeQueryData(data) {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
}