<template>
    <div>
        <Topbar />
        <div class="card shadow mx-3 conteudo text-left" style="margin-top: 90px">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary-dark">
                    <i :class="`${title.icon} mr-2`"></i>{{ title.text }}
                    <div v-show="carregando" class="spinner-grow" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </h6>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-12">
                        <Quadro :type="messageType" :message="message" />
                        <div class="d-print-none">
                            <a @click="$router.go(-1)" id="btn_voltar" class="btn btn-secondary mr-1" :disabled="false">
                                <i class="fa fa-arrow-left"></i> Voltar
                            </a>
                            <button
                                type="button"
                                id="btnSave"
                                class="btn btn-primary mr-1"
                                @click="salvar"
                                :disabled="carregando || !podemud"
                            >
                                Salvar
                            </button>
                            <button
                                type="button"
                                id="btn_del"
                                class="btn btn-danger mr-1"
                                :disabled="operation === 'insert' || carregando || !podemud"
                                data-toggle="modal"
                                data-target="#modalExcluir"
                            >
                                Excluir
                            </button>
                            <hr />
                        </div>
                        <fieldset v-if="isDataLoaded()" :disabled="carregando || !podemud">
                            <form>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <fieldset>
                                            <div class="form-row">
                                                <InputAndLabel
                                                    column="1"
                                                    name="id"
                                                    label="ID"
                                                    type="text"
                                                    v-model="dados[idname].value"
                                                    readonly
                                                />
                                                <InputAndLabel
                                                    column="1"
                                                    name="data"
                                                    label="Data"
                                                    type="date"
                                                    v-model="dados.data.value"
                                                    :errorMessage="errors.data"
                                                />
                                                <SelectAndLabel
                                                    column="4"
                                                    name="idcontrato"
                                                    label="Contrato"
                                                    :options="combos.idcontrato"
                                                    v-model="dados.idcontrato.value"
                                                    :errorMessage="errors.idcontrato"
                                                    input
                                                    :disabled="carregando || !podemud"
                                                />
                                                <SelectAndLabel
                                                    column="3"
                                                    name="idclientescob"
                                                    label="Cliente Cobrança"
                                                    :options="combos.idclientescob"
                                                    v-model="dados.idclientescob.value"
                                                    :errorMessage="errors.idclientescob"
                                                />
                                                <SelectAndLabel
                                                    column="3"
                                                    name="idtabcob"
                                                    label="Tabela"
                                                    :options="combos.idtabcob"
                                                    v-model="dados.idtabcob.value"
                                                    :errorMessage="errors.idtabcob"
                                                />
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 pr-2">
                                                    <div class="card mb-3">
                                                        <div class="card-header">Parâmetros</div>
                                                        <div class="form-row mt-3 mx-3">
                                                            <InputAndLabel
                                                                column="3"
                                                                name="gmv"
                                                                label="Valor GMV"
                                                                type="number"
                                                                format="decimal"
                                                                min="0"
                                                                step="0.01"
                                                                v-model="dados.gmv.value"
                                                                :errorMessage="errors.gmv"
                                                                right
                                                            />
                                                            <InputAndLabel
                                                                column="3"
                                                                name="takerate"
                                                                label="Take Rate %"
                                                                type="number"
                                                                format="decimal"
                                                                min="0"
                                                                step="0.01"
                                                                v-model="dados.takerate.value"
                                                                :errorMessage="errors.takerate"
                                                                right
                                                            />
                                                            <InputAndLabel
                                                                column="3"
                                                                name="ticketmedio"
                                                                label="Valor Ticket Médio"
                                                                type="number"
                                                                format="decimal"
                                                                min="0"
                                                                step="0.01"
                                                                v-model="dados.ticketmedio.value"
                                                                :errorMessage="errors.ticketmedio"
                                                                right
                                                            />
                                                            <InputAndLabel
                                                                column="3"
                                                                name="itensrecebidos"
                                                                label="Qtde Itens Recebidos"
                                                                type="number"
                                                                format="integer"
                                                                min="0"
                                                                v-model="dados.itensrecebidos.value"
                                                                :errorMessage="errors.itensrecebidos"
                                                                right
                                                            />
                                                            <InputAndLabel
                                                                column="3"
                                                                name="totalitensestoque"
                                                                label="Qtde Total Itens Estoque"
                                                                type="number"
                                                                format="integer"
                                                                min="0"
                                                                v-model="dados.totalitensestoque.value"
                                                                :errorMessage="errors.totalitensestoque"
                                                                right
                                                            />
                                                            <InputAndLabel
                                                                column="3"
                                                                name="valortotalestoque"
                                                                label="Valor Total Estoque"
                                                                type="number"
                                                                format="decimal"
                                                                min="0"
                                                                step="0.01"
                                                                v-model="dados.valortotalestoque.value"
                                                                :errorMessage="errors.valortotalestoque"
                                                                right
                                                            />
                                                            <InputAndLabel
                                                                column="3"
                                                                name="valorreversa"
                                                                label="Valor Reversa"
                                                                type="number"
                                                                format="decimal"
                                                                min="0"
                                                                v-model="dados.valorreversa.value"
                                                                :errorMessage="errors.valorreversa"
                                                                right
                                                            />
                                                            <InputAndLabel
                                                                column="3"
                                                                name="qtdereversa"
                                                                label="Qtde Reversa"
                                                                type="number"
                                                                format="integer"
                                                                min="0"
                                                                v-model="dados.qtdereversa.value"
                                                                :errorMessage="errors.qtdereversa"
                                                                right
                                                            />
                                                            <InputAndLabel
                                                                column="3"
                                                                name="valoritensclass"
                                                                label="Valor Itens Class."
                                                                type="number"
                                                                format="decimal"
                                                                min="0"
                                                                v-model="dados.valoritensclass.value"
                                                                :errorMessage="errors.valoritensclass"
                                                                right
                                                            />
                                                            <InputAndLabel
                                                                column="3"
                                                                name="qtdeitensclass"
                                                                label="Qtde Itens Class."
                                                                type="number"
                                                                format="integer"
                                                                min="0"
                                                                v-model="dados.qtdeitensclass.value"
                                                                :errorMessage="errors.qtdeitensclass"
                                                                right
                                                            />
                                                            <InputAndLabel
                                                                column="3"
                                                                name="totalpicking"
                                                                label="Qtde Total Picking"
                                                                type="number"
                                                                format="integer"
                                                                min="0"
                                                                v-model="dados.totalpicking.value"
                                                                :errorMessage="errors.totalpicking"
                                                                right
                                                            />
                                                            <InputAndLabel
                                                                column="3"
                                                                name="totalpacking"
                                                                label="Qtde Total Packing"
                                                                type="number"
                                                                format="integer"
                                                                min="0"
                                                                v-model="dados.totalpacking.value"
                                                                :errorMessage="errors.totalpacking"
                                                                right
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 pl-2">
                                                    <div class="card mb-3">
                                                        <div class="card-header">Totalizadores</div>
                                                        <div class="form-row mt-3 mx-3 justify-content-end">
                                                            <InputAndLabel
                                                                column="3"
                                                                name="faturamento"
                                                                label="Faturamento"
                                                                type="number"
                                                                format="decimal"
                                                                min="0"
                                                                step="0.01"
                                                                v-model="dados.faturamento.value"
                                                                :errorMessage="errors.faturamento"
                                                                right
                                                                readonly
                                                            />
                                                            <InputAndLabel
                                                                column="3"
                                                                name="totalreversa"
                                                                label="Total Reversa"
                                                                type="number"
                                                                format="decimal"
                                                                min="0"
                                                                step="0.01"
                                                                v-model="dados.totalreversa.value"
                                                                :errorMessage="errors.totalreversa"
                                                                right
                                                                readonly
                                                            />
                                                            <InputAndLabel
                                                                column="3"
                                                                name="totalitensclass"
                                                                label="Total Itens Class."
                                                                type="number"
                                                                format="decimal"
                                                                min="0"
                                                                step="0.01"
                                                                v-model="dados.totalitensclass.value"
                                                                :errorMessage="errors.totalitensclass"
                                                                right
                                                                readonly
                                                            />
                                                            <InputAndLabel
                                                                column="3"
                                                                name="excedente"
                                                                label="Excedente"
                                                                type="number"
                                                                format="decimal"
                                                                min="0"
                                                                step="0.01"
                                                                v-model="dados.excedente.value"
                                                                :errorMessage="errors.excedente"
                                                                right
                                                            />
                                                            <InputAndLabel
                                                                column="3"
                                                                name="movarm"
                                                                label="Mov. / Arm."
                                                                type="number"
                                                                format="decimal"
                                                                min="0"
                                                                step="0.01"
                                                                v-model="dados.movarm"
                                                                readonly
                                                                right
                                                            />
                                                            <InputAndLabel
                                                                column="3"
                                                                name="insumos"
                                                                label="Insumos"
                                                                type="number"
                                                                format="decimal"
                                                                min="0"
                                                                step="0.01"
                                                                v-model="dados.insumos.value"
                                                                :errorMessage="errors.insumos"
                                                                right
                                                                readonly
                                                            />
                                                            <InputAndLabel
                                                                column="3"
                                                                name="totalocorrencias"
                                                                label="Ocorrências"
                                                                type="number"
                                                                format="decimal"
                                                                min="0"
                                                                step="0.01"
                                                                v-model="dados.totalocorrencias.value"
                                                                :errorMessage="errors.totalocorrencias"
                                                                right
                                                                readonly
                                                            />
                                                            <InputAndLabel
                                                                column="3"
                                                                name="valoradicional"
                                                                label="Valor Adicional"
                                                                type="number"
                                                                format="decimal"
                                                                min="0"
                                                                step="0.01"
                                                                v-model="dados.valoradicional.value"
                                                                :errorMessage="errors.valoradicional"
                                                                right
                                                            />
                                                            <InputAndLabel
                                                                column="3"
                                                                name="total"
                                                                label="Total"
                                                                type="number"
                                                                format="decimal"
                                                                min="0"
                                                                step="0.01"
                                                                v-model="dados.total.value"
                                                                :errorMessage="errors.total"
                                                                right
                                                                readonly
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div class="col-lg-6 mt-4">
                                        <div class="card shadow" v-if="operation == 'update'">
                                            <div class="card-header bg-secondary py-3">
                                                <h6 class="m-0 font-weight-bold text-white row">
                                                    <div class="align-self-center">
                                                        <i class="fas fa-exchange-alt"></i> Movimentos
                                                    </div>
                                                    <div v-show="carregando" class="spinner-grow" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </h6>
                                            </div>

                                            <div class="p-0 card-body">
                                                <div class="col-lg-12 table-responsive">
                                                    <table class="table table-sm table-striped table-bordered my-3">
                                                        <thead>
                                                            <tr style="white-space: nowrap">
                                                                <th>
                                                                    <button
                                                                        v-if="podemud"
                                                                        type="button"
                                                                        class="btn btn-primary"
                                                                        @click="() => abrirModalMov('insert')"
                                                                    >
                                                                        Lançar
                                                                    </button>
                                                                </th>
                                                                <th>Tipo</th>
                                                                <th>Nome</th>
                                                                <th>Custo</th>
                                                                <th>Qtde Valor</th>
                                                                <th>Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                v-for="line in dados.movimentos.dados"
                                                                :key="'movimento-' + line.idcobranca_mov"
                                                            >
                                                                <td>
                                                                    <a
                                                                        v-if="podemud"
                                                                        data-toggle="tooltip"
                                                                        title="Editar/Visualizar"
                                                                        role="button"
                                                                        class="text-primary"
                                                                        @click="
                                                                            () => abrirModalMov(line.idcobranca_mov)
                                                                        "
                                                                    >
                                                                        <i class="fa fa-edit"></i>
                                                                    </a>
                                                                    <a
                                                                        v-if="podemud"
                                                                        data-toggle="tooltip"
                                                                        title="Excluir"
                                                                        role="button"
                                                                        class="text-danger"
                                                                        @click="
                                                                            () =>
                                                                                abrirModalDeleteMov(line.idcobranca_mov)
                                                                        "
                                                                    >
                                                                        <i class="fas fa-trash-alt"></i>
                                                                    </a>
                                                                </td>
                                                                <td
                                                                    v-if="line.nomeindex == 0"
                                                                    :rowspan="line.totalspan"
                                                                    class="text-center align-middle"
                                                                >
                                                                    {{ line.tipo }}
                                                                </td>
                                                                <td>{{ line.nome }}</td>
                                                                <td class="text-right">
                                                                    {{ decimalFormat(line.custo) }}
                                                                </td>
                                                                <td class="text-right">
                                                                    {{ decimalFormat(line.qtdeval) }}
                                                                </td>
                                                                <td class="text-right">
                                                                    {{ decimalFormat(line.total) }}
                                                                </td>
                                                            </tr>
                                                            <tr class="table-success">
                                                                <td :colspan="5" class="text-center align-middle">
                                                                    {{ dados.movimentos.totais.tipo }}
                                                                </td>
                                                                <td class="text-right">
                                                                    {{ decimalFormat(dados.movimentos.totais.total) }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mt-4">
                                        <div class="card shadow" v-if="operation == 'update'">
                                            <div class="card-header bg-secondary py-3">
                                                <h6 class="m-0 font-weight-bold text-white row">
                                                    <div class="align-self-center">
                                                        <i class="fas fa-pallet"></i> Insumos
                                                    </div>
                                                    <div v-show="carregando" class="spinner-grow" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </h6>
                                            </div>

                                            <div class="p-0 card-body">
                                                <div class="col-lg-12 table-responsive">
                                                    <table class="table table-sm table-striped table-bordered my-3">
                                                        <thead>
                                                            <tr style="white-space: nowrap">
                                                                <th>
                                                                    <button
                                                                        v-if="podemud"
                                                                        type="button"
                                                                        class="btn btn-primary"
                                                                        @click="() => abrirModalInsumo('insert')"
                                                                    >
                                                                        Lançar
                                                                    </button>
                                                                </th>
                                                                <th>Insumo</th>
                                                                <th class="text-right">Qtde.</th>
                                                                <th class="text-right">Valor</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                v-for="line in dados._insumos.dados"
                                                                :key="'insumo-' + line.idcobranca_insumos"
                                                            >
                                                                <td>
                                                                    <a
                                                                        v-if="podemud"
                                                                        data-toggle="tooltip"
                                                                        title="Editar/Visualizar"
                                                                        role="button"
                                                                        class="text-primary"
                                                                        @click="
                                                                            () => abrirModalInsumo(line.idcobranca_insumos)
                                                                        "
                                                                    >
                                                                        <i class="fa fa-edit"></i>
                                                                    </a>
                                                                    <a
                                                                        v-if="podemud"
                                                                        data-toggle="tooltip"
                                                                        title="Excluir"
                                                                        role="button"
                                                                        class="text-danger"
                                                                        @click="
                                                                            () =>
                                                                                abrirModalDeleteInsumo(line.idcobranca_insumos)
                                                                        "
                                                                    >
                                                                        <i class="fas fa-trash-alt"></i>
                                                                    </a>
                                                                </td>
                                                                <td>{{ line.insumo }}</td>
                                                                <td class="text-right">
                                                                    {{ decimalFormat(line.qtde) }}
                                                                </td>
                                                                <td class="text-right">
                                                                    {{ decimalFormat(line.valor) }}
                                                                </td>
                                                            </tr>
                                                            <tr class="table-success">
                                                                <td :colspan="3" class="text-center align-middle">
                                                                    {{ dados._insumos.totais.tipo }}
                                                                </td>
                                                                <td class="text-right">
                                                                    {{ decimalFormat(dados._insumos.totais.total) }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mt-4">
                                        <div class="card shadow" v-if="operation == 'update'">
                                            <div class="card-header bg-secondary py-3">
                                                <h6 class="m-0 font-weight-bold text-white row">
                                                    <div class="align-self-center">
                                                        <i class="fas fa-tags"></i> Ocorrências
                                                    </div>
                                                    <div v-show="carregando" class="spinner-grow" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </h6>
                                            </div>

                                            <div class="p-0 card-body">
                                                <div class="col-lg-12 table-responsive">
                                                    <table class="table table-sm table-striped table-bordered my-3">
                                                        <thead>
                                                            <tr style="white-space: nowrap">
                                                                <th>
                                                                    <button
                                                                        v-if="podemud"
                                                                        type="button"
                                                                        class="btn btn-primary"
                                                                        @click="() => abrirModalOcorrencia('insert')"
                                                                    >
                                                                        Lançar
                                                                    </button>
                                                                </th>
                                                                <th>Ocorrencia</th>
                                                                <th>Motivo</th>
                                                                <th>Data</th>
                                                                <th class="text-right">Qtde.</th>
                                                                <th class="text-right">Valor</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                v-for="line in dados._ocorrencias.dados"
                                                                :key="'ocorrencia-' + line.idcobranca_ocorrencias"
                                                            >
                                                                <td>
                                                                    <a
                                                                        v-if="podemud"
                                                                        data-toggle="tooltip"
                                                                        title="Editar/Visualizar"
                                                                        role="button"
                                                                        class="text-primary"
                                                                        @click="
                                                                            () => abrirModalOcorrencia(line.idcobranca_ocorrencias)
                                                                        "
                                                                    >
                                                                        <i class="fa fa-edit"></i>
                                                                    </a>
                                                                    <a
                                                                        v-if="podemud"
                                                                        data-toggle="tooltip"
                                                                        title="Excluir"
                                                                        role="button"
                                                                        class="text-danger"
                                                                        @click="
                                                                            () =>
                                                                                abrirModalDeleteOcorrencia(line.idcobranca_ocorrencias)
                                                                        "
                                                                    >
                                                                        <i class="fas fa-trash-alt"></i>
                                                                    </a>
                                                                </td>
                                                                <td>{{ line.ocorrencia }}</td>
                                                                <td>{{ line.motivo }}</td>
                                                                <td>{{ dateFormat(line.data) }}</td>
                                                                <td class="text-right">
                                                                    {{ decimalFormat(line.qtde) }}
                                                                </td>
                                                                <td class="text-right">
                                                                    {{ decimalFormat(line.valor) }}
                                                                </td>
                                                            </tr>
                                                            <tr class="table-success">
                                                                <td :colspan="5" class="text-center align-middle">
                                                                    {{ dados._ocorrencias.totais.tipo }}
                                                                </td>
                                                                <td class="text-right">
                                                                    {{ decimalFormat(dados._ocorrencias.totais.total) }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            v-if="operation == 'update'"
            id="modalOcorrencia"
            title="Ocorrencia"
            confirmClass="btn btn-danger d-none"
            :confirmFunction="() => null"
            noFooter
        >
            <CobrancaOcorrencia
                v-if="isDataLoaded()"
                :id="ocorrenciaSelecionado"
                :parentsetOcorrenciaSelecionado="setOcorrenciaSelecionado"
                :idparent="dados[idname].value"
                :parentBaseUrl="baseUrl"
                :parentGetData="getData"
            />
        </Modal>
        <Modal
            v-if="operation == 'update'"
            id="modalInsumo"
            title="Insumo"
            confirmClass="btn btn-danger d-none"
            :confirmFunction="() => null"
            noFooter
        >
            <CobrancaInsumo
                v-if="isDataLoaded()"
                :id="insumoSelecionado"
                :parentsetInsumoSelecionado="setInsumoSelecionado"
                :idparent="dados[idname].value"
                :parentBaseUrl="baseUrl"
                :parentGetData="getData"
            />
        </Modal>
        <Modal
            v-if="operation == 'update'"
            id="modalMov"
            title="Movimento"
            confirmClass="btn btn-danger d-none"
            :confirmFunction="() => null"
            noFooter
        >
            <CobrancaMov
                v-if="isDataLoaded()"
                :id="movSelecionada"
                :parentSetMovSelecionada="setMovSelecionada"
                :idparent="dados[idname].value"
                :parentBaseUrl="baseUrl"
                :parentGetData="getData"
            />
        </Modal>
        <Modal
            id="modalDeleteInsumo"
            title="Confirmar Exclusão"
            confirmClass="btn btn-danger"
            confirmText="Excluir"
            :confirmFunction="deleteInsumo"
        >
            Tem certeza que deseja excluir este insumo?
        </Modal>
        <Modal
            id="modalDeleteOcorrencia"
            title="Confirmar Exclusão"
            confirmClass="btn btn-danger"
            confirmText="Excluir"
            :confirmFunction="deleteOcorrencia"
        >
            Tem certeza que deseja excluir este ocorrencia?
        </Modal>
        <Modal
            id="modalDeleteMov"
            title="Confirmar Exclusão"
            confirmClass="btn btn-danger"
            confirmText="Excluir"
            :confirmFunction="deleteMov"
        >
            Tem certeza que deseja excluir este movimento?
        </Modal>
        <Modal
            id="modalExcluir"
            title="Confirmar Exclusão"
            confirmClass="btn btn-danger"
            confirmText="Excluir"
            :confirmFunction="deletar"
        >
            Tem certeza que deseja excluir esta cobrança?
        </Modal>
        <ForbiddenModal />
        <LogoutModal />
    </div>
</template>

<script>
import api, { getUsername } from "../services/api";
import Topbar from "@/components/Topbar";
import InputAndLabel from "@/components/InputAndLabel";
import SelectAndLabel from "@/components/SelectAndLabel";
import DateAndLabel from "@/components/DateAndLabel";
import Quadro from "@/components/Quadro";
import LogoutModal from "@/components/LogoutModal.vue";
import Modal from "@/components/Modal";
import ViewMixin from "./ViewMixin";
import $ from "jquery";
import {
    ClientErrorException,
    DEFAULT_ERROR_MESSAGE,
    PermissaoException,
    ServerErrorException,
    TokenInvalidoException,
    ValidationException,
} from "../services/exceptions";
import ForbiddenModal from "../components/ForbiddenModal.vue";
import CheckboxAndLabel from "../components/CheckboxAndLabel.vue";
import TextareaAndLabel from "../components/TextareaAndLabel.vue";
import { maskCNPJCPF, wait } from "../utils";
import Select3 from "../components/Select3.vue";
import CobrancaMov from "./CobrancaMov.vue";
import CobrancaInsumo from "./CobrancaInsumo.vue";
import CobrancaOcorrencia from "./CobrancaOcorrencia.vue";

export default {
    name: "Cobranca",
    mixins: [ViewMixin],
    components: {
        Topbar,
        LogoutModal,
        Modal,
        InputAndLabel,
        SelectAndLabel,
        Quadro,
        ForbiddenModal,
        CheckboxAndLabel,
        DateAndLabel,
        TextareaAndLabel,
        Select3,
        CobrancaMov,
        CobrancaInsumo,
        CobrancaOcorrencia,
    },
    props: ["id"],
    data: function () {
        return {
            idname: "idcobranca",
            baseUrl: "cobrancas",
            title: {
                icon: "fas fa-money-check-alt",
                text: "Cobranças",
            },
            combos: {
                idcliente: [],
            },
            // dados: {
            //   documento: ''
            // },
            cnpj: "",
            cnpjex: "",
            idtipomov_val: "",
            idarm_val: "",
            idtamanho_val: "",
            modalDeleteCnpj: {
                id: "",
            },
            modalDeleteMovimento: {
                id: "",
            },
            modalDeleteTamanho: {
                id: "",
            },
            movSelecionada: "insert",
            insumoSelecionado: "insert",
            ocorrenciaSelecionado: "insert",
        };
    },
    computed: {
        podemud: function () {
            return this.getValueData("aprov") != "1";
        },
    },

    beforeRouteLeave(to, from, next) {
        this.areYouSure(next);
    },

    mounted() {
        this.defineOperation(this.id);
        this.getCombo().then(this.getData);
    },
    methods: {
        async getCombo() {
            this.carregando = true;

            try {
                let response = await api.get(`/${this.baseUrl}/combosToSave/${this.id}`);
                let data = response.data;
                this.combos = data;
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
        async getData(id = this.id) {
            this.carregando = true;

            try {
                let response = await api.get(`/${this.baseUrl}/${id}`);
                let data = response.data;
                this.setData(data, [
                    "idstatus",
                    "status",
                    "armazenamentos",
                    "tamanhos",
                    "movimentos",
                    "_insumos",
                    "_ocorrencias",
                    "cnpjs",
                    "cnpjsex",
                    "movarm",
                ]);
                if (data.aprov == "1") {
                    this.setMessage("Cobranças aprovadas não podem ser editadas", "warning");
                }
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
        async salvar() {
            this.carregando = true;

            try {
                let response;
                let id = this.dados[this.idname].value;
                let newData;
                let fieldsToDelete = [
                    "idstatus",
                    "status",
                    "armazenamentos",
                    "tamanhos",
                    "movimentos",
                    "_insumos",
                    "_ocorrencias",
                    "cnpjs",
                    "cnpjsex",
                    "movarm",
                ];
                if (this.operation === "update") {
                    newData = this.getDataToUpdate(fieldsToDelete);
                    response = await api.put(`/${this.baseUrl}/${id}`, newData);
                } else {
                    newData = this.getDataToInsert(fieldsToDelete);
                    response = await api.post(`/${this.baseUrl}`, newData);
                }
                let data = response.data;
                id = data.id;
                this.dados[this.idname] = { value: id, oldValue: id };
                this.operation = "update";

                this.updateData(newData);

                this.changeURL(`/${this.baseUrl}/${id}`);

                this.setMessage(data.successMessage, "success");
                this.errors = {};

                this.getData(id);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
        async deletar() {
            this.carregando = true;

            $("#modalExcluir").modal("hide");

            try {
                let id = this.dados[this.idname].value;
                await api.delete(`/${this.baseUrl}/${id}`);

                this.$router.push(`/${this.baseUrl}`);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },

        setMovSelecionada(v) {
            this.movSelecionada = v;
        },

        setInsumoSelecionado(v) {
            this.insumoSelecionado = v;
        },

        setOcorrenciaSelecionado(v) {
            this.ocorrenciaSelecionado = v;
        },

        abrirModalMov(id) {
            if (this.needConfirmToLeave) {
                if (confirm("Você tem alterações não salvas, salvar antes de editar?")) {
                    this.salvar();
                } else {
                    return null;
                }
            }
            this.movSelecionada = id;
            $("#modalMov").modal("show");
        },

        abrirModalInsumo(id) {
            if (this.needConfirmToLeave) {
                if (confirm("Você tem alterações não salvas, salvar antes de editar?")) {
                    this.salvar();
                } else {
                    return null;
                }
            }
            this.insumoSelecionado = id;
            $("#modalInsumo").modal("show");
        },

        abrirModalOcorrencia(id) {
            if (this.needConfirmToLeave) {
                if (confirm("Você tem alterações não salvas, salvar antes de editar?")) {
                    this.salvar();
                } else {
                    return null;
                }
            }
            this.ocorrenciaSelecionado = id;
            $("#modalOcorrencia").modal("show");
        },

        abrirModalDeleteMov(id) {
            this.movSelecionada = id;
            $("#modalDeleteMov").modal("show");
        },

        abrirModalDeleteInsumo(id) {
            this.insumoSelecionado = id;
            $("#modalDeleteInsumo").modal("show");
        },

        abrirModalDeleteOcorrencia(id) {
            this.ocorrenciaSelecionado = id;
            $("#modalDeleteOcorrencia").modal("show");
        },

        async deleteMov() {
            this.carregando = true;

            $("#modalDeleteMov").modal("hide");

            try {
                let id = this.movSelecionada;
                await api.delete(`/${this.baseUrl}/${this.dados[this.idname].value}/mov/${id}`);
                this.dados.movimentos = this.dados.movimentos.filter((el) => el.idcobranca_mov != id);

                this.getData();
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },

        async deleteInsumo() {
            this.carregando = true;

            $("#modalDeleteInsumo").modal("hide");

            try {
                let id = this.insumoSelecionado;
                await api.delete(`/${this.baseUrl}/${this.dados[this.idname].value}/insumo/${id}`);
                // this.dados._insumos = this.dados._insumos.filter((el) => el.idcobranca_insumos != id);

                this.getData();
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
                console.error(e);
            }

            this.carregando = false;
        },

        async deleteOcorrencia() {
            this.carregando = true;

            $("#modalDeleteOcorrencia").modal("hide");

            try {
                let id = this.ocorrenciaSelecionado;
                await api.delete(`/${this.baseUrl}/${this.dados[this.idname].value}/ocorrencia/${id}`);
                // this.dados._ocorrencias = this.dados._ocorrencias.filter((el) => el.idcobranca_ocorrencias != id);

                this.getData();
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
                console.error(e);
            }

            this.carregando = false;
        },

        calcularFaturamento() {
            this.dados.faturamento.value =
                (parseFloat(this.dados.gmv.value) || 0) * ((parseFloat(this.dados.takerate.value) || 0) / 100);
            this.calcularTotal();
        },
        calcularReversa() {
            this.dados.totalreversa.value =
                (parseFloat(this.dados.valorreversa.value) || 0) * (parseFloat(this.dados.qtdereversa.value) || 0);
            this.calcularTotal();
        },
        calcularItensClass() {
            this.dados.totalitensclass.value =
                (parseFloat(this.dados.valoritensclass.value) || 0) *
                (parseFloat(this.dados.qtdeitensclass.value) || 0);
            this.calcularTotal();
        },
        async calcularTotal(sleep = true) {
            if (sleep) {
                await wait(100);
            }
            this.dados.total.value =
                (parseFloat(this.dados.faturamento.value) || 0) +
                (parseFloat(this.dados.insumos.value) || 0) +
                (parseFloat(this.dados.totalocorrencias.value) || 0) +
                (parseFloat(this.dados.totalreversa.value) || 0) +
                (parseFloat(this.dados.totalitensclass.value) || 0) +
                (parseFloat(this.dados.excedente.value) || 0) +
                (parseFloat(this.dados.movarm) || 0) +
                (parseFloat(this.dados.valoradicional.value) || 0);
        },
    },

    watch: {
        "dados.gmv.value": {
            handler: function (newVal, oldVal) {
                if (oldVal != undefined) {
                    this.calcularFaturamento();
                }
            },
        },
        "dados.takerate.value": {
            handler: function (newVal, oldVal) {
                if (oldVal != undefined) {
                    this.calcularFaturamento();
                }
            },
        },

        "dados.valorreversa.value": {
            handler: function (newVal, oldVal) {
                if (oldVal != undefined) {
                    this.calcularReversa();
                }
            },
        },
        "dados.qtdereversa.value": {
            handler: function (newVal, oldVal) {
                if (oldVal != undefined) {
                    this.calcularReversa();
                }
            },
        },

        "dados.valoritensclass.value": {
            handler: function (newVal, oldVal) {
                if (oldVal != undefined) {
                    this.calcularItensClass();
                }
            },
        },
        "dados.qtdeitensclass.value": {
            handler: function (newVal, oldVal) {
                if (oldVal != undefined) {
                    this.calcularItensClass();
                }
            },
        },

        // "dados.insumos.value": {
        //     handler: function (newVal, oldVal) {
        //         if (oldVal != undefined) {
        //             this.calcularTotal(false);
        //         }
        //     },
        // },
        // "dados.totalocorrencias.value": {
        //     handler: function (newVal, oldVal) {
        //         if (oldVal != undefined) {
        //             this.calcularTotal(false);
        //         }
        //     },
        // },
        "dados.excedente.value": {
            handler: function (newVal, oldVal) {
                if (oldVal != undefined) {
                    this.calcularTotal(false);
                }
            },
        },
        "dados.valoradicional.value": {
            handler: function (newVal, oldVal) {
                if (oldVal != undefined) {
                    this.calcularTotal(false);
                }
            },
        },
    },
};
</script>

<style></style>
