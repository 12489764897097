var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        id: "logoutModal",
        title: "Pronto para partir?",
        confirmText: "Sair",
        confirmFunction: _vm.$parent.logout,
      },
    },
    [
      _vm._v(
        ' Selecione "Sair" abaixo se você estiver pronto para encerrar sua sessão atual. '
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }