<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top mb-4 shadow">
        <!-- Sidebar Toggle (Topbar) -->
        <button id="sidebarToggleTop" class="btn btn-link d-md-none d-none rounded-circle mr-3">
            <i class="fa fa-bars"></i>
        </button>

        <router-link to="/home">
            <img
                src="../assets/logo.svg"
                height="40"
                style="margin-top: 5px; margin-bottom: 5px"
                class="d-inline-block align-top"
            />
        </router-link>

        <!--<h4 class="pt-2">Grupo Toys</h4>-->
        <button
            class="btn btn-link d-lg-none"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
            <i class=" fa fa-bars"></i>
        </button>
        <!-- Nav Item - Dashboard -->
        <div class="collapse navbar-collapse " id="navbarSupportedContent">
            <!-- Topbar Navbar -->
            <ul class="navbar-nav ml-auto">
                <div class="dropdown-divider border-gray"></div>

                <!-- <li class="nav-item d-lg-inline">
                    <router-link class="nav-link " to="/produtos">
                        <i class="fas fa-boxes"></i>
                        <span class="mr-0 d-lg-inline"> Produtos</span>
                    </router-link>
                </li> -->
                <!-- Nav Item - Utilities Collapse Menu -->

                <li class="nav-item dropdown d-lg-inline">
                    <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        id="dropdown01"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i class="fa fa-fw fa-bars mr-1"></i>
                        <span class="d-lg-inline">Menu</span>
                    </a>
                    <div class="dropdown-menu shadow animated--grow-in" aria-labelledby="dropdown01">
                        <div class=" py-2 dropdown-inner rounded">
                            <router-link class="dropdown-item" to="/resumos">
                                <i class="fas fa-clipboard-check mr-2"></i>Resumos
                            </router-link>
                            <router-link class="dropdown-item" to="/cobrancas">
                                <i class="fas fa-money-check-alt mr-2"></i>Cobranças
                            </router-link>
                            <router-link class="dropdown-item" to="/contratos">
                                <i class="fas fa-file-contract mr-2"></i>Contratos
                            </router-link>
                            <router-link class="dropdown-item" to="/clientescob">
                                <i class="fas fa-users mr-2"></i>Clientes Cobrança
                            </router-link>
                            <!-- <router-link class="dropdown-item" to="/clientes">
                                <i class="fas fa-address-book mr-2"></i>Clientes
                            </router-link> -->
                            <div class="dropdown-divider"></div>
                            <router-link class="dropdown-item" to="/tabcob">
                                <i class="fas fa-cash-register mr-1"></i>Tabela de Cobrança
                            </router-link>
                            <router-link class="dropdown-item" to="/ocorrencias">
                                <i class="fas fa-tags mr-1"></i>Ocorrências
                            </router-link>
                            <router-link class="dropdown-item" to="/insumos">
                                <i class="fas fa-pallet mr-1"></i>Insumos
                            </router-link>
                            <div class="dropdown-divider"></div>
                            <router-link class="dropdown-item" to="/perfis">
                                <i class="fas fa-id-card mr-2"></i>Perfil de Acesso
                            </router-link>
                            <router-link class="dropdown-item" to="/usuarios">
                                <i class="fas fa-users mr-2"></i>Usuários
                            </router-link>
                        </div>
                    </div>
                </li>

                <div class="dropdown-divider border-gray"></div>

                <!-- Nav Item - User Information -->
                <li class="nav-item dropdown d-lg-inline no-arrow">
                    <a
                        class="nav-link dropdown-toggle "
                        role="button"
                        href="#"
                        id="userDropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i class="fa fa-fw fa-user"></i>
                        <span class="d-lg-inline"> {{ username }}</span>
                    </a>
                    <!-- Dropdown - User Information -->
                    <div
                        class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="userDropdown"
                    >
                        <div class="dropdown-divider border-gray"></div>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                            <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2"></i>
                            Sair
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import { getUsername } from "../services/api";
export default {
    name: "Topbar",
    computed: {
        username: function() {
            return getUsername().split(" ")[0];
        },
    },
};
</script>

<style lang="scss" scoped>
a.disabled {
    opacity: 0.5;
}

a {
    font-size: 0.95em;
}
a.dropdown-item {
    color: var(--gray) !important;
}
a.dropdown-item:active {
    color: var(--white) !important;
}
</style>
