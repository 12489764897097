<template>
  <div
    class="spinner-grow"
    :style="{ width: size, height: size }"
    role="status"
  >
    <span class="sr-only">Loading...</span>
  </div>
</template>

<script>
export default {
  name: "Carregando",
  props: {
    size: {
      default: '1em'
    }
  },
};
</script>

<style>
</style>