<template>
  <div>
    <Topbar />
    <div class="card shadow mx-3 conteudo text-left" style="margin-top: 90px">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary-dark">
          <i :class="`${title.icon} mr-2`"></i>{{ title.text }}
          <div v-show="carregando" class="spinner-grow" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </h6>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12">
            <fieldset
              v-if="isDataLoaded()"
              :disabled="carregando"
            >
              <Quadro :type="messageType" :message="message" />              
              <form>
                <div class="d-print-none">
                  <router-link
                    :to="`/${this.baseUrl}`"
                    id="btn_voltar"
                    class="btn btn-secondary mr-1"
                  >
                    <i class="fa fa-arrow-left"></i> Voltar</router-link
                  >
                  <button
                    type="button"
                    id="btnSave"
                    class="btn btn-primary mr-1"
                    @click="salvar"
                  >
                    Salvar
                  </button>
                  <hr />
                </div>
                <fieldset>
                  <div v-for="v in this.dados" :key="v.campo" class="form-row">

                    <InputAndLabel
                      column="6"
                      :name="v.campo"
                      :label="v.label"
                      type="text"
                      v-model="v.valor"
                    />

                  </div>
                </fieldset>
              </form>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
    <ForbiddenModal />
    <LogoutModal />
  </div>
</template>

<script>
import api from "../services/api";
import Topbar from "@/components/Topbar";
import InputAndLabel from "@/components/InputAndLabel";
import SelectAndLabel from "@/components/SelectAndLabel";
import Quadro from "@/components/Quadro";
import LogoutModal from "@/components/LogoutModal.vue";
import Modal from "@/components/Modal";
import ViewMixin from "./ViewMixin";
import $ from "jquery";
import {
    ClientErrorException,
  DEFAULT_ERROR_MESSAGE,
  PermissaoException,
  ServerErrorException,
  TokenInvalidoException,
  ValidationException,
} from "../services/exceptions";
import ForbiddenModal from '../components/ForbiddenModal.vue';
import CheckboxAndLabel from '../components/CheckboxAndLabel.vue';

export default {
  name: "CFG",
  mixins: [ViewMixin],
  components: {
    Topbar,
    LogoutModal,
    Modal,
    InputAndLabel,
    SelectAndLabel,
    Quadro,
    ForbiddenModal,
    CheckboxAndLabel
  },
  props: ["id"],
  data: function () {
    return {
      idname: 'id',
      baseUrl: "cfg",
      title: {
        icon: "fas fa-cogs",
        text: "Configurações"
      },
      combos: {
        idperfil: []
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.carregando = true;

      try {
        let response = await api.get(`/${this.baseUrl}`);
        let data = response.data;
        this.dados = data;
      } catch (e) {
        if (e instanceof ValidationException) {
          this.handleValidationException(e);
        } else if (e instanceof PermissaoException) {
          this.handlePermissaoException();
        } else if (e instanceof TokenInvalidoException) {
          this.handleTokenInvalidoException();
        } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
          this.setMessage(e.message, "danger");
        } else {
          this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
        }        
      }

      this.carregando = false;
    },
    async salvar() {
      this.carregando = true;

      try {
        let response;
        let newData = this.dados;
        response = await api.put(
            `/${this.baseUrl}`,
            newData
          );
        let data = response.data;

        this.setMessage(data.successMessage, "success");
        this.errors = {};
      } catch (e) {
        if (e instanceof ValidationException) {
          this.handleValidationException(e);
        } else if (e instanceof PermissaoException) {
          this.handlePermissaoException();
        } else if (e instanceof TokenInvalidoException) {
          this.handleTokenInvalidoException();
        } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
          this.setMessage(e.message, "danger");
        } else {
          this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
        }
      }

      this.carregando = false;
    },
  },
};
</script>

<style>
</style>