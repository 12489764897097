<template>
    <div>
        <Topbar />
        <div class="card shadow mx-3 conteudo text-left" style="margin-top: 90px">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary-dark">
                    <i :class="`${title.icon} mr-2`"></i>{{ title.text }}
                    <div v-show="carregando" class="spinner-grow" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </h6>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-12">
                        <fieldset v-if="isDataLoaded()" :disabled="carregando">
                            <Quadro :type="messageType" :message="message" />
                            <form>
                                <div class="d-print-none">
                                    <a
                                        @click="$router.go(-1)"
                                        id="btn_voltar"
                                        class="btn btn-secondary mr-1"
                                    >
                                        <i class="fa fa-arrow-left"></i> Voltar
                                    </a>
                                    <button type="button" id="btnSave" class="btn btn-primary mr-1" @click="salvar">
                                        Salvar
                                    </button>
                                    <button
                                        type="button"
                                        id="btn_del"
                                        class="btn btn-danger mr-1"
                                        :disabled="operation === 'insert'"
                                        data-toggle="modal"
                                        data-target="#modalExcluir"
                                    >
                                        Excluir
                                    </button>
                                    <hr />
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <fieldset>
                                            <div class="form-row">
                                                <InputAndLabel
                                                    column="1"
                                                    name="id"
                                                    label="ID"
                                                    type="text"
                                                    v-model="dados[idname].value"
                                                    readonly
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="data"
                                                    label="Data"
                                                    type="date"
                                                    v-model="dados.data.value"
                                                    :errorMessage="errors.data"
                                                />
                                                <SelectAndLabel
                                                    input
                                                    column="9"
                                                    name="idcliente"
                                                    label="Cliente"
                                                    :options="combos.idcliente"
                                                    v-model="dados.idcliente.value"
                                                    :errorMessage="errors.idcliente"
                                                />
                                            </div>
                                            <div class="form-row">
                                                <InputAndLabel
                                                    column="2"
                                                    name="valortotalfaturamento"
                                                    label="Valor Total Faturamento"
                                                    type="number"
                                                    format="decimal"
                                                    min="0"
                                                    step="0.01"
                                                    v-model="dados.valortotalfaturamento.value"
                                                    :errorMessage="errors.valortotalfaturamento"
                                                    right
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="totalpicking"
                                                    label="Total Picking"
                                                    type="number"
                                                    format="integer"
                                                    min="0"
                                                    v-model="dados.totalpicking.value"
                                                    :errorMessage="errors.totalpicking"
                                                    right
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="totalpacking"
                                                    label="Total Packing"
                                                    type="number"
                                                    format="integer"
                                                    min="0"
                                                    v-model="dados.totalpacking.value"
                                                    :errorMessage="errors.totalpacking"
                                                    right
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="ticketmedio"
                                                    label="Ticket Médio"
                                                    format="decimal"
                                                    type="number"
                                                    min="0"
                                                    step="0.01"
                                                    v-model="dados.ticketmedio.value"
                                                    :errorMessage="errors.ticketmedio"
                                                    right
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="totalitensestoque"
                                                    label="Valor Itens Estoque"
                                                    type="number"
                                                    format="integer"
                                                    min="0"
                                                    v-model="dados.totalitensestoque.value"
                                                    :errorMessage="errors.totalitensestoque"
                                                    right
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="valortotalestoque"
                                                    label="Valor Total Estoque"
                                                    type="number"
                                                    format="decimal"
                                                    min="0"
                                                    step="0.01"
                                                    v-model="dados.valortotalestoque.value"
                                                    :errorMessage="errors.valortotalestoque"
                                                    right
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="itensrecebidos"
                                                    label="Itens Recebidos"
                                                    type="number"
                                                    format="integer"
                                                    min="0"
                                                    v-model="dados.itensrecebidos.value"
                                                    :errorMessage="errors.itensrecebidos"
                                                    right
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="qtdetiquetaemitida"
                                                    label="Qtd Etiqueta Emitida"
                                                    type="number"
                                                    format="integer"
                                                    min="0"
                                                    v-model="dados.qtdetiquetaemitida.value"
                                                    :errorMessage="errors.qtdetiquetaemitida"
                                                    right
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="itensclassificados"
                                                    label="Itens Classificados"
                                                    type="number"
                                                    format="integer"
                                                    min="0"
                                                    v-model="dados.itensclassificados.value"
                                                    :errorMessage="errors.itensclassificados"
                                                    right
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="qtdevolumesreversa"
                                                    label="Qtde Volumes Reversa"
                                                    type="number"
                                                    format="integer"
                                                    min="0"
                                                    v-model="dados.qtdevolumesreversa.value"
                                                    :errorMessage="errors.qtdevolumesreversa"
                                                    right
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="valototalinsumos"
                                                    label="Valor Total Insumos"
                                                    type="number"
                                                    format="decimal"
                                                    min="0"
                                                    step="0.01"
                                                    v-model="dados.valototalinsumos.value"
                                                    :errorMessage="errors.valototalinsumos"
                                                    right
                                                />
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>                                
                            </form>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            id="modalExcluir"
            title="Confirmar Exclusão"
            confirmClass="btn btn-danger"
            confirmText="Excluir"
            :confirmFunction="deletar"
        >
            Tem certeza que deseja excluir este contrato?
        </Modal>
        <ForbiddenModal />
        <LogoutModal />
    </div>
</template>

<script>
import api, { getUsername } from "../services/api";
import Topbar from "@/components/Topbar";
import InputAndLabel from "@/components/InputAndLabel";
import SelectAndLabel from "@/components/SelectAndLabel";
import DateAndLabel from "@/components/DateAndLabel";
import Quadro from "@/components/Quadro";
import LogoutModal from "@/components/LogoutModal.vue";
import Modal from "@/components/Modal";
import ViewMixin from "./ViewMixin";
import $ from "jquery";
import {
    ClientErrorException,
    DEFAULT_ERROR_MESSAGE,
    PermissaoException,
    ServerErrorException,
    TokenInvalidoException,
    ValidationException,
} from "../services/exceptions";
import ForbiddenModal from "../components/ForbiddenModal.vue";
import CheckboxAndLabel from "../components/CheckboxAndLabel.vue";
import TextareaAndLabel from "../components/TextareaAndLabel.vue";
import { maskCNPJCPF } from "../utils";
import Select3 from '../components/Select3.vue';

export default {
    name: "Contrato",
    mixins: [ViewMixin],
    components: {
        Topbar,
        LogoutModal,
        Modal,
        InputAndLabel,
        SelectAndLabel,
        Quadro,
        ForbiddenModal,
        CheckboxAndLabel,
        DateAndLabel,
        TextareaAndLabel,
        Select3,
    },
    props: ["id"],
    data: function() {
        return {
            idname: "idresumo",
            baseUrl: "resumos",
            title: {
                icon: "fas fa-clipboard-check",
                text: "Resumo",
            },
            combos: {
                idcliente: [],
            },
            // dados: {
            //   documento: ''
            // },
            cnpj: "",
            cnpjex: "",
            idtipomov_val: "",
            idarm_val: "",
            idtamanho_val: "",
            modalDeleteCnpj: {
                id: ""
            },
            modalDeleteMovimento: {
                id: ""
            },
            modalDeleteTamanho: {
                id: ""
            },
        };
    },
    beforeRouteLeave(to, from, next) {
        this.areYouSure(next);
    },
    mounted() {
        this.defineOperation(this.id);
        this.getCombo().then(this.getData);
    },
    methods: {
        async getCombo() {
            this.carregando = true;

            try {
                let response = await api.get(`/${this.baseUrl}/combosToSave/${this.id}`);
                let data = response.data;
                this.combos = data;
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
        async getData(id = this.id) {
            this.carregando = true;

            try {
                let response = await api.get(`/${this.baseUrl}/${id}`);
                let data = response.data;
                this.setData(data, ["idstatus", "status", "armazenamentos", "tamanhos", "movimentos", "cnpjs", "cnpjsex"]);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
        async salvar() {
            this.carregando = true;

            try {
                let response;
                let id = this.dados[this.idname].value;
                let newData;
                let fieldsToDelete = ["idstatus", "status", "armazenamentos", "tamanhos", "movimentos", "cnpjs", "cnpjsex"];
                if (this.operation === "update") {
                    newData = this.getDataToUpdate(fieldsToDelete);
                    response = await api.put(`/${this.baseUrl}/${id}`, newData);
                } else {
                    newData = this.getDataToInsert(fieldsToDelete);
                    response = await api.post(`/${this.baseUrl}`, newData);
                }
                let data = response.data;
                id = data.id;
                this.dados[this.idname] = { value: id, oldValue: id };
                this.operation = "update";

                this.updateData(newData);

                this.changeURL(`/${this.baseUrl}/${id}`);

                this.setMessage(data.successMessage, "success");
                this.errors = {};
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
        async deletar() {
            this.carregando = true;

            $("#modalExcluir").modal("hide");

            try {
                let id = this.dados[this.idname].value;
                await api.delete(`/${this.baseUrl}/${id}`);

                this.$router.push(`/${this.baseUrl}`);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },

        async postarCnpj() {
            this.carregando = true;
            $("#cnpjCard").collapse("hide");

            try {
                let response;
                let idcontrato = this.dados[this.idname].value;
                let cnpj = this.cnpj;
                response = await api.post(`/${this.baseUrl}/cnpj`, { idcontrato, cnpj });
                let dados = response.data;
                let { id } = dados.result;

                this.setMessage(dados.successMessage, "success");
                this.errors = {};
                this.cnpj = "";

                this.getData(idcontrato);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },

        abrirModalDeleteCnpj(id) {
            this.modalDeleteCnpj.id = id;
            $("#modalDeleteCnpj").modal("show");
        },

        async deleteCnpj() {
            this.carregando = true;

            $("#modalDeleteCnpj").modal("hide");

            try {
                let id = this.modalDeleteCnpj.id;
                await api.delete(`/${this.baseUrl}/cnpj/${id}`);
                this.dados.cnpjs = this.dados.cnpjs.filter((el) => el.idcontratos_cnpj != id);
                this.getData();
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },

        async postarCnpjEx() {
            this.carregando = true;
            $("#cnpjCard").collapse("hide");

            try {
                let response;
                let idcontrato = this.dados[this.idname].value;
                let cnpj = this.cnpjex;
                response = await api.post(`/${this.baseUrl}/cnpjex`, { idcontrato, cnpj });
                let dados = response.data;
                let { id } = dados.result;

                this.setMessage(dados.successMessage, "success");
                this.errors = {};
                this.cnpjex = "";

                this.getData(idcontrato);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },

        abrirModalDeleteCnpjEx(id) {
            this.modalDeleteCnpj.id = id;
            $("#modalDeleteCnpjEx").modal("show");
        },

        async deleteCnpjEx() {
            this.carregando = true;

            $("#modalDeleteCnpjEx").modal("hide");

            try {
                let id = this.modalDeleteCnpj.id;
                await api.delete(`/${this.baseUrl}/cnpjex/${id}`);
                this.getData();
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },

        async postarMovimento() {
            this.carregando = true;
            $("#movimentoCard").collapse("hide");

            try {
                let response;
                let idcontrato = this.dados[this.idname].value;
                let idtipomov_val = this.idtipomov_val;
                response = await api.post(`/${this.baseUrl}/movimento`, { idcontrato, idtipomov_val });
                let dados = response.data;
                let retorno = dados.result;

                this.dados.movimentos.unshift(retorno);

                this.setMessage(dados.successMessage, "success");
                this.errors = {};
                this.idtipomov_val = "";
                await this.getCombo();
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },

        abrirModalDeleteMovimento(id) {
            this.modalDeleteMovimento.id = id;
            $("#modalDeleteMovimento").modal("show");
        },

        async deleteMovimento() {
            this.carregando = true;

            $("#modalDeleteMovimento").modal("hide");

            try {
                let id = this.modalDeleteMovimento.id;
                await api.delete(`/${this.baseUrl}/movimento/${id}`);
                this.dados.movimentos = this.dados.movimentos.filter((el) => el.idtipomov_val != id);
                await this.getCombo();
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },

        async postarArmazenamento() {
            this.carregando = true;
            $("#armazenamentoCard").collapse("hide");

            try {
                let response;
                let idcontrato = this.dados[this.idname].value;
                let idarm_val = this.idarm_val;
                response = await api.post(`/${this.baseUrl}/armazenamento`, { idcontrato, idarm_val });
                let dados = response.data;
                let retorno = dados.result;

                this.dados.armazenamentos.unshift(retorno);

                this.setMessage(dados.successMessage, "success");
                this.errors = {};
                this.idarm_val = "";
                await this.getCombo();
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },

        abrirModalDeleteArmazenamento(id) {
            this.modalDeleteArmazenamento.id = id;
            $("#modalDeleteArmazenamento").modal("show");
        },

        async deleteArmazenamento() {
            this.carregando = true;

            $("#modalDeleteArmazenamento").modal("hide");

            try {
                let id = this.modalDeleteArmazenamento.id;
                await api.delete(`/${this.baseUrl}/armazenamento/${id}`);
                this.dados.armazenamentos = this.dados.armazenamentos.filter((el) => el.idarm_val != id);
                await this.getCombo();
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },

        async postarTamanho() {
            this.carregando = true;
            $("#tamanhoCard").collapse("hide");

            try {
                let response;
                let idcontrato = this.dados[this.idname].value;
                let idtamanho_val = this.idtamanho_val;
                response = await api.post(`/${this.baseUrl}/tamanho`, { idcontrato, idtamanho_val });
                let dados = response.data;
                let retorno = dados.result;

                this.dados.tamanhos.unshift(retorno);

                this.setMessage(dados.successMessage, "success");
                this.errors = {};
                this.idtamanho_val = "";
                await this.getCombo();
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },

        abrirModalDeleteTamanho(id) {
            this.modalDeleteTamanho.id = id;
            $("#modalDeleteTamanho").modal("show");
        },

        async deleteTamanho() {
            this.carregando = true;

            $("#modalDeleteTamanho").modal("hide");

            try {
                let id = this.modalDeleteTamanho.id;
                await api.delete(`/${this.baseUrl}/tamanho/${id}`);
                this.dados.tamanhos = this.dados.tamanhos.filter((el) => el.idtamanho_val != id);
                await this.getCombo();
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
    },

    watch: {
        "dados.documento.value": {
            handler: function(newVal, oldVal) {
                // if (oldVal == undefined || newVal.length > oldVal.length) {
                //   this.dados.documento.value = maskCNPJCPF(newVal, true);
                // }
            },
        },
    },
};
</script>

<style></style>
