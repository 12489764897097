<template>
    <div>
        <input v-if="!carregando" type="checkbox" :checked="_aprov" @change="handleAprovadoChange" />
        <div v-else class="spinner-grow text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<script>
import api from '../services/api';
import { ClientErrorException, DEFAULT_ERROR_MESSAGE, PermissaoException, ServerErrorException, TokenInvalidoException } from '../services/exceptions';
import ViewMixin from "../views/ViewMixin.vue";

export default {
    name: "AprovacaoCobranca",
    props: ["id", "aprov"],
    mixins: [ViewMixin],
    data: () => {
        return {
            carregando: false,
            _aprov: false
        };
    },
    created() {
        this._aprov = this.aprov == '1'
    },
    methods: {
        async handleAprovadoChange(e) {
            this.carregando = true;
            const checked = e.target.checked;
            this._aprov = checked;

            try {
                await api.put(`/cobrancas/aprovar/${this.id}`);
            } catch (e) {
                this._aprov = !checked;
                if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    alert(e.message);
                } else {
                    alert(DEFAULT_ERROR_MESSAGE);
                }
            }

            this.carregando = false;
        },
    },
};
</script>
