<template>
  <div :class="`form-group col-md-${column}`">
    <label class="text-black" :for="name">{{ label }}</label>
    <input      
      :class="'form-control ' + (errorMessage ? 'is-invalid' : '')"
      :id="name"
      :name="name"  
      type="text"
      maxlength="10"
      v-bind="$attrs"
      @input="$emit('input', dateFormated)"
      @keydown.enter="enterKey"
      v-model="date"
      v-mask="'##/##/####'"
    />
    <div v-if="errorMessage" class="invalid-feedback d-block">
      {{ errorMessage[0] }}
    </div>
  </div>
</template>

<script>
import { enterKey } from "../utils";
export default {
  name: "DateAndLabel",
  props: {
    name: String,
    label: String,
    column: {},    
    errorMessage: Array, 
    enterKey: {
      type: Function,
      default: function (e) {
        enterKey(e);
      },
    },
  },

  data: function () {
    return {
      date: ''
    }
  },

  computed: {
    dateFormated: function () {
      return this.date.replace(
        /(\d{2})\/(\d{2})\/(\d{4})/,
        function name(m, p1, p2, p3) {
          // "$3-$2-$1"
          return `${p3}-${p2}-${p1}`.substring(0, 10)
        }
      );
    }
  },

  methods: {
    receiveData() {
      if (this.$attrs.value && this.$attrs.value.length > 0) {
        this.date = this.$attrs.value.replace(
          /(\d{4})-(\d{2})-(\d{2})/,
          function name(m, p1, p2, p3) {
            // "$3-$2-$1"
            return `${p3}/${p2}/${p1}`.substring(0, 10)
          }
        );
      } else {
        this.date = ''
      }
    },
  },

  mounted() {
    this.receiveData();
  },

  watch: {
    date: {
      handler: function(newVal, oldVal) {
        if (newVal.length > oldVal.length) {
          newVal = newVal.replace(/\D/g, "");
          newVal = newVal.substring(0, 8);
          newVal = newVal.replace(/^(\d{2})(\d{0,2})/g, "$1/$2");
          newVal = newVal.replace(/(\d{2})(\d{0,4})$/g, "$1/$2");

          newVal = newVal.substring(0, 10);

          this.date = newVal;
        }
      },
    },
    '$attrs.value': {
      handler: function(newVal, oldVal) {
        this.receiveData();
      },
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media only screen and (min-width: 768px) {
}
</style>
