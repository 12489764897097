<template>
  <div class="d-flex row justify-content-between mt-2">
    <div
      class="d-flex col-md-6 justify-content-center justify-content-md-start"
    >
      <button
        :disabled="$parent.pular === 0 || perPage == 'all'"
        type="button"
        class="btn btn-outline-primary mx-1"
        @click="primeiro()"
        id="btn_primeiro"
      >
        Primeiro
      </button>

      <button
        :disabled="$parent.pular === 0 || perPage == 'all'"
        type="button"
        class="btn btn-outline-primary mx-1"
        @click="anterior()"
        id="btn_anterior"
      >
        &lt;
      </button>

      <button
        :disabled="parseInt($parent.pular) + parseInt($parent.perPage) >= $parent.totalRows || perPage == 'all'"
        type="button"
        class="btn btn-outline-primary mx-1"
        @click="proximo()"
        id="btn_proximo"
      >
        &gt;
      </button>

      <button
        :disabled="parseInt($parent.pular) + parseInt($parent.perPage) >= $parent.totalRows || perPage == 'all'"
        type="button"
        class="btn btn-outline-primary mx-1"
        @click="ultimo()"
        id="btn_ultimo"
      >
        Último
      </button>
    </div>
    <div
      class="d-flex col-md-6 ml-2 ml-md-0 form-row justify-content-center justify-content-md-end mt-2 mt-md-0"
    >
      <div class="mx-1 mt-1 col-12 col-md-auto p-0 text-center">Mostrando</div>
      <div class="mx-1 col-12 col-md-auto p-0 text-center">
        <select
          class="custom-select perPage"
          id="perPage"
          v-model="perPage"
          @change="handlePerPage"
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="all">Todos</option>
        </select>
      </div>
      <div class="mx-1 mt-md-1 col-12 col-md-auto p-0 text-center">
        registros por página
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableFooter",
  props: [],
  data(){
    return{
      perPage:'all'
    }
  },
  updated() {
    this.perPage = this.$parent.perPage;
  },
  methods: {
    primeiro() {
      this.$parent.pular = 0;
      this.$parent.dados = [];

      this.$parent.list();
    },

    anterior() {
      let pular = this.$parent.pular;
      let perPage = this.$parent.perPage;
      pular -= parseInt(perPage);
      if (pular < 0) {
        pular = 0;
      }

      this.$parent.pular = pular;
      this.$parent.dados = [];

      this.$parent.list();
    },

    proximo() {
      let pular = this.$parent.pular;
      let perPage = this.$parent.perPage;
      pular += parseInt(perPage);
  
      this.$parent.pular = pular;
      this.$parent.dados = [];

      this.$parent.list();
    },

    ultimo() {
      let pular = this.$parent.pular;
      let perPage = this.$parent.perPage;
      let totalRows = this.$parent.totalRows;
      pular = Math.floor(parseInt(totalRows) / parseInt(perPage)) *
        parseInt(perPage);

      this.$parent.pular = pular;
      this.$parent.dados = [];

      this.$parent.list();
    },

    handlePerPage() {
      this.$parent.perPage = this.perPage;
      this.$parent.dados = [];
      this.$parent.list();
    }
  },
};
</script>
