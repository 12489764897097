<template>
  <th :class="classe">
    <button
      type="button"
      @click="sort"
      class="btn btn-link pl-0"
      :id="db">      
      <i v-if="$parent.sort == db && $parent.sortDirection == 'asc'" class="fas fa-sort-up"/>
      <i v-if="$parent.sort == db && $parent.sortDirection == 'desc'" class="fas fa-sort-down"/>      
      {{label}}
    </button>
  </th>
</template>

<script>
export default {  
  name: 'TableHeader', 
  props: ['db','label','classe'], 
  methods:{
    sort(e){            
      if (this.$parent.sort === e.target.id) {
          this.$parent.sortDirection === 'asc' ? this.$parent.sortDirection = 'desc' : this.$parent.sortDirection = 'asc';
      } else {
          this.$parent.sort = e.target.id;
          this.$parent.sortDirection = 'desc';
      }
      
      this.$parent.list();
    }
    }  
}
</script>
