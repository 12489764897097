var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Topbar"),
      _c(
        "div",
        {
          staticClass: "card shadow mx-3 conteudo text-left",
          staticStyle: { "margin-top": "90px" },
        },
        [
          _c("div", { staticClass: "card-header py-3" }, [
            _c(
              "h6",
              { staticClass: "m-0 font-weight-bold text-primary-dark" },
              [
                _c("i", { class: _vm.title.icon + " mr-2" }),
                _vm._v(_vm._s(_vm.title.text) + " "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.carregando,
                        expression: "carregando",
                      },
                    ],
                    staticClass: "spinner-grow",
                    attrs: { role: "status" },
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Loading..."),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _vm.isDataLoaded()
                  ? _c(
                      "fieldset",
                      { attrs: { disabled: _vm.carregando } },
                      [
                        _c("Quadro", {
                          attrs: {
                            type: _vm.messageType,
                            message: _vm.message,
                          },
                        }),
                        _c("form", [
                          _c(
                            "div",
                            { staticClass: "d-print-none" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "btn btn-secondary mr-1",
                                  attrs: {
                                    to: "/" + this.baseUrl,
                                    id: "btn_voltar",
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-arrow-left" }),
                                  _vm._v(" Voltar"),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary mr-1",
                                  attrs: { type: "button", id: "btnSave" },
                                  on: { click: _vm.salvar },
                                },
                                [_vm._v(" Salvar ")]
                              ),
                              _c("hr"),
                            ],
                            1
                          ),
                          _c(
                            "fieldset",
                            _vm._l(this.dados, function (v) {
                              return _c(
                                "div",
                                { key: v.campo, staticClass: "form-row" },
                                [
                                  _c("InputAndLabel", {
                                    attrs: {
                                      column: "6",
                                      name: v.campo,
                                      label: v.label,
                                      type: "text",
                                    },
                                    model: {
                                      value: v.valor,
                                      callback: function ($$v) {
                                        _vm.$set(v, "valor", $$v)
                                      },
                                      expression: "v.valor",
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]
      ),
      _c("ForbiddenModal"),
      _c("LogoutModal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }