var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "navbar navbar-expand-lg navbar-light bg-white fixed-top mb-4 shadow",
    },
    [
      _vm._m(0),
      _c("router-link", { attrs: { to: "/home" } }, [
        _c("img", {
          staticClass: "d-inline-block align-top",
          staticStyle: { "margin-top": "5px", "margin-bottom": "5px" },
          attrs: { src: require("../assets/logo.svg"), height: "40" },
        }),
      ]),
      _vm._m(1),
      _c(
        "div",
        {
          staticClass: "collapse navbar-collapse ",
          attrs: { id: "navbarSupportedContent" },
        },
        [
          _c("ul", { staticClass: "navbar-nav ml-auto" }, [
            _c("div", { staticClass: "dropdown-divider border-gray" }),
            _c("li", { staticClass: "nav-item dropdown d-lg-inline" }, [
              _vm._m(2),
              _c(
                "div",
                {
                  staticClass: "dropdown-menu shadow animated--grow-in",
                  attrs: { "aria-labelledby": "dropdown01" },
                },
                [
                  _c(
                    "div",
                    { staticClass: " py-2 dropdown-inner rounded" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/resumos" },
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-clipboard-check mr-2",
                          }),
                          _vm._v("Resumos "),
                        ]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/cobrancas" },
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-money-check-alt mr-2",
                          }),
                          _vm._v("Cobranças "),
                        ]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/contratos" },
                        },
                        [
                          _c("i", { staticClass: "fas fa-file-contract mr-2" }),
                          _vm._v("Contratos "),
                        ]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/clientescob" },
                        },
                        [
                          _c("i", { staticClass: "fas fa-users mr-2" }),
                          _vm._v("Clientes Cobrança "),
                        ]
                      ),
                      _c("div", { staticClass: "dropdown-divider" }),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/tabcob" },
                        },
                        [
                          _c("i", { staticClass: "fas fa-cash-register mr-1" }),
                          _vm._v("Tabela de Cobrança "),
                        ]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/ocorrencias" },
                        },
                        [
                          _c("i", { staticClass: "fas fa-tags mr-1" }),
                          _vm._v("Ocorrências "),
                        ]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/insumos" },
                        },
                        [
                          _c("i", { staticClass: "fas fa-pallet mr-1" }),
                          _vm._v("Insumos "),
                        ]
                      ),
                      _c("div", { staticClass: "dropdown-divider" }),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/perfis" },
                        },
                        [
                          _c("i", { staticClass: "fas fa-id-card mr-2" }),
                          _vm._v("Perfil de Acesso "),
                        ]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/usuarios" },
                        },
                        [
                          _c("i", { staticClass: "fas fa-users mr-2" }),
                          _vm._v("Usuários "),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "dropdown-divider border-gray" }),
            _c(
              "li",
              { staticClass: "nav-item dropdown d-lg-inline no-arrow" },
              [
                _c(
                  "a",
                  {
                    staticClass: "nav-link dropdown-toggle ",
                    attrs: {
                      role: "button",
                      href: "#",
                      id: "userDropdown",
                      "data-toggle": "dropdown",
                      "aria-haspopup": "true",
                      "aria-expanded": "false",
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-fw fa-user" }),
                    _c("span", { staticClass: "d-lg-inline" }, [
                      _vm._v(" " + _vm._s(_vm.username)),
                    ]),
                  ]
                ),
                _vm._m(3),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-link d-md-none d-none rounded-circle mr-3",
        attrs: { id: "sidebarToggleTop" },
      },
      [_c("i", { staticClass: "fa fa-bars" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-link d-lg-none",
        attrs: {
          "data-toggle": "collapse",
          "data-target": "#navbarSupportedContent",
          "aria-controls": "navbarSupportedContent",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation",
        },
      },
      [_c("i", { staticClass: " fa fa-bars" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "nav-link dropdown-toggle",
        attrs: {
          href: "#",
          id: "dropdown01",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [
        _c("i", { staticClass: "fa fa-fw fa-bars mr-1" }),
        _c("span", { staticClass: "d-lg-inline" }, [_vm._v("Menu")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "dropdown-menu dropdown-menu-right shadow animated--grow-in",
        attrs: { "aria-labelledby": "userDropdown" },
      },
      [
        _c("div", { staticClass: "dropdown-divider border-gray" }),
        _c(
          "a",
          {
            staticClass: "dropdown-item",
            attrs: {
              href: "#",
              "data-toggle": "modal",
              "data-target": "#logoutModal",
            },
          },
          [
            _c("i", { staticClass: "fas fa-sign-out-alt fa-sm fa-fw mr-2" }),
            _vm._v(" Sair "),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }