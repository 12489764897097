<template>
  <div :class="`form-group col-md-${column}`">
    <label class="text-black" :for="name">{{ label }}</label>
    <select
      v-if="!input"
      :class="'form-control ' + (errorMessage ? 'is-invalid' : '')"
      :id="name"
      :name="name"      
      v-bind="$attrs"
      @input="$emit('input', $event.target.value)"
      @keydown.enter="enterKey"
    >
      <option v-if="comEscolhaVazia"></option>
      <option
        v-for="option in options"
        :key="name + '-' + option.id"
        :value="option.id"
      >
        {{ option.name }}
      </option>
    </select>
    <select-3 v-else :options="optionsForSelect3" :search="search" v-bind="$attrs" v-model="val"></select-3>
    <div v-if="errorMessage" class="invalid-feedback d-block">
      {{ errorMessage[0] }}
    </div>
  </div>
</template>

<script>
import { enterKey } from "../utils";
import Select3 from './Select3.vue';
export default {
  components: {
    Select3
  },
  name: "SelectAndLabel",
  data: function () {
    return {
      search: '',
      val: ''
    }
  },
  props: {
    name: String,
    label: String,
    input: {
      type: Boolean,
      default: false
    },
    column: {},    
    options: {},
    semEscolhaVazia: {
      type: Boolean,
      default: false
    },
    errorMessage: Array,    
    enterKey: {
      type: Function,
      default: function (e) {
        enterKey(e);
      },
    },
  },
  computed: {
    comEscolhaVazia: function(){
      return !this.semEscolhaVazia;
    },
    optionsForSelect3: function () {
      if (this.options) {
        return this.options.map((v) => ({'label': v.name, 'value': v.id}));
      } else {
        return [];
      }
    }
  },
  beforeMount() {
    this.val = this.$attrs.value;
  },
  watch: {
    val: function (newVal, oldVal) {
      this.$emit('input', newVal);
    },
    '$attrs.value': function (newVal, oldVal) {
      if (newVal == '') {
        this.val = '';
      }      
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media only screen and (min-width: 768px) {
}
</style>
