var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade show",
      attrs: {
        id: _vm.id,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "exampleModalLabel",
      },
    },
    [
      _c(
        "div",
        { class: "modal-dialog " + _vm.modalSize, attrs: { role: "document" } },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c(
              "div",
              { staticClass: "modal-header bg-secondary text-primary" },
              [
                _c(
                  "h5",
                  {
                    staticClass: "modal-title text-white",
                    attrs: { id: "exampleModalLabel" },
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.title) + " "),
                    _c("Carregando", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.carregando,
                          expression: "carregando",
                        },
                      ],
                    }),
                  ],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button", "aria-label": "Close" },
                    on: { click: _vm.completeCancelFunction },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "text-white",
                        attrs: { "aria-hidden": "true" },
                      },
                      [_vm._v("×")]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "modal-body",
                staticStyle: { "overflow-x": "hidden" },
              },
              [_vm._t("default")],
              2
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showFooter,
                    expression: "showFooter",
                  },
                ],
                staticClass: "modal-footer",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button" },
                    on: { click: _vm.completeCancelFunction },
                  },
                  [_vm._v("Cancelar")]
                ),
                !_vm.buttonList
                  ? _c(
                      "button",
                      {
                        class: _vm.confirmClass || "btn btn-primary",
                        on: { click: _vm.confirmFunction },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.confirmText || "Confirmar") + " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._l(_vm.buttonList, function (button, index) {
                  return _c(
                    "button",
                    {
                      key: _vm.id + "-button-" + index,
                      class: button.confirmClass || "btn btn-primary",
                      on: { click: button.confirmFunction },
                    },
                    [_vm._v(" " + _vm._s(button.confirmText) + " ")]
                  )
                }),
              ],
              2
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }