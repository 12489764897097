<template>
  <div
    v-show="message == null || message != ''"
    id="mensagem"
    :class="`alert alert-${type}`"
  >
    <span v-for="(token, index) in generateTokens()" :key="'quadro-texto-'+index">
      <span v-if="token.match(/(\[.*\]\(.*\))/g)">
        <router-link
          :to="token.match(/\[(.*)\]\((.*)\)/)[2]"
          @click.native="$router.go()"
        >
          {{ token.match(/\[(.*)\]\((.*)\)/)[1] }}
        </router-link>
      </span>
      <span v-else>
        {{ token }}
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Quadro',
  props: ['type', 'message'],
  methods: {
    generateTokens() {
      let tokens = this.message?.split(/(\[.*\]\(.*\))/g)
      return tokens
    }
  }
}
</script>

<style>

</style>