<template>
  <div :class="`form-group col-md-${column}`">
    <label class="text-black" :for="name">{{ label }}</label>
    <textarea      
      :class="'form-control ' + (errorMessage ? 'is-invalid' : '')"
      :id="name"
      :name="name"      
      v-bind="$attrs"
      :rows="rows"
      @input="$emit('input', $event.target.value)"      
    />
    <div v-if="errorMessage" class="invalid-feedback d-block">
      {{ errorMessage[0] }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TextareaAndLabel",
  props: {
    name: String,
    label: String,
    column: {
      default: 12
    },    
    rows: {
      default: 3
    },
    errorMessage: Array,    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media only screen and (min-width: 768px) {
}
</style>
