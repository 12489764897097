var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-view" }, [
    _c("div", { staticClass: "card bg-light" }, [
      _vm._m(0),
      _c("div", { staticClass: "container-formulario" }, [
        _c("div", { staticClass: "formulario" }, [
          _c("fieldset", { attrs: { disabled: _vm.carregando } }, [
            _c("h3", [_vm._v("Identificação de Usuário")]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errorMessage,
                    expression: "errorMessage",
                  },
                ],
                staticClass: "alert alert-danger",
                attrs: { role: "alert" },
              },
              [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
            ),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.login,
                    expression: "data.login",
                  },
                ],
                class:
                  "form-control text-center " +
                  (_vm.errors.login ? "is-invalid" : ""),
                attrs: {
                  type: "text",
                  id: "login",
                  name: "login",
                  placeholder: "Login",
                },
                domProps: { value: _vm.data.login },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return function (e) {
                      return _vm.enterKey(e)
                    }.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.data, "login", $event.target.value)
                  },
                },
              }),
              _vm.errors.login
                ? _c("div", { staticClass: "invalid-feedback d-block" }, [
                    _vm._v(" " + _vm._s(_vm.errors.login[0]) + " "),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.senha,
                    expression: "data.senha",
                  },
                ],
                class:
                  "form-control text-center " +
                  (_vm.errors.senha ? "is-invalid" : ""),
                attrs: {
                  type: "password",
                  id: "password",
                  name: "password",
                  placeholder: "Senha",
                },
                domProps: { value: _vm.data.senha },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return function (e) {
                      return _vm.enterKey(e)
                    }.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.data, "senha", $event.target.value)
                  },
                },
              }),
              _vm.errors.senha
                ? _c("div", { staticClass: "invalid-feedback d-block" }, [
                    _vm._v(" " + _vm._s(_vm.errors.senha[0]) + " "),
                  ])
                : _vm._e(),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-block",
                attrs: { type: "button" },
                on: { click: _vm.handleLogin },
              },
              [
                _vm._v(" Entrar "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.carregando,
                        expression: "carregando",
                      },
                    ],
                    staticClass: "spinner-grow",
                    attrs: { role: "status" },
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Loading..."),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo w-50" }, [
      _c("img", {
        attrs: {
          src: require("../assets/logo.svg"),
          alt: "logo",
          width: "108",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }