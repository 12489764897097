<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <fieldset v-if="isDataLoaded()" :disabled="carregando">
                    <Quadro :type="messageType" :message="message" />
                    <form class="text-left">
                        <div class="d-print-none">
                            <a
                                id="btn_voltar"
                                class="btn btn-secondary mr-1"
                                data-toggle="modal"
                                data-target="#modalMov"
                            >
                                <i class="fa fa-arrow-left"></i> Voltar</a
                            >
                            <button type="button" id="btnSave" class="btn btn-primary mr-1" @click="salvar">
                                Salvar
                            </button>
                            <!-- <button
                                type="button"
                                id="btn_del"
                                class="btn btn-danger mr-1"
                                :disabled="operation === 'insert'"
                                data-toggle="modal"
                                data-target="#modalExcluir"
                            >
                                Excluir
                            </button> -->
                            <hr />
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <fieldset>
                                    <div class="form-row">
                                        <InputAndLabel
                                            column="3"
                                            name="id"
                                            label="ID"
                                            type="text"
                                            v-model="dados[idname].value"
                                            readonly
                                        />
                                        <SelectAndLabel
                                            column="4"
                                            name="tipo"
                                            label="Tipo"
                                            :options="combos.tipo"
                                            v-model="dados.tipo.value"
                                            :errorMessage="errors.tipo"
                                        />
                                        <SelectAndLabel
                                            column="5"
                                            name="nome"
                                            label="Nome"
                                            :options="combos.nome"
                                            v-model="dados.nome.value"
                                            :errorMessage="errors.nome"
                                        />
                                    </div>
                                    <div class="form-row">
                                        <InputAndLabel
                                            column="4"
                                            name="custo"
                                            label="Custo"
                                            type="number"
                                            v-model="dados.custo.value"
                                            :errorMessage="errors.custo"
                                            right
                                        />
                                        <InputAndLabel
                                            column="4"
                                            name="qtdeval"
                                            label="Qtde Valor"
                                            type="number"
                                            v-model="dados.qtdeval.value"
                                            :errorMessage="errors.qtdeval"
                                            right
                                        />
                                        <InputAndLabel
                                            column="4"
                                            name="total"
                                            label="Total"
                                            type="number"
                                            v-model="dados.total.value"
                                            :errorMessage="errors.total"
                                            right
                                            readonly
                                        />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </form>
                </fieldset>
            </div>
        </div>
    </div>
</template>

<script>
import api, { getUsername } from "../services/api";
import Topbar from "@/components/Topbar";
import InputAndLabel from "@/components/InputAndLabel";
import SelectAndLabel from "@/components/SelectAndLabel";
import DateAndLabel from "@/components/DateAndLabel";
import Quadro from "@/components/Quadro";
import LogoutModal from "@/components/LogoutModal.vue";
import Modal from "@/components/Modal";
import ViewMixin from "./ViewMixin";
import $ from "jquery";
import {
    ClientErrorException,
    DEFAULT_ERROR_MESSAGE,
    PermissaoException,
    ServerErrorException,
    TokenInvalidoException,
    ValidationException,
} from "../services/exceptions";
import ForbiddenModal from "../components/ForbiddenModal.vue";
import CheckboxAndLabel from "../components/CheckboxAndLabel.vue";
import TextareaAndLabel from "../components/TextareaAndLabel.vue";
import { maskCNPJCPF, wait } from "../utils";

export default {
    name: "CobrancaMov",
    mixins: [ViewMixin],
    components: {
        Topbar,
        LogoutModal,
        Modal,
        InputAndLabel,
        SelectAndLabel,
        Quadro,
        ForbiddenModal,
        CheckboxAndLabel,
        DateAndLabel,
        TextareaAndLabel,
    },
    props: ["id", "idparent", "parentBaseUrl", "parentGetData", 'parentSetMovSelecionada'],
    data: function() {
        return {
            idname: "idcobranca_mov",
            baseUrl: "mov",
            title: {
                icon: "fas fa-exchange-alt",
                text: "Movimento",
            },
            combos: {
                idcliente: [],
            },
            // dados: {
            //   documento: ''
            // },
            mensagem: "",
        };
    },
    mounted() {
        this.defineOperation(this.id);
        this.getCombo().then(this.getData);
    },
    methods: {
        async getCombo() {
            this.carregando = true;

            try {
                let response = await api.get(`/${this.parentBaseUrl}/${this.idparent}/${this.baseUrl}/combosToSave`);
                let data = response.data;
                this.combos = data;
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
        async getData(id = this.id) {
            this.carregando = true;

            try {
                let response = await api.get(`/${this.parentBaseUrl}/${this.idparent}/${this.baseUrl}/${id}`);
                let data = response.data;
                this.setData(data, ["movs"]);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
        async salvar() {
            this.carregando = true;

            try {
                let response;
                let id = this.dados[this.idname].value;
                let newData;
                let fieldsToDelete = ["movs"];
                if (this.operation === "update") {
                    newData = this.getDataToUpdate(fieldsToDelete);
                    newData['idcobranca'] = this.idparent
                    response = await api.put(`/${this.parentBaseUrl}/${this.idparent}/${this.baseUrl}/${id}`, newData);
                } else {
                    newData = this.getDataToInsert(fieldsToDelete);
                    newData['idcobranca'] = this.idparent
                    response = await api.post(`/${this.parentBaseUrl}/${this.idparent}/${this.baseUrl}`, newData);
                }
                let data = response.data;
                id = data.id;
                this.dados[this.idname] = { value: id, oldValue: id };
                this.operation = "update";

                this.updateData(newData);

                this.parentGetData(this.idparent);
                this.parentSetMovSelecionada(id);

                setTimeout(() => {
                    this.setMessage(data.successMessage, "success");
                    this.errors = {};
                }, 100);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
                console.error(e);
            }

            this.carregando = false;
        },

        async calcularTotal(sleep = true) {
            if (sleep) {
                await wait(100);
            }
            this.dados.total.value =
                (parseFloat(this.dados.custo.value) || 0) *
                (parseFloat(this.dados.qtdeval.value) || 0);
        },
    },

    watch: {
        "id": {
            handler: function(newVal, oldVal) {
                this.defineOperation(this.id);
                this.getData();
                this.setMessage('')
            },
        },
        "dados.custo.value": {
            handler: function (newVal, oldVal) {
                if (oldVal != undefined) {
                    this.calcularTotal();
                }
            },
        },
        "dados.qtdeval.value": {
            handler: function (newVal, oldVal) {
                if (oldVal != undefined) {
                    this.calcularTotal();
                }
            },
        },
    },
};
</script>

<style></style>
