<template>
  <div>
    <Topbar />
    <div class="card shadow mx-3 conteudo text-left" style="margin-top: 90px">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary-dark">
          <i :class="`${title.icon} mr-2`"></i>{{ title.text }}
          <div v-show="carregando" class="spinner-grow" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </h6>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12">
            <fieldset
              v-if="isDataLoaded()"
              :disabled="carregando"
            >
              <Quadro :type="messageType" :message="message" />              
              <form>
                <div class="d-print-none">
                  <router-link
                    :to="`/${this.baseUrl}`"
                    id="btn_voltar"
                    class="btn btn-secondary mr-1"
                  >
                    <i class="fa fa-arrow-left"></i> Voltar</router-link
                  >
                  <button
                    type="button"
                    id="btnSave"
                    class="btn btn-primary mr-1"
                    @click="(operation === 'insert' ? configurarPermissoes() : salvar())"
                  >
                    Salvar
                  </button>
                  <button
                    type="button"
                    id="btn_del"
                    class="btn btn-danger mr-1"
                    :disabled="operation === 'insert'"
                    data-toggle="modal"
                    data-target="#modalExcluir"
                  >
                    Excluir
                  </button>
                  <hr />
                </div>
                <fieldset>
                  <div class="form-row">

                    <InputAndLabel
                      column="1"
                      name="id"
                      label="ID"
                      type="text"
                      v-model="dados[idname].value"
                      readonly
                    />

                    <InputAndLabel
                      column="3"
                      name="nome"
                      label="Nome"
                      type="text"
                      maxlength="30"
                      v-model="dados.nome.value"
                      :errorMessage="errors.nome"
                    />

                    <InputAndLabel
                      column="5"
                      name="descricao"
                      label="Descrição"
                      type="text"
                      maxlength="50"
                      v-model="dados.descricao.value"
                      :errorMessage="errors.descricao"
                    />
                    
                    <div class="form-group mt-md-4 pt-md-3 ml-md-0 ml-2">
                      <CheckboxAndLabel
                        name="inativo"
                        v-model="dados.inativo.value"
                        label="Inativo"
                        :errorMessage="errors.inativo"
                      />
                    </div>
                  </div>                  
                </fieldset>
                <hr />
                <button
                  v-if="operation === 'insert'"
                  type="button"
                  id="btnSave"
                  class="btn btn-primary mr-1"
                  @click="configurarPermissoes"
                >
                  Configurar Permissões
                </button>
                <v-jstree v-else :data="dados.permissoes" show-checkbox multiple allow-batch whole-row @item-click="itemClick">
                  <template scope="_">
                    <div style="display: inherit; width: 200px" @click.ctrl="customItemClickWithCtrl">
                      <!-- <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i> -->
                      {{_.model.text}}
                      <!-- <button style="border: 0px; background-color: transparent; cursor: pointer;" @click="customItemClick(_.vm, _.model, $event)"><i class="fa fa-remove"></i></button> -->
                    </div>
                  </template>
                </v-jstree>
              </form>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
    <Modal
      id="modalExcluir"
      title="Confirmar Exclusão"
      confirmClass="btn btn-danger"
      confirmText="Excluir"
      :confirmFunction="deletar"
    >
      Tem certeza que deseja excluir este perfil?
    </Modal>
    <ForbiddenModal />
    <LogoutModal />
  </div>
</template>

<script>
import api from "../services/api";
import Topbar from "@/components/Topbar";
import InputAndLabel from "@/components/InputAndLabel";
import SelectAndLabel from "@/components/SelectAndLabel";
import Quadro from "@/components/Quadro";
import LogoutModal from "@/components/LogoutModal.vue";
import Modal from "@/components/Modal";
import ViewMixin from "./ViewMixin";
import $ from "jquery";
import {
    ClientErrorException,
  DEFAULT_ERROR_MESSAGE,
  PermissaoException,
  ServerErrorException,
  TokenInvalidoException,
  ValidationException,
} from "../services/exceptions";
import ForbiddenModal from '../components/ForbiddenModal.vue';
import CheckboxAndLabel from '../components/CheckboxAndLabel.vue';
import VJstree from 'vue-jstree'

export default {
  name: "Perfil",
  mixins: [ViewMixin],
  components: {
    Topbar,
    LogoutModal,
    Modal,
    InputAndLabel,
    SelectAndLabel,
    Quadro,
    ForbiddenModal,
    CheckboxAndLabel,
    VJstree
  },
  props: ["id"],
  data: function () {
    return {
      idname: 'idperfil',
      baseUrl: "perfis",
      title: {
        icon: "fas fa-id-card",
        text: "Perfil de Acesso"
      },
    }
  },
  mounted() {
    this.defineOperation(this.id);
    this.getData();
  },
  methods: {    
    async getData(id = this.id) {
      this.carregando = true;

      try {
        let response = await api.get(`/${this.baseUrl}/${id}`);
        let data = response.data;
        this.setData(data, ['permissoes']);
      } catch (e) {
        if (e instanceof ValidationException) {
          this.handleValidationException(e);
        } else if (e instanceof PermissaoException) {
          this.handlePermissaoException();
        } else if (e instanceof TokenInvalidoException) {
          this.handleTokenInvalidoException();
        } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
          this.setMessage(e.message, "danger");
        } else {
          this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
        }        
      }

      this.carregando = false;
    },

    async configurarPermissoes() {
      await this.salvar();
      await this.getData(this.dados[this.idname].value);
    },

    async salvar() {
      this.carregando = true;

      try {
        let response;
        let id = this.dados[this.idname].value;
        let newData;
        let fieldsToDelete = [];
        if (this.operation === 'update') {
          newData = this.getDataToUpdate(fieldsToDelete);
          response = await api.put(
            `/${this.baseUrl}/${id}`,
            newData
          );
        } else {
          newData = this.getDataToInsert(fieldsToDelete)
          response = await api.post(`/${this.baseUrl}`, newData);
        }
        let data = response.data;
        id = data.id;
        this.dados[this.idname] = { value: id, oldValue: id };
        this.operation = 'update';

        this.updateData(newData);

        this.changeURL(`/${this.baseUrl}/${id}`);

        this.setMessage(data.successMessage, "success");        
        this.errors = {};
      } catch (e) {
        if (e instanceof ValidationException) {
          this.handleValidationException(e);
        } else if (e instanceof PermissaoException) {
          this.handlePermissaoException();
        } else if (e instanceof TokenInvalidoException) {
          this.handleTokenInvalidoException();
        } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
          this.setMessage(e.message, "danger");
        } else {
          this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
        }
      }

      this.carregando = false;
    },
    async deletar() {
      this.carregando = true;

      $("#modalExcluir").modal("hide");

      try {
        let id = this.dados[this.idname].value;
        await api.delete(`/${this.baseUrl}/${id}`);

        this.$router.push(`/${this.baseUrl}`);
      } catch (e) {
        if (e instanceof ValidationException) {
          this.handleValidationException(e);
        } else if (e instanceof PermissaoException) {
          this.handlePermissaoException();
        } else if (e instanceof TokenInvalidoException) {
          this.handleTokenInvalidoException();
        } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
          this.setMessage(e.message, "danger");
        } else {
          this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
        }
      }

      this.carregando = false;
    },

    itemClick (node) {
      this.updatePermissao({...node.model.value, selecionado: node.model.selected});
    },

    async updatePermissao(item) {
      this.carregando = true;

      try {
        let id = this.dados[this.idname].value;
        await api.put(`/${this.baseUrl}/updatePermissao/${id}`, item);
      } catch (e) {
        if (e instanceof ValidationException) {
          this.handleValidationException(e);
        } else if (e instanceof PermissaoException) {
          this.handlePermissaoException();
        } else if (e instanceof TokenInvalidoException) {
          this.handleTokenInvalidoException();
        } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
          this.setMessage(e.message, "danger");
        } else {
          this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
        }
      }

      this.carregando = false;
    },
  },
};
</script>

<style>
</style>