var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("Topbar"),
      _c(
        "div",
        {
          staticClass: "container-fluid text-left",
          staticStyle: { "padding-top": "80px" },
        },
        [
          _c(
            "div",
            {
              staticClass: "card shadow mb-4",
              staticStyle: { height: "84vh" },
            },
            [
              _c("div", { staticClass: "card-header bg-secondary py-3" }, [
                _c("h6", { staticClass: "m-0 font-weight-bold text-white" }, [
                  _c("i", { class: _vm.title.icon + " mr-2" }),
                  _vm._v(_vm._s(_vm.title.text) + " "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.carregando,
                          expression: "carregando",
                        },
                      ],
                      staticClass: "spinner-grow",
                      attrs: { role: "status" },
                    },
                    [
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Loading..."),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c("fieldset", { attrs: { disabled: _vm.carregando } }, [
                _c(
                  "div",
                  { staticClass: "card-body bg-light text-primary-dark" },
                  [
                    _c(
                      "p",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "btn btn-link",
                            attrs: { to: "/" + _vm.baseUrl + "/insert" },
                          },
                          [
                            _c("i", { staticClass: "fa fa-fw fa-plus" }),
                            _vm._v(" Adicionar "),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary mr-1",
                            attrs: { type: "button" },
                            on: { click: _vm.exportar },
                          },
                          [
                            _c("i", { staticClass: "fas fa-file-csv" }),
                            _vm._v(" Exportar Fechamento "),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary mr-1",
                            attrs: { type: "button" },
                            on: { click: _vm.abrirModalBaixarFechamento },
                          },
                          [
                            _c("i", { staticClass: "fas fa-file-download" }),
                            _vm._v(" Exportar All Clientes "),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "collapse show",
                        attrs: { id: "filtrocard" },
                      },
                      [
                        _c("div", { staticClass: "card card-body bg-light" }, [
                          _c(
                            "div",
                            { staticClass: "form-row align-items-center" },
                            [
                              _c("InputAndLabel", {
                                attrs: {
                                  column: "3",
                                  name: "pesquisa",
                                  label: "Pesquisa",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.filtro.pesquisa,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filtro, "pesquisa", $$v)
                                  },
                                  expression: "filtro.pesquisa",
                                },
                              }),
                              _c("input-and-label", {
                                attrs: {
                                  column: "2",
                                  name: "dataini",
                                  label: "Data Início",
                                  type: "date",
                                },
                                model: {
                                  value: _vm.filtro.dataini,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filtro, "dataini", $$v)
                                  },
                                  expression: "filtro.dataini",
                                },
                              }),
                              _c("input-and-label", {
                                attrs: {
                                  column: "2",
                                  name: "datafim",
                                  label: "Data Fim",
                                  type: "date",
                                },
                                model: {
                                  value: _vm.filtro.datafim,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filtro, "datafim", $$v)
                                  },
                                  expression: "filtro.datafim",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "form-group col-md-auto pt-md-2",
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-primary form-control mt-md-4",
                                      attrs: {
                                        type: "submit",
                                        id: "pesquisar",
                                      },
                                      on: { click: _vm.list },
                                    },
                                    [_vm._v(" Pesquisar ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "collapse",
                        attrs: { id: "filtrocardImportacao" },
                      },
                      [
                        _c("div", { staticClass: "card card-body bg-light" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "custom-file form-group col-md-5 ml-2 mt-2",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.arquivoNome,
                                      expression: "arquivoNome",
                                    },
                                  ],
                                  attrs: {
                                    type: "hidden",
                                    name: "arquivo",
                                    id: "arquivo",
                                  },
                                  domProps: { value: _vm.arquivoNome },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.arquivoNome = $event.target.value
                                    },
                                  },
                                }),
                                _c("input", {
                                  staticClass: "custom-file-input",
                                  attrs: {
                                    type: "file",
                                    name: "files[]",
                                    id: "userfile",
                                    multiple: "",
                                  },
                                  on: { change: _vm.handleEscolhaArquivo },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "custom-file-label",
                                    attrs: {
                                      for: "userfile",
                                      "data-browse": "Buscar",
                                      id: "etiqueta",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.arquivoLabel))]
                                ),
                              ]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success ml-2 mt-2",
                                attrs: {
                                  type: "submit",
                                  id: "btn_save",
                                  name: "btn_save",
                                  value: "UPLOAD",
                                  disabled: _vm.arquivos == null,
                                },
                                on: { click: _vm.importarPlanilha },
                              },
                              [_vm._v(" Importar ")]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c("hr"),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-lg-12 tableFixHead" }, [
                        _c(
                          "table",
                          {
                            staticClass:
                              "table table-sm table-striped table-bordered",
                          },
                          [
                            _c("thead", [
                              _c(
                                "tr",
                                { staticStyle: { "white-space": "nowrap" } },
                                [
                                  _c("th"),
                                  _c("TableHeader", {
                                    attrs: { db: _vm.idname, label: "ID" },
                                  }),
                                  _vm._m(0),
                                  _c("TableHeader", {
                                    attrs: { db: "data", label: "Data" },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      db: "contrato",
                                      label: "Contrato",
                                    },
                                  }),
                                  _c("TableHeader", {
                                    attrs: { db: "tabcob", label: "Tabela" },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      classe: "text-right",
                                      db: "gmv",
                                      label: "Valor GMV",
                                    },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      classe: "text-right",
                                      db: "takerate",
                                      label: "Take Rate %",
                                    },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      classe: "text-right",
                                      db: "ticketmedio",
                                      label: "Valor Ticket Médio",
                                    },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      classe: "text-right",
                                      db: "itensrecebidos",
                                      label: "Qtde Itens Recebidos",
                                    },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      classe: "text-right",
                                      db: "totalitensestoque",
                                      label: "Qtde Total Itens Estoque",
                                    },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      classe: "text-right",
                                      db: "valortotalestoque",
                                      label: "Valor Total Estoque",
                                    },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      classe: "text-right",
                                      db: "valorreversa",
                                      label: "Valor Reversa",
                                    },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      classe: "text-right",
                                      db: "qtdereversa",
                                      label: "Qtde Reversa",
                                    },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      classe: "text-right",
                                      db: "valoritensclass",
                                      label: "Valor Itens Class.",
                                    },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      classe: "text-right",
                                      db: "qtdeitensclass",
                                      label: "Qtde Itens Class.",
                                    },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      classe: "text-right",
                                      db: "totalpicking",
                                      label: "Qtde Total Picking",
                                    },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      classe: "text-right",
                                      db: "totalpacking",
                                      label: "Qtde Total Packing",
                                    },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      classe: "text-right",
                                      db: "faturamento",
                                      label: "Faturamento",
                                    },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      classe: "text-right",
                                      db: "insumos",
                                      label: "Insumos",
                                    },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      classe: "text-right",
                                      db: "totalreversa",
                                      label: "Total Reversa",
                                    },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      classe: "text-right",
                                      db: "totalitensclass",
                                      label: "Total Itens Classificados",
                                    },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      classe: "text-right",
                                      db: "excedente",
                                      label: "Excedente",
                                    },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      classe: "text-right",
                                      db: "movarm",
                                      label: "Movimento / Armazenagem",
                                    },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      classe: "text-right",
                                      db: "valoradicional",
                                      label: "Valor Adicional",
                                    },
                                  }),
                                  _c("TableHeader", {
                                    attrs: {
                                      classe: "text-right",
                                      db: "total",
                                      label: "Total",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("tr", { staticClass: "table-success" }, [
                                _c("td"),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-right",
                                    attrs: { colspan: "4" },
                                  },
                                  [_vm._v("Totais")]
                                ),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.decimalFormat(_vm.totais.gmv || 0)
                                    )
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.decimalFormat(
                                        _vm.totais.takerate || 0
                                      )
                                    )
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.decimalFormat(
                                        _vm.totais.ticketmedio || 0
                                      )
                                    )
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.intFormat(
                                        _vm.totais.itensrecebidos || 0
                                      )
                                    )
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.intFormat(
                                        _vm.totais.totalitensestoque || 0
                                      )
                                    )
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.decimalFormat(
                                          _vm.totais.valortotalestoque || 0
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.intFormat(
                                        _vm.totais.valorreversa || 0
                                      )
                                    )
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.intFormat(_vm.totais.qtdereversa || 0)
                                    )
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.intFormat(
                                        _vm.totais.valoritensclass || 0
                                      )
                                    )
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.intFormat(
                                        _vm.totais.qtdeitensclass || 0
                                      )
                                    )
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.intFormat(
                                        _vm.totais.totalpicking || 0
                                      )
                                    )
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.intFormat(
                                        _vm.totais.totalpacking || 0
                                      )
                                    )
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.decimalFormat(
                                        _vm.totais.faturamento || 0
                                      )
                                    )
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.decimalFormat(_vm.totais.insumos || 0)
                                    )
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.decimalFormat(
                                        _vm.totais.totalreversa || 0
                                      )
                                    )
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.decimalFormat(
                                        _vm.totais.totalitensclass || 0
                                      )
                                    )
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.decimalFormat(
                                        _vm.totais.excedente || 0
                                      )
                                    )
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.decimalFormat(_vm.totais.movarm || 0)
                                    )
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.decimalFormat(
                                        _vm.totais.valoradicional || 0
                                      )
                                    )
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.decimalFormat(_vm.totais.total || 0)
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.dados, function (line) {
                                return _c(
                                  "tr",
                                  {
                                    key: line[_vm.idname],
                                    style: {
                                      backgroundColor:
                                        "#" + line.statusbackground,
                                      color: "#" + line.statuscolor,
                                    },
                                  },
                                  [
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to:
                                                "/" +
                                                _vm.baseUrl +
                                                "/" +
                                                line[_vm.idname],
                                              "data-toggle": "tooltip",
                                              title: "Editar/Visualizar",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-edit",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("td", [
                                      _vm._v(_vm._s(line[_vm.idname])),
                                    ]),
                                    _c(
                                      "td",
                                      { staticClass: "text-center" },
                                      [
                                        _c("AprovacaoCobranca", {
                                          attrs: {
                                            id: line[_vm.idname],
                                            aprov: line.aprov,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("td", [
                                      _vm._v(_vm._s(_vm.dateFormat(line.data))),
                                    ]),
                                    _c("td", [_vm._v(_vm._s(line.contrato))]),
                                    _c("td", [_vm._v(_vm._s(line.tabcob))]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(_vm.decimalFormat(line.gmv || 0))
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.decimalFormat(line.takerate || 0)
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.decimalFormat(
                                            line.ticketmedio || 0
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.intFormat(
                                            line.itensrecebidos || 0
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.intFormat(
                                            line.totalitensestoque || 0
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.decimalFormat(
                                            line.valortotalestoque || 0
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.intFormat(line.valorreversa || 0)
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.intFormat(line.qtdereversa || 0)
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.intFormat(
                                            line.valoritensclass || 0
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.intFormat(
                                            line.qtdeitensclass || 0
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.intFormat(line.totalpicking || 0)
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.intFormat(line.totalpacking || 0)
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.decimalFormat(
                                            line.faturamento || 0
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.decimalFormat(line.insumos || 0)
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.decimalFormat(
                                            line.totalreversa || 0
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.decimalFormat(
                                            line.totalitensclass || 0
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.decimalFormat(line.excedente || 0)
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.decimalFormat(line.movarm || 0)
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.decimalFormat(
                                            line.valoradicional || 0
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.decimalFormat(line.total || 0)
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c("TableFooter"),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "Modal",
        {
          attrs: {
            id: "modalBaixarFechamento",
            title: "Baixar Fechamento",
            confirmClass: "btn btn-primary",
            confirmFunction: _vm.baixarFechamento,
            small: "",
          },
        },
        [
          _c("div", { staticClass: "row text-left" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c("fieldset", [
                _c(
                  "div",
                  { staticClass: "form-row" },
                  [
                    _c("InputAndLabel", {
                      attrs: {
                        column: "12",
                        name: "dataBaixarFechamento",
                        label: "Data",
                        type: "date",
                      },
                      model: {
                        value: _vm.dataBaixarFechamento,
                        callback: function ($$v) {
                          _vm.dataBaixarFechamento = $$v
                        },
                        expression: "dataBaixarFechamento",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c("ForbiddenModal"),
      _c("LogoutModal"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "text-center" }, [
      _c("span", { staticClass: "btn btn-link" }, [_vm._v("Aprovado")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }