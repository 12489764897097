var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "form-group col-md-" +
        _vm.column +
        " " +
        (_vm.right == false ? "" : "text-right"),
    },
    [
      _c("label", { class: "text-black", attrs: { for: _vm.name } }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _c(
        "input",
        _vm._b(
          {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: _vm.vMask,
                expression: "vMask",
              },
            ],
            class:
              "form-control " +
              (_vm.right == false ? "" : "text-right") +
              (_vm.errorMessage ? "is-invalid" : ""),
            style: {
              color: _vm.color,
              background: _vm.background,
            },
            attrs: {
              id: _vm.name,
              name: _vm.name,
              tabindex: _vm.tabindex,
              type: _vm.inputType,
            },
            domProps: { value: _vm.formattedValue },
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.enterKey.apply(null, arguments)
              },
              focus: _vm.onFocus,
              blur: _vm.onBlur,
              input: _vm.onInput,
            },
          },
          "input",
          _vm.$attrs,
          false
        )
      ),
      _vm.errorMessage
        ? _c("div", { staticClass: "invalid-feedback d-block" }, [
            _vm._v(" " + _vm._s(_vm.errorMessage[0]) + " "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }