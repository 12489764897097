<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <fieldset v-if="isDataLoaded()" :disabled="carregando">
                    <Quadro :type="messageType" :message="message" />
                    <form class="text-left">
                        <div class="d-print-none">
                            <a
                                id="btn_voltar"
                                class="btn btn-secondary mr-1"
                                data-toggle="modal"
                                data-target="#modalOcorrencia"
                            >
                                <i class="fa fa-arrow-left"></i> Voltar</a
                            >
                            <button type="button" id="btnSave" class="btn btn-primary mr-1" @click="salvar">
                                Salvar
                            </button>
                            <!-- <button
                                type="button"
                                id="btn_del"
                                class="btn btn-danger mr-1"
                                :disabled="operation === 'insert'"
                                data-toggle="modal"
                                data-target="#modalExcluir"
                            >
                                Excluir
                            </button> -->
                            <hr />
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <fieldset>
                                    <div class="form-row">
                                        <InputAndLabel
                                            column="2"
                                            name="id"
                                            label="ID"
                                            type="text"
                                            v-model="dados[idname].value"
                                            readonly
                                        />
                                        <SelectAndLabel
                                            column="5"
                                            name="idocorrencia"
                                            label="Ocorrencia"
                                            :options="combos.ocorrencia"
                                            v-model="dados.idocorrencia.value"
                                            :errorMessage="errors.idocorrencia"
                                        />
                                        <InputAndLabel
                                            column="5"
                                            name="motivo"
                                            label="Motivo"
                                            type="text"
                                            v-model="dados.motivo.value"
                                            :errorMessage="errors.motivo"
                                        />
                                    </div>
                                    <div class="form-row">
                                        <InputAndLabel
                                            column="4"
                                            name="qtde"
                                            label="Qtde."
                                            type="number"
                                            v-model="dados.qtde.value"
                                            :errorMessage="errors.qtde"
                                            right
                                        />
                                        <InputAndLabel
                                            column="4"
                                            name="valor"
                                            label="Valor"
                                            type="number"
                                            v-model="dados.valor.value"
                                            :errorMessage="errors.valor"
                                            right
                                        />
                                        <InputAndLabel
                                            column="4"
                                            name="data"
                                            label="Data"
                                            type="date"
                                            v-model="dados.data.value"
                                            :errorMessage="errors.data"
                                        />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </form>
                </fieldset>
            </div>
        </div>
    </div>
</template>

<script>
import api, { getUsername } from "../services/api";
import Topbar from "@/components/Topbar";
import InputAndLabel from "@/components/InputAndLabel";
import SelectAndLabel from "@/components/SelectAndLabel";
import DateAndLabel from "@/components/DateAndLabel";
import Quadro from "@/components/Quadro";
import LogoutModal from "@/components/LogoutModal.vue";
import Modal from "@/components/Modal";
import ViewMixin from "./ViewMixin";
import $ from "jquery";
import {
    ClientErrorException,
    DEFAULT_ERROR_MESSAGE,
    PermissaoException,
    ServerErrorException,
    TokenInvalidoException,
    ValidationException,
} from "../services/exceptions";
import ForbiddenModal from "../components/ForbiddenModal.vue";
import CheckboxAndLabel from "../components/CheckboxAndLabel.vue";
import TextareaAndLabel from "../components/TextareaAndLabel.vue";
import { maskCNPJCPF } from "../utils";

export default {
    name: "CobrancaOcorrencia",
    mixins: [ViewMixin],
    components: {
        Topbar,
        LogoutModal,
        Modal,
        InputAndLabel,
        SelectAndLabel,
        Quadro,
        ForbiddenModal,
        CheckboxAndLabel,
        DateAndLabel,
        TextareaAndLabel,
    },
    props: ["id", "idparent", "parentBaseUrl", "parentGetData", 'parentsetOcorrenciaSelecionado'],
    data: function() {
        return {
            idname: "idcobranca_ocorrencias",
            baseUrl: "ocorrencia",
            title: {
                icon: "fas fa-pallet",
                text: "Ocorrencia",
            },
            combos: {
                idcliente: [],
            },
            // dados: {
            //   documento: ''
            // },
            mensagem: "",
        };
    },
    mounted() {
        this.defineOperation(this.id);
        this.getCombo().then(this.getData);
    },
    methods: {
        async getCombo() {
            this.carregando = true;

            try {
                let response = await api.get(`/${this.parentBaseUrl}/${this.idparent}/${this.baseUrl}/combosToSave`);
                let data = response.data;
                this.combos = data;
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
        async getData(id = this.id) {
            this.carregando = true;

            try {
                let response = await api.get(`/${this.parentBaseUrl}/${this.idparent}/${this.baseUrl}/${id}`);
                let data = response.data;
                this.setData(data, ["movs"]);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
        async salvar() {
            this.carregando = true;

            try {
                let response;
                let id = this.dados[this.idname].value;
                let newData;
                let fieldsToDelete = ["movs"];
                if (this.operation === "update") {
                    newData = this.getDataToUpdate(fieldsToDelete);
                    newData['idcobranca'] = this.idparent
                    response = await api.put(`/${this.parentBaseUrl}/${this.idparent}/${this.baseUrl}/${id}`, newData);
                } else {
                    newData = this.getDataToInsert(fieldsToDelete);
                    newData['idcobranca'] = this.idparent
                    response = await api.post(`/${this.parentBaseUrl}/${this.idparent}/${this.baseUrl}`, newData);
                }
                let data = response.data;
                id = data.id;
                this.dados[this.idname] = { value: id, oldValue: id };
                this.operation = "update";

                this.updateData(newData);

                this.parentGetData(this.idparent);
                this.parentsetOcorrenciaSelecionado(id);

                setTimeout(() => {
                    this.setMessage(data.successMessage, "success");
                    this.errors = {};
                }, 100);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
                console.error(e);
            }

            this.carregando = false;
        },
    },

    watch: {
        "id": {
            handler: function(newVal, oldVal) {
                this.defineOperation(this.id);
                this.getData();
                this.setMessage('')
            },
        },
    },
};
</script>

<style></style>
