<template>
    <div>
        <Topbar />
        <div class="card shadow mx-3 conteudo text-left" style="margin-top: 90px">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary-dark">
                    <i :class="`${title.icon} mr-2`"></i>{{ title.text }}
                    <div v-show="carregando" class="spinner-grow" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </h6>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-12">
                        <fieldset v-if="isDataLoaded()" :disabled="carregando">
                            <Quadro :type="messageType" :message="message" />
                            <form>
                                <div class="d-print-none">
                                    <router-link
                                        :to="`/${this.baseUrl}`"
                                        id="btn_voltar"
                                        class="btn btn-secondary mr-1"
                                    >
                                        <i class="fa fa-arrow-left"></i> Voltar</router-link
                                    >
                                    <button type="button" id="btnSave" class="btn btn-primary mr-1" @click="salvar">
                                        Salvar
                                    </button>
                                    <!-- <button
                                        type="button"
                                        id="btn_del"
                                        class="btn btn-danger mr-1"
                                        :disabled="operation === 'insert'"
                                        data-toggle="modal"
                                        data-target="#modalExcluir"
                                    >
                                        Excluir
                                    </button> -->
                                    <hr />
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <fieldset>
                                            <div class="form-row">
                                                <InputAndLabel
                                                    column="1"
                                                    name="id"
                                                    label="ID"
                                                    type="text"
                                                    v-model="dados[idname].value"
                                                    readonly
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="vigencia_ini"
                                                    label="Vigência Início"
                                                    type="text"
                                                    v-mask="'##/##/####'"
                                                    v-model="dados.vigencia_ini.value"
                                                    :errorMessage="errors.vigencia_ini"
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="vigencia_fim"
                                                    label="Vigência Fim"
                                                    type="text"
                                                    v-mask="'##/##/####'"
                                                    v-model="dados.vigencia_fim.value"
                                                    :errorMessage="errors.vigencia_fim"
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="base"
                                                    label="Base"
                                                    type="number"
                                                    step="0.01"
                                                    v-model="dados.base.value"
                                                    :errorMessage="errors.base"
                                                    right
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="valor"
                                                    label="Valor"
                                                    type="number"
                                                    step="0.01"
                                                    v-model="dados.valor.value"
                                                    :errorMessage="errors.valor"
                                                    right
                                                />
                                            </div>
                                            <div class="form-row">
                                                <InputAndLabel
                                                    column="5"
                                                    name="contrato"
                                                    label="Contrato"
                                                    type="text"
                                                    v-model="dados.contrato"
                                                    readonly
                                                />                                                
                                                <InputAndLabel
                                                    column="2"
                                                    name="faixaini_ped"
                                                    label="Faixa Início Pedido"
                                                    type="number"
                                                    step="0.01"
                                                    v-model="dados.faixaini_ped.value"
                                                    :errorMessage="errors.faixaini_ped"
                                                    right
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="faixafim_ped"
                                                    label="Faixa Fim Pedido"
                                                    type="number"
                                                    step="0.01"
                                                    v-model="dados.faixafim_ped.value"
                                                    :errorMessage="errors.faixafim_ped"
                                                    right
                                                />
                                            </div>
                                            <div class="form-row">
                                                <SelectAndLabel
                                                    column="3"
                                                    name="idmov"
                                                    label="Movimentação"
                                                    :options="combos.idmov"
                                                    v-model="dados.idmov.value"
                                                    :errorMessage="errors.idmov"
                                                />
                                                <SelectAndLabel
                                                    column="3"
                                                    name="idtipomov"
                                                    label="Tipo Movimento"
                                                    :options="combos.idtipomov"
                                                    v-model="dados.idtipomov.value"
                                                    :errorMessage="errors.idtipomov"
                                                />
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>                                
                            </form>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            id="modalExcluir"
            title="Confirmar Exclusão"
            confirmClass="btn btn-danger"
            confirmText="Excluir"
            :confirmFunction="deletar"
        >
            Tem certeza que deseja excluir este movimento?
        </Modal>
        <ForbiddenModal />
        <LogoutModal />
    </div>
</template>

<script>
import api, { getUsername } from "../services/api";
import Topbar from "@/components/Topbar";
import InputAndLabel from "@/components/InputAndLabel";
import SelectAndLabel from "@/components/SelectAndLabel";
import DateAndLabel from "@/components/DateAndLabel";
import Quadro from "@/components/Quadro";
import LogoutModal from "@/components/LogoutModal.vue";
import Modal from "@/components/Modal";
import ViewMixin from "./ViewMixin";
import $ from "jquery";
import {
    ClientErrorException,
    DEFAULT_ERROR_MESSAGE,
    PermissaoException,
    ServerErrorException,
    TokenInvalidoException,
    ValidationException,
} from "../services/exceptions";
import ForbiddenModal from "../components/ForbiddenModal.vue";
import CheckboxAndLabel from "../components/CheckboxAndLabel.vue";
import TextareaAndLabel from "../components/TextareaAndLabel.vue";
import { maskCNPJCPF } from "../utils";

export default {
    name: "Movimento",
    mixins: [ViewMixin],
    components: {
        Topbar,
        LogoutModal,
        Modal,
        InputAndLabel,
        SelectAndLabel,
        Quadro,
        ForbiddenModal,
        CheckboxAndLabel,
        DateAndLabel,
        TextareaAndLabel,
    },
    props: ["id"],
    data: function() {
        return {
            idname: "idtipomov_val",
            baseUrl: "movimentos",
            title: {
                icon: "fas fa-exchange-alt",
                text: "Movimento",
            },
            combos: {
                idcliente: [],
            },
            // dados: {
            //   documento: ''
            // },
            mensagem: "",
        };
    },
    mounted() {
        this.defineOperation(this.id);
        this.getCombo().then(this.getData);
    },
    methods: {
        async getCombo() {
            this.carregando = true;

            try {
                let response = await api.get(`/${this.baseUrl}/combosToSave`);
                let data = response.data;
                this.combos = data;
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
        async getData(id = this.id) {
            this.carregando = true;

            try {
                let response = await api.get(`/${this.baseUrl}/${id}`);
                let data = response.data;
                this.setData(data, ["idstatus", "status", "statuscolor", "statusbackground", "contrato"]);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
        async salvar() {
            this.carregando = true;

            try {
                let response;
                let id = this.dados[this.idname].value;
                let newData;
                let fieldsToDelete = ["idstatus", "status", "statuscolor", "statusbackground", "contrato"];
                if (this.operation === "update") {
                    newData = this.getDataToUpdate(fieldsToDelete);
                    response = await api.put(`/${this.baseUrl}/${id}`, newData);
                } else {
                    newData = this.getDataToInsert(fieldsToDelete);
                    response = await api.post(`/${this.baseUrl}`, newData);
                }
                let data = response.data;
                id = data.id;
                this.dados[this.idname] = { value: id, oldValue: id };
                this.operation = "update";

                this.updateData(newData);

                this.changeURL(`/${this.baseUrl}/${id}`);

                this.setMessage(data.successMessage, "success");
                this.errors = {};
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
        async deletar() {
            this.carregando = true;

            $("#modalExcluir").modal("hide");

            try {
                let id = this.dados[this.idname].value;
                await api.delete(`/${this.baseUrl}/${id}`);

                this.$router.push(`/${this.baseUrl}`);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },

        async postarMensagem() {
            this.carregando = true;
            $("#mensagemCard").collapse("hide");

            try {
                let response;
                let idlead = this.dados[this.idname].value;
                let mensagem = this.mensagem;
                response = await api.post(`/${this.baseUrl}/mensagem`, { idlead, mensagem });
                let dados = response.data;
                let { idatividade, data } = dados.result;

                this.dados.atividades.unshift({ idatividade, data, mensagem, usuario: getUsername() });

                this.setMessage(dados.successMessage, "success");
                this.errors = {};
                this.mensagem = "";
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
    },

    watch: {
        "dados.documento.value": {
            handler: function(newVal, oldVal) {
                // if (oldVal == undefined || newVal.length > oldVal.length) {
                //   this.dados.documento.value = maskCNPJCPF(newVal, true);
                // }
            },
        },
    },
};
</script>

<style></style>
