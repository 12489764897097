import { render, staticRenderFns } from "./Cobrancas.vue?vue&type=template&id=cdd69cfc&scoped=true&"
import script from "./Cobrancas.vue?vue&type=script&lang=js&"
export * from "./Cobrancas.vue?vue&type=script&lang=js&"
import style0 from "./Cobrancas.vue?vue&type=style&index=0&id=cdd69cfc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdd69cfc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cdd69cfc')) {
      api.createRecord('cdd69cfc', component.options)
    } else {
      api.reload('cdd69cfc', component.options)
    }
    module.hot.accept("./Cobrancas.vue?vue&type=template&id=cdd69cfc&scoped=true&", function () {
      api.rerender('cdd69cfc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Cobrancas.vue"
export default component.exports