<template>
  <div :class="`form-group col-md-${column} ${right == false ? '' : 'text-right'}`">
    <label :class='`text-black`' :for="name">{{ label }}</label>
    <input      
      :class="`form-control ${right == false ? '' : 'text-right'}` + (errorMessage ? 'is-invalid' : '')"
      :id="name"
      :name="name"
      v-bind="$attrs"
      :style="{
        color,
        background
      }"
      @keydown.enter="enterKey"
      :tabindex="tabindex"

      :value="formattedValue"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
      :type="inputType"
      v-mask="vMask"
    />
    <div v-if="errorMessage" class="invalid-feedback d-block">
      {{ errorMessage[0] }}
    </div>
  </div>
</template>

<script>
import { decimalFormat, enterKey, intFormat, moneyFormat } from "../utils";
export default {
  name: "InputAndLabel",
  props: {
    tabindex: null,
    color: null,
    background: null,
    type: {
      type: String,
      default: 'text',
    },
    name: String,
    label: String,
    right: Boolean,
    column: {},    
    errorMessage: Array, 
    enterKey: {
      type: Function,
      default: function (e) {
        enterKey(e);
      },
    },
    value: {
      type: [String, Number],
      default: 0,
    },
    'v-mask': {
      type: [String],
    },
    format: {
      type: [String],
      default: 'decimal',
    },
  },

  mounted() {
    this.modelValue = this.value;
  },
  
  data: function () {
    return {
      modelValue: 0,
      inputType: 'text',
    }
  },

  updated() {
    if (this.type == 'date') {
      this.inputType = this.type
    }
  },

  computed: {
    formattedValue() {
      if (this.type == 'number' && this.inputType === "text") {
        if (this.modelValue) {
          switch (this.format) {
            case 'decimal':
              return decimalFormat(this.modelValue);

            case 'integer':
              return intFormat(this.modelValue)
            
            case 'money':
              return moneyFormat(this.modelValue)
          
            default:
              return this.modelValue;
          }
        } else {
          return 0
        }
      } else {
        return this.modelValue;
      }
    },
  },

  methods: {
    onFocus() {
      if (this.type === "number") {
        this.inputType = "number";
      }
    },
    onBlur() {
      if (this.type === "number") {
        this.inputType = "text";
      }
    },
    onInput(e) {
      this.modelValue = e.target.value
      this.$emit('input', e.target.value);
    },
  },

  watch: {
    value(newValue) {
      if (!this.vMask) {
        this.modelValue = newValue;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media only screen and (min-width: 768px) {
}
</style>
