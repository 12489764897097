var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Topbar"),
      _c(
        "div",
        {
          staticClass: "card shadow mx-3 conteudo text-left",
          staticStyle: { "margin-top": "90px" },
        },
        [
          _c("div", { staticClass: "card-header py-3" }, [
            _c(
              "h6",
              { staticClass: "m-0 font-weight-bold text-primary-dark" },
              [
                _c("i", { class: _vm.title.icon + " mr-2" }),
                _vm._v(_vm._s(_vm.title.text) + " "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.carregando,
                        expression: "carregando",
                      },
                    ],
                    staticClass: "spinner-grow",
                    attrs: { role: "status" },
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Loading..."),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _vm.isDataLoaded()
                  ? _c(
                      "fieldset",
                      { attrs: { disabled: _vm.carregando } },
                      [
                        _c("Quadro", {
                          attrs: {
                            type: _vm.messageType,
                            message: _vm.message,
                          },
                        }),
                        _c("form", [
                          _c(
                            "div",
                            { staticClass: "d-print-none" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "btn btn-secondary mr-1",
                                  attrs: {
                                    to: "/" + this.baseUrl,
                                    id: "btn_voltar",
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-arrow-left" }),
                                  _vm._v(" Voltar"),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary mr-1",
                                  attrs: { type: "button", id: "btnSave" },
                                  on: { click: _vm.salvar },
                                },
                                [_vm._v(" Salvar ")]
                              ),
                              _vm.operation == "update"
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary mr-1",
                                      attrs: {
                                        type: "button",
                                        id: "btnDuplicate",
                                      },
                                      on: { click: _vm.duplicar },
                                    },
                                    [
                                      _c("i", { staticClass: "fas fa-copy" }),
                                      _vm._v(" Duplicar "),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger mr-1",
                                  attrs: {
                                    type: "button",
                                    id: "btn_del",
                                    disabled: _vm.operation === "insert",
                                    "data-toggle": "modal",
                                    "data-target": "#modalExcluir",
                                  },
                                },
                                [_vm._v(" Excluir ")]
                              ),
                              _c("hr"),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-lg-12" }, [
                              _c("fieldset", [
                                _c(
                                  "div",
                                  { staticClass: "form-row" },
                                  [
                                    _c("InputAndLabel", {
                                      attrs: {
                                        column: "1",
                                        name: "id",
                                        label: "ID",
                                        type: "text",
                                        readonly: "",
                                      },
                                      model: {
                                        value: _vm.dados[_vm.idname].value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dados[_vm.idname],
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "dados[idname].value",
                                      },
                                    }),
                                    _c("InputAndLabel", {
                                      attrs: {
                                        column: "7",
                                        name: "nome",
                                        label: "Nome",
                                        type: "text",
                                        errorMessage: _vm.errors.nome,
                                      },
                                      model: {
                                        value: _vm.dados.nome.value,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.dados.nome, "value", $$v)
                                        },
                                        expression: "dados.nome.value",
                                      },
                                    }),
                                    _c("InputAndLabel", {
                                      attrs: {
                                        column: "2",
                                        name: "vigencia_ini",
                                        label: "Vigência Início",
                                        type: "date",
                                        errorMessage: _vm.errors.vigencia_ini,
                                      },
                                      model: {
                                        value: _vm.dados.vigencia_ini.value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dados.vigencia_ini,
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "dados.vigencia_ini.value",
                                      },
                                    }),
                                    _c("InputAndLabel", {
                                      attrs: {
                                        column: "2",
                                        name: "vigencia_fim",
                                        label: "Vigência Fim",
                                        type: "date",
                                        errorMessage: _vm.errors.vigencia_fim,
                                      },
                                      model: {
                                        value: _vm.dados.vigencia_fim.value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dados.vigencia_fim,
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "dados.vigencia_fim.value",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("hr"),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-lg-12" }, [
                              _vm.operation == "update"
                                ? _c("div", { staticClass: "card shadow" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "card-header bg-secondary py-3",
                                      },
                                      [
                                        _c(
                                          "h6",
                                          {
                                            staticClass:
                                              "m-0 font-weight-bold text-white row",
                                          },
                                          [
                                            _vm._m(0),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.carregando,
                                                    expression: "carregando",
                                                  },
                                                ],
                                                staticClass: "spinner-grow",
                                                attrs: { role: "status" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "sr-only" },
                                                  [_vm._v("Loading...")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "p-0 card-body" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-lg-12 table-responsive",
                                          },
                                          [
                                            _c(
                                              "table",
                                              {
                                                staticClass:
                                                  "table table-sm table-striped table-bordered my-3",
                                              },
                                              [
                                                _c("thead", [
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticStyle: {
                                                        "white-space": "nowrap",
                                                      },
                                                    },
                                                    [
                                                      _c("th", [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-primary",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click:
                                                                function () {
                                                                  return _vm.abrirModalFaixa(
                                                                    "insert"
                                                                  )
                                                                },
                                                            },
                                                          },
                                                          [_vm._v(" Lançar ")]
                                                        ),
                                                      ]),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                        },
                                                        [_vm._v("Percentual")]
                                                      ),
                                                      _c("th", [
                                                        _vm._v("Ticket Médio"),
                                                      ]),
                                                      _c("th", [
                                                        _vm._v("Faturamento"),
                                                      ]),
                                                    ]
                                                  ),
                                                ]),
                                                _c(
                                                  "tbody",
                                                  _vm._l(
                                                    _vm.dados.faixas,
                                                    function (line) {
                                                      return _c(
                                                        "tr",
                                                        {
                                                          key:
                                                            "faixa-" +
                                                            line.idtabcob_faixas,
                                                        },
                                                        [
                                                          _c("td", [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "text-primary",
                                                                attrs: {
                                                                  "data-toggle":
                                                                    "tooltip",
                                                                  title:
                                                                    "Editar/Visualizar",
                                                                  role: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function () {
                                                                      return _vm.abrirModalFaixa(
                                                                        line.idtabcob_faixas
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-edit",
                                                                }),
                                                              ]
                                                            ),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "text-danger",
                                                                attrs: {
                                                                  "data-toggle":
                                                                    "tooltip",
                                                                  title:
                                                                    "Excluir",
                                                                  role: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function () {
                                                                      return _vm.abrirModalDeleteFaixa(
                                                                        line.idtabcob_faixas
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fas fa-trash-alt",
                                                                }),
                                                              ]
                                                            ),
                                                          ]),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "text-right",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  line.perc +
                                                                    "%"
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                (line.faixa_ini_tm !==
                                                                null
                                                                  ? _vm.decimalFormat(
                                                                      line.faixa_ini_tm
                                                                    )
                                                                  : "") +
                                                                  " - " +
                                                                  (line.faixa_fim_tm !==
                                                                  null
                                                                    ? _vm.decimalFormat(
                                                                        line.faixa_fim_tm
                                                                      )
                                                                    : "")
                                                              )
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                (line.faixa_ini_fat !==
                                                                null
                                                                  ? _vm.decimalFormat(
                                                                      line.faixa_ini_fat
                                                                    )
                                                                  : "") +
                                                                  " - " +
                                                                  (line.faixa_fim_fat !==
                                                                  null
                                                                    ? _vm.decimalFormat(
                                                                        line.faixa_fim_fat
                                                                      )
                                                                    : "")
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]
      ),
      _vm.operation == "update"
        ? _c(
            "Modal",
            {
              attrs: {
                id: "modalFaixa",
                title: "Faixa",
                confirmClass: "btn btn-danger d-none",
                confirmFunction: function () {
                  return null
                },
                noFooter: "",
              },
            },
            [
              _vm.isDataLoaded()
                ? _c("TabCobFaixa", {
                    attrs: {
                      id: _vm.faixaSelecionada,
                      idparent: _vm.dados[_vm.idname].value,
                      parentBaseUrl: _vm.baseUrl,
                      parentGetData: _vm.getData,
                      parentSetFaixaSelecionada: _vm.setFaixaSelecionada,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "Modal",
        {
          attrs: {
            id: "modalExcluir",
            title: "Confirmar Exclusão",
            confirmClass: "btn btn-danger",
            confirmText: "Excluir",
            confirmFunction: _vm.deletar,
          },
        },
        [_vm._v(" Tem certeza que deseja excluir esta tabela de cobrança? ")]
      ),
      _c(
        "Modal",
        {
          attrs: {
            id: "modalDeleteFaixa",
            title: "Confirmar Exclusão",
            confirmClass: "btn btn-danger",
            confirmText: "Excluir",
            confirmFunction: _vm.deleteFaixa,
          },
        },
        [_vm._v(" Tem certeza que deseja excluir esta faixa? ")]
      ),
      _c("ForbiddenModal"),
      _c("LogoutModal"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "align-self-center" }, [
      _c("i", { staticClass: "fas fa-ruler-horizontal" }),
      _vm._v(" Faixas "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }