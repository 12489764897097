var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-check d-inline-block" }, [
    _c(
      "input",
      _vm._b(
        {
          class: "form-check-input " + (_vm.errorMessage ? "is-invalid" : ""),
          attrs: { type: "checkbox", id: _vm.name, name: _vm.name, value: "1" },
          domProps: { checked: _vm.checked },
          on: {
            change: function ($event) {
              return _vm.$emit("input", $event.target.checked)
            },
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.enterKey.apply(null, arguments)
            },
          },
        },
        "input",
        _vm.$attrs,
        false
      )
    ),
    _c("label", { staticClass: "form-check-label", attrs: { for: _vm.name } }, [
      _vm._v(" " + _vm._s(_vm.label) + " "),
    ]),
    _vm.errorMessage
      ? _c("div", { staticClass: "invalid-feedback d-block" }, [
          _vm._v(" " + _vm._s(_vm.errorMessage[0]) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }