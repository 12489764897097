import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import VueMask from 'v-mask'

import 'bootstrap/dist/js/bootstrap'
import './custom.scss'

Vue.config.productionTip = false

if (window.location.origin.split('://')[0] === 'http' && window.location.hostname !== "localhost") {
  var httpURL = window.location.hostname + window.location.pathname + window.location.search;
  var httpsURL = "https://" + httpURL;
  window.location = httpsURL;
}

Vue.use(VueMask);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
