<template>
  <div class="home-view">
    <Topbar />
    <div class="container-img" style="padding-top: 90px">
      <img src="../assets/logo.svg" alt="logo">
    </div>    
    <LogoutModal/>
  </div>
</template>

<script>
// @ is an alias to /src
import Topbar from '@/components/Topbar.vue'
import LogoutModal from '@/components/LogoutModal.vue'
import ViewMixin from './ViewMixin'
import { DEFAULT_ERROR_MESSAGE, ServerErrorException, TokenInvalidoException } from '../services/exceptions'
import api from '../services/api'

export default {
  name: 'Home',
  mixins: [
    ViewMixin
  ],
  components: {
    Topbar,
    LogoutModal
  },

  async mounted() {
    try {
      await this.validarToken();
    } catch (e) {
      if (e instanceof TokenInvalidoException) {
        this.handleTokenInvalidoException();
      } else if (e instanceof ServerErrorException) {
        alert(e.message);
      } else {
        alert(DEFAULT_ERROR_MESSAGE);
      }      
    }
  },

  methods: {
    async validarToken() {
      this.carregando = true;

      await api.get("/validar-token");

      this.carregando = false;
    },
  }
}
</script>

<style scoped lang="scss">    

  .home-view {
    height: 100%;
  }

  .container-img {
    height: 85%;

    display: flex;
    justify-content: center;
    align-self: center;
  }

  img {    
    filter: drop-shadow(0px 0px 100px rgba(0, 0, 0, 0.2));
    max-width: 80%;
  }

  @media only screen and (min-width: 768px) {
    img {    
      max-width: 40%;
    }
  }
</style>
