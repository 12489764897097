import { render, staticRenderFns } from "./Insumos.vue?vue&type=template&id=141f4dc0&scoped=true&"
import script from "./Insumos.vue?vue&type=script&lang=js&"
export * from "./Insumos.vue?vue&type=script&lang=js&"
import style0 from "./Insumos.vue?vue&type=style&index=0&id=141f4dc0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "141f4dc0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('141f4dc0')) {
      api.createRecord('141f4dc0', component.options)
    } else {
      api.reload('141f4dc0', component.options)
    }
    module.hot.accept("./Insumos.vue?vue&type=template&id=141f4dc0&scoped=true&", function () {
      api.rerender('141f4dc0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Insumos.vue"
export default component.exports