<template>
  <div>    
    <i v-if="inverso == null ? variavelBooleana : !variavelBooleana" class="fas fa-check text-success"></i>
    <i v-else class="fas fa-times text-danger"></i>
  </div>
</template>

<script>
export default {
  name: "CertoErrado",
  props: ["variavel", "inverso"],
  computed: {
    variavelBooleana: function() {
      let boll;
      switch (typeof this.variavel) {
        case "string":
          boll = parseInt(this.variavel) ? true : false;
          return boll;
          
        case "number": 
          boll = this.variavel ? true : false;
          return boll;

        case "boolean": 
          boll = this.variavel;
          return boll;
      
        default:
          return this.variavel;          
      }
    }
  }
};
</script>

<style>
</style>