import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAdminToken } from '../services/api'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Usuarios from '../views/Usuarios.vue'
import Usuario from '../views/Usuario.vue'
import Perfis from '../views/Perfis.vue'
import Perfil from '../views/Perfil.vue'
import Resumos from '../views/Resumos.vue'
import Resumo from '../views/Resumo.vue'
import ClientesCob from '../views/ClientesCob.vue'
import ClienteCob from '../views/ClienteCob.vue'
import Cobrancas from '../views/Cobrancas.vue'
import Cobranca from '../views/Cobranca.vue'
import Contratos from '../views/Contratos.vue'
import Contrato from '../views/Contrato.vue'
import Clientes from '../views/Clientes.vue'
import Cliente from '../views/Cliente.vue'
import TipoMovVals from '../views/TipoMovVals.vue'
import TipoMovVal from '../views/TipoMovVal.vue'
import ArmVals from '../views/ArmVals.vue'
import ArmVal from '../views/ArmVal.vue'
import TamanhoVals from '../views/TamanhoVals.vue'
import TamanhoVal from '../views/TamanhoVal.vue'
import TabCobs from '../views/TabCobs.vue'
import TabCob from '../views/TabCob.vue'
import Ocorrencias from '../views/Ocorrencias.vue'
import Ocorrencia from '../views/Ocorrencia.vue'
import Insumos from '../views/Insumos.vue'
import Insumo from '../views/Insumo.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/resumos',
    name: 'Resumos',
    component: Resumos    
  },  
  {
    path: '/resumos/:id',
    name: 'Resumo',
    component: Resumo,
    props: true
  },
  {
    path: '/clientescob',
    name: 'ClientesCob',
    component: ClientesCob    
  },  
  {
    path: '/clientescob/:id',
    name: 'ClienteCob',
    component: ClienteCob,
    props: true
  },
  {
    path: '/cobrancas',
    name: 'Cobrancas',
    component: Cobrancas    
  },  
  {
    path: '/cobrancas/:id',
    name: 'Cobranca',
    component: Cobranca,
    props: true
  },
  {
    path: '/contratos',
    name: 'Contratos',
    component: Contratos    
  },  
  {
    path: '/contratos/:id',
    name: 'Contrato',
    component: Contrato,
    props: true
  },
  {
    path: '/clientes',
    name: 'Clientes',
    component: Clientes
  },
  {
    path: '/clientes/:id',
    name: 'Cliente',
    component: Cliente,
    props: true
  },
  {
    path: '/movimentos',
    name: 'TipoMovVals',
    component: TipoMovVals
  },
  {
    path: '/movimentos/:id',
    name: 'TipoMovVal',
    component: TipoMovVal,
    props: true
  },
  {
    path: '/tabcob',
    name: 'TabCobs',
    component: TabCobs
  },
  {
    path: '/tabcob/:id',
    name: 'TabCob',
    component: TabCob,
    props: true
  },
  {
    path: '/ocorrencias',
    name: 'Ocorrencias',
    component: Ocorrencias
  },
  {
    path: '/ocorrencias/:id',
    name: 'Ocorrencia',
    component: Ocorrencia,
    props: true
  },
  {
    path: '/insumos',
    name: 'Insumos',
    component: Insumos
  },
  {
    path: '/insumos/:id',
    name: 'Insumo',
    component: Insumo,
    props: true
  },
  {
    path: '/armazenamentos',
    name: 'ArmVals',
    component: ArmVals
  },
  {
    path: '/armazenamentos/:id',
    name: 'ArmVal',
    component: ArmVal,
    props: true
  },
  {
    path: '/tamanhos',
    name: 'TamanhoVals',
    component: TamanhoVals
  },
  {
    path: '/tamanhos/:id',
    name: 'TamanhoVal',
    component: TamanhoVal,
    props: true
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: Usuarios    
  },
  {
    path: '/usuarios/:id',
    name: 'Usuario',
    component: Usuario,
    props: true
  },
  {
    path: '/perfis',
    name: 'Perfis',
    component: Perfis
  },
  {
    path: '/perfis/:id',
    name: 'Perfil',
    component: Perfil,
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  { path: '/', redirect: '/home' }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  let isAuthenticated = getAdminToken();
  document.querySelector('body').scrollTo(0, 0);

  if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
  else next()
})

export default router
