<template>
  <Modal
    id="forbiddenModal"
    title="Acesso Negado"
    confirmText="Ok"
    :confirmFunction="fechar"
  >
    Você não possui permissão para utilizar esse recurso.
  </Modal>
</template>

<script>
import Modal from "./Modal";
import $ from "jquery";

export default {
  name: "ForbiddenModal",
  components: {
    Modal,
  },
  mounted() {
    $('#forbiddenModal').on('hidden.bs.modal')
  },

  methods: {
    fechar() {
      $('#forbiddenModal').modal('hide');
    }
  }
};
</script>

<style>
</style>