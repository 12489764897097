var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vSelect",
    _vm._b(
      {
        attrs: { type: "text", options: _vm.options },
        on: {
          search: _vm.search,
          input: function ($event) {
            return _vm.$emit("input", _vm.emitValue)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "no-options",
            fn: function (ref) {
              var search = ref.search
              var searching = ref.searching
              return [
                searching && search.length >= 3
                  ? [
                      _vm._v(" Nada encontrado com "),
                      _c("em", [_vm._v(_vm._s(search))]),
                    ]
                  : _c("em", { staticStyle: { opacity: "0.5" } }, [
                      _vm._v("Escreva pelo menos "),
                      _c("strong", [_vm._v("3")]),
                      _vm._v(" caracteres"),
                    ]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.valor,
          callback: function ($$v) {
            _vm.valor = $$v
          },
          expression: "valor",
        },
      },
      "vSelect",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }