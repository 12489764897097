<template>
    <div class="home">
        <Topbar />
        <div class="container-fluid text-left" style="margin-top: 90px">
            <!-- Content Row -->
            <div class="card shadow mb-4">
                <div class="card-header bg-secondary py-3">
                    <h6 class="m-0 font-weight-bold text-white">
                        <i :class="`${title.icon} mr-2`"></i>{{ title.text }}
                        <div v-show="carregando" class="spinner-grow" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </h6>
                </div>
                <fieldset :disabled="carregando">
                    <div class="card-body bg-light text-primary-dark">
                        <p>
                            <a
                                class="btn btn-link"
                                data-toggle="collapse"
                                href="#filtrocard"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                            >
                                <i class="fas fa-filter"></i>
                                Filtro
                            </a>
                            <!-- <a
                                class="btn btn-link"
                                data-toggle="collapse"
                                href="#filtrocardImportacao"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                            >
                                <i class="fas fa-file-import"></i>
                                Importação
                            </a> -->
                            <router-link class="btn btn-link" :to="`/${baseUrl}/insert`"
                                ><i class="fa fa-fw fa-plus"></i> Adicionar
                            </router-link>
                            <button type="button" class="btn btn-primary mr-1" @click="exportar">
                                <i class="fas fa-file-csv"></i>
                                Exportar
                            </button>
                        </p>
                        <div class="collapse show" id="filtrocard">
                            <div class="card card-body bg-light">
                                <div class="form-row align-items-center">                                    
                                    <InputAndLabel
                                        column="3"
                                        name="pesquisa"
                                        label="Pesquisa"
                                        type="text"
                                        v-model="filtro.pesquisa"
                                    />
                                    <!-- <SelectAndLabel
                                        column="2"
                                        name="tipocob"
                                        label="Tipo Cobrança"
                                        v-model="filtro.tipocob"
                                        :options="combos.tipocob"
                                    /> -->
                                    <!-- <SelectAndLabel
                                        column="2"
                                        name="idcotacao"
                                        label="Cotação"
                                        v-model="filtro.idcotacao"
                                        :options="combos.cotacoes"
                                    />
                                    <SelectAndLabel
                                        column="2"
                                        name="idstatus"
                                        label="Status"
                                        v-model="filtro.idstatus"
                                        :options="combos.status"
                                    /> -->
                                    <input-and-label
                                        column="2"
                                        name="dataini"
                                        label="Data Início"
                                        v-model="filtro.dataini"
                                        type="date"
                                    />
                                    <input-and-label
                                        column="2"
                                        name="datafim"
                                        label="Data Fim"
                                        v-model="filtro.datafim"
                                        type="date"
                                    />
                                    <div class="form-group col-md-auto pt-md-2">
                                        <button
                                            type="submit"
                                            class="btn btn-primary form-control mt-md-4"
                                            id="pesquisar"
                                            @click="list"
                                        >
                                            Pesquisar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="collapse" id="filtrocardImportacao">
                            <div class="card card-body bg-light">
                                <div class="row">
                                    <div class="custom-file form-group col-md-5 ml-2 mt-2">
                                        <input type="hidden" name="arquivo" id="arquivo" v-model="arquivoNome" />
                                        <input
                                            type="file"
                                            class="custom-file-input"
                                            name="files[]"
                                            @change="handleEscolhaArquivo"
                                            id="userfile"
                                            multiple
                                        />
                                        <label
                                            class="custom-file-label"
                                            for="userfile"
                                            data-browse="Buscar"
                                            id="etiqueta"
                                        >
                                            {{ arquivoLabel }}</label
                                        >
                                    </div>
                                    <button
                                        type="submit"
                                        id="btn_save"
                                        name="btn_save"
                                        value="UPLOAD"
                                        class="btn btn-success ml-2 mt-2"
                                        @click="importarPlanilha"
                                        :disabled="arquivos == null"
                                    >
                                        Importar
                                    </button>
                                </div>
                            </div>
                        </div>

                        <hr />
                        <div class="row">
                            <div class="col-lg-12 table-responsive">
                                <table class="table table-sm table-striped table-bordered">
                                    <thead>
                                        <tr style="white-space: nowrap">
                                            <th></th>
                                            <TableHeader db="data" label="Data" />
                                            <TableHeader db="base" label="Base" />
                                            <TableHeader db="razaosocial" label="Razão Social" />
                                            <TableHeader db="cnpj" label="CNPJ" />
                                            <TableHeader db="fantasia" label="Fantasia" />
                                            <TableHeader classe="text-right" db="valorTotalFaturamento" label="Valor Total Faturamento" />
                                            <TableHeader classe="text-right" db="totalpicking" label="Total Picking" />
                                            <TableHeader classe="text-right" db="totalpacking" label="Total Packing" />
                                            <TableHeader classe="text-right" db="ticketMedio" label="Ticket Medio" />
                                            <TableHeader classe="text-right" db="totalitensestoque" label="Total Itens Estoque" />
                                            <TableHeader classe="text-right" db="valortotalestoque" label="Valor Total Estoque" />
                                            <TableHeader classe="text-right" db="itensRecebidos" label="Itens Recebidos" />
                                            <TableHeader classe="text-right" db="qtdEtiquetaEmitida" label="Qtd Etiqueta Emitida" />
                                            <TableHeader classe="text-right" db="itensclassificados" label="Itens Classificados" />
                                            <TableHeader classe="text-right" db="qtdevolumesreversa" label="Qtde Volumes Reversa" />
                                            <TableHeader classe="text-right" db="valototalinsumos" label="Valor Total Insumos" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="line in dados" :key="line[idname]" :style="{backgroundColor: '#'+line.statusbackground, color: '#'+line.statuscolor,}">
                                            <td>
                                                <router-link
                                                    :to="`/${baseUrl}/${line[idname]}`"
                                                    data-toggle="tooltip"
                                                    title="Editar/Visualizar"
                                                    ><i class="fa fa-edit"></i
                                                ></router-link>
                                            </td>
                                            <td>{{ dateFormat(line.data) }}</td>
                                            <td>{{ line.base }}</td>
                                            <td>{{ line.razaosocial }}</td>
                                            <td>{{ line.cnpj }}</td>
                                            <td>{{ line.fantasia }}</td>
                                            <td class="text-right">{{ decimalFormat(line.valortotalfaturamento || 0) }}</td>
                                            <td class="text-right">{{ intFormat(line.totalpicking || 0) }}</td>
                                            <td class="text-right">{{ intFormat(line.totalpacking || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(line.ticketmedio || 0) }}</td>
                                            <td class="text-right">{{ intFormat(line.totalitensestoque || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(line.valortotalestoque || 0) }}</td>
                                            <td class="text-right">{{ intFormat(line.itensrecebidos || 0) }}</td>
                                            <td class="text-right">{{ intFormat(line.qtdetiquetaemitida || 0) }}</td>
                                            <td class="text-right">{{ intFormat(line.itensclassificados || 0) }}</td>
                                            <td class="text-right">{{ intFormat(line.qtdevolumesreversa || 0) }}</td>
                                            <td class="text-right">{{ decimalFormat(line.valototalinsumos || 0) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <TableFooter />
                    </div>
                </fieldset>
            </div>
        </div>
        <ForbiddenModal />
        <LogoutModal />
    </div>
</template>

<script>
// @ is an alias to /src
import Topbar from "@/components/Topbar.vue";
import LogoutModal from "@/components/LogoutModal.vue";
import TableHeader from "@/components/TableHeader.vue";
import ViewMixin from "./ViewMixin";
import api from "../services/api";
import $ from "jquery";
import {
    ClientErrorException,
    DEFAULT_ERROR_MESSAGE,
    PermissaoException,
    ServerErrorException,
    TokenInvalidoException,
} from "../services/exceptions";
import { enterKey, dateFormat, getDate } from "../utils";
import ForbiddenModal from "../components/ForbiddenModal.vue";
import CertoErrado from "../components/CertoErrado.vue";
import TableFooter from "../components/TableFooter.vue";
import InputAndLabel from "../components/InputAndLabel.vue";
import SelectAndLabel from "../components/SelectAndLabel.vue";
import DateAndLabel from "../components/DateAndLabel.vue";

export default {
    name: "Resumos",
    mixins: [ViewMixin],
    components: {
        Topbar,
        LogoutModal,
        TableHeader,
        ForbiddenModal,
        CertoErrado,
        TableFooter,
        InputAndLabel,
        SelectAndLabel,
        DateAndLabel,
    },
    data: () => {
        return {
            idname: "idresumo",
            baseUrl: "resumos",
            title: {
                icon: "fas fa-clipboard-check",
                text: "Resumos",
            },
            sort: "idresumo",
            sortDirection: "desc",
            filtro: {
                pesquisa: "",
            },
            arquivoLabel: "Selecionar Arquivo",
            arquivoNome: "",
            arquivos: null,
        };
    },
    mounted() {
        this.populateFilterFromQueryString();

        this.filtro.dataini = this.filtro.dataini ?? getDate('firstDayOfMonth'),
        this.filtro.datafim = this.filtro.datafim ?? getDate('lastDayOfMonth'),
        this.getCombos();
        this.list();
    },
    methods: {
        enterKey,
        dateFormat,

        getTipocob(cob) {
            let arr = [];
            arr['f'] = 'Fixo';
            arr['m'] = 'Movimentação';
            arr['c'] = 'Composto';
            return arr[cob];
        },

        handleEscolhaArquivo(e) {
            if (e.target.files.length > 0) {
                this.arquivoLabel = "";
                for (let i = 0; i < e.target.files.length; i++) {
                    const file = e.target.files[i];
                    this.arquivoLabel += '"' + file.name + '" ';
                }
                this.arquivoNome = e.target.files[0].name;
                this.arquivos = e.target.files;
            } else {
                this.arquivoLabel = "Selecionar Arquivo";
                this.arquivos = null
            }
        },

        async getCombos() {
            this.carregando = true;

            try {
                // $("#filtrocard").collapse("hide");

                let { baseUrl } = this;
                let response = await api.get(`/${baseUrl}/combos`);

                this.combos = response.data;
            } catch (e) {
                if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    alert(e.message);
                } else {
                    alert(DEFAULT_ERROR_MESSAGE);
                }
            }

            this.carregando = false;
        },

        async list() {
            this.carregando = true;

            try {
                // $("#filtrocard").collapse("hide");

                let { baseUrl, sort, sortDirection, filtro, perPage, pular } = this;
                let params = {
                    sort: `${sort} ${sortDirection}`,
                    ...filtro,
                    perPage,
                    pular
                }
                let response = await api.get(`/${baseUrl}`, { params });

                this.dados = response.data.dados;
                this.totalRows = response.data.totalRows;

                this.changeURL(`/${this.baseUrl}?${this.encodeQueryData(params)}`);
            } catch (e) {
                if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    alert(e.message);
                } else {
                    alert(DEFAULT_ERROR_MESSAGE);
                }
            }

            this.carregando = false;
        },

        async importarPlanilha() {
            this.carregando = true;

            try {
                $("#filtrocardImportacao").collapse("hide");

                let { baseUrl, arquivos } = this;
                const formData = new FormData();
                for (let i = 0; i < arquivos.length; i++) {
                    formData.append(`files[${i}]`, arquivos[i]);
                }

                await api.post(
                    `/${baseUrl}/importar`,
                    formData,
                    { headers: { "content-type": "multipart/form-data" } }
                );

                this.dados = [];
                this.list();
            } catch (e) {
                if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    alert(e.message);
                } else {
                    alert(DEFAULT_ERROR_MESSAGE);
                    console.error(e);
                }
            }

            this.carregando = false;
        },

        async exportar() {
            try {
                const response = await this.apiExport();
                const today = new Date().toISOString().slice(0, 10);
                const fileName = `${this.title.text}_${today}.csv`;
                const blobURL = URL.createObjectURL(new File([response.data], fileName));
                const a = document.createElement("a");
                a.href = blobURL;
                a.style = "display: none";
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
            } catch (e) {
                console.error(e);
                if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ServerErrorException) {
                    alert(e.message);
                } else {
                    alert(DEFAULT_ERROR_MESSAGE);
                }
            }
            this.carregando = false;
        },

        async apiExport() {
            this.carregando = true;

            let { pular, perPage } = this;

            const response = await api.get(`/${this.baseUrl}/exportar`, {
                responseType: "blob",
                params: {
                    pular,
                    perPage,
                    sort: `${this.sort} ${this.sortDirection}`,
                    ...this.filtro,
                },
            });

            this.carregando = false;

            return response;
        },
    },
};
</script>

<style scoped lang="scss">
.spinner-grow {
    width: 1em;
    height: 1em;
}

@media only screen and (min-width: 768px) {
}
</style>
