var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Topbar"),
      _c(
        "div",
        {
          staticClass: "card shadow mx-3 conteudo text-left",
          staticStyle: { "margin-top": "90px" },
        },
        [
          _c("div", { staticClass: "card-header py-3" }, [
            _c(
              "h6",
              { staticClass: "m-0 font-weight-bold text-primary-dark" },
              [
                _c("i", { class: _vm.title.icon + " mr-2" }),
                _vm._v(_vm._s(_vm.title.text) + " "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.carregando,
                        expression: "carregando",
                      },
                    ],
                    staticClass: "spinner-grow",
                    attrs: { role: "status" },
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Loading..."),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-lg-12" },
                [
                  _c("Quadro", {
                    attrs: { type: _vm.messageType, message: _vm.message },
                  }),
                  _c("div", { staticClass: "d-print-none" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-secondary mr-1",
                        attrs: { id: "btn_voltar", disabled: false },
                        on: {
                          click: function ($event) {
                            return _vm.$router.go(-1)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-arrow-left" }),
                        _vm._v(" Voltar "),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary mr-1",
                        attrs: {
                          type: "button",
                          id: "btnSave",
                          disabled: _vm.carregando || !_vm.podemud,
                        },
                        on: { click: _vm.salvar },
                      },
                      [_vm._v(" Salvar ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger mr-1",
                        attrs: {
                          type: "button",
                          id: "btn_del",
                          disabled:
                            _vm.operation === "insert" ||
                            _vm.carregando ||
                            !_vm.podemud,
                          "data-toggle": "modal",
                          "data-target": "#modalExcluir",
                        },
                      },
                      [_vm._v(" Excluir ")]
                    ),
                    _c("hr"),
                  ]),
                  _vm.isDataLoaded()
                    ? _c(
                        "fieldset",
                        { attrs: { disabled: _vm.carregando || !_vm.podemud } },
                        [
                          _c("form", [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-lg-12" }, [
                                _c("fieldset", [
                                  _c(
                                    "div",
                                    { staticClass: "form-row" },
                                    [
                                      _c("InputAndLabel", {
                                        attrs: {
                                          column: "1",
                                          name: "id",
                                          label: "ID",
                                          type: "text",
                                          readonly: "",
                                        },
                                        model: {
                                          value: _vm.dados[_vm.idname].value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dados[_vm.idname],
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "dados[idname].value",
                                        },
                                      }),
                                      _c("InputAndLabel", {
                                        attrs: {
                                          column: "1",
                                          name: "data",
                                          label: "Data",
                                          type: "date",
                                          errorMessage: _vm.errors.data,
                                        },
                                        model: {
                                          value: _vm.dados.data.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dados.data,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "dados.data.value",
                                        },
                                      }),
                                      _c("SelectAndLabel", {
                                        attrs: {
                                          column: "4",
                                          name: "idcontrato",
                                          label: "Contrato",
                                          options: _vm.combos.idcontrato,
                                          errorMessage: _vm.errors.idcontrato,
                                          input: "",
                                          disabled:
                                            _vm.carregando || !_vm.podemud,
                                        },
                                        model: {
                                          value: _vm.dados.idcontrato.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dados.idcontrato,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "dados.idcontrato.value",
                                        },
                                      }),
                                      _c("SelectAndLabel", {
                                        attrs: {
                                          column: "3",
                                          name: "idclientescob",
                                          label: "Cliente Cobrança",
                                          options: _vm.combos.idclientescob,
                                          errorMessage:
                                            _vm.errors.idclientescob,
                                        },
                                        model: {
                                          value: _vm.dados.idclientescob.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dados.idclientescob,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dados.idclientescob.value",
                                        },
                                      }),
                                      _c("SelectAndLabel", {
                                        attrs: {
                                          column: "3",
                                          name: "idtabcob",
                                          label: "Tabela",
                                          options: _vm.combos.idtabcob,
                                          errorMessage: _vm.errors.idtabcob,
                                        },
                                        model: {
                                          value: _vm.dados.idtabcob.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dados.idtabcob,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "dados.idtabcob.value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-6 pr-2" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card mb-3" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card-header" },
                                              [_vm._v("Parâmetros")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row mt-3 mx-3",
                                              },
                                              [
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "gmv",
                                                    label: "Valor GMV",
                                                    type: "number",
                                                    format: "decimal",
                                                    min: "0",
                                                    step: "0.01",
                                                    errorMessage:
                                                      _vm.errors.gmv,
                                                    right: "",
                                                  },
                                                  model: {
                                                    value: _vm.dados.gmv.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados.gmv,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dados.gmv.value",
                                                  },
                                                }),
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "takerate",
                                                    label: "Take Rate %",
                                                    type: "number",
                                                    format: "decimal",
                                                    min: "0",
                                                    step: "0.01",
                                                    errorMessage:
                                                      _vm.errors.takerate,
                                                    right: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dados.takerate.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados.takerate,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dados.takerate.value",
                                                  },
                                                }),
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "ticketmedio",
                                                    label: "Valor Ticket Médio",
                                                    type: "number",
                                                    format: "decimal",
                                                    min: "0",
                                                    step: "0.01",
                                                    errorMessage:
                                                      _vm.errors.ticketmedio,
                                                    right: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dados.ticketmedio
                                                        .value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados.ticketmedio,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dados.ticketmedio.value",
                                                  },
                                                }),
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "itensrecebidos",
                                                    label:
                                                      "Qtde Itens Recebidos",
                                                    type: "number",
                                                    format: "integer",
                                                    min: "0",
                                                    errorMessage:
                                                      _vm.errors.itensrecebidos,
                                                    right: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dados.itensrecebidos
                                                        .value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados
                                                          .itensrecebidos,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dados.itensrecebidos.value",
                                                  },
                                                }),
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "totalitensestoque",
                                                    label:
                                                      "Qtde Total Itens Estoque",
                                                    type: "number",
                                                    format: "integer",
                                                    min: "0",
                                                    errorMessage:
                                                      _vm.errors
                                                        .totalitensestoque,
                                                    right: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dados
                                                        .totalitensestoque
                                                        .value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados
                                                          .totalitensestoque,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dados.totalitensestoque.value",
                                                  },
                                                }),
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "valortotalestoque",
                                                    label:
                                                      "Valor Total Estoque",
                                                    type: "number",
                                                    format: "decimal",
                                                    min: "0",
                                                    step: "0.01",
                                                    errorMessage:
                                                      _vm.errors
                                                        .valortotalestoque,
                                                    right: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dados
                                                        .valortotalestoque
                                                        .value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados
                                                          .valortotalestoque,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dados.valortotalestoque.value",
                                                  },
                                                }),
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "valorreversa",
                                                    label: "Valor Reversa",
                                                    type: "number",
                                                    format: "decimal",
                                                    min: "0",
                                                    errorMessage:
                                                      _vm.errors.valorreversa,
                                                    right: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dados.valorreversa
                                                        .value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados.valorreversa,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dados.valorreversa.value",
                                                  },
                                                }),
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "qtdereversa",
                                                    label: "Qtde Reversa",
                                                    type: "number",
                                                    format: "integer",
                                                    min: "0",
                                                    errorMessage:
                                                      _vm.errors.qtdereversa,
                                                    right: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dados.qtdereversa
                                                        .value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados.qtdereversa,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dados.qtdereversa.value",
                                                  },
                                                }),
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "valoritensclass",
                                                    label: "Valor Itens Class.",
                                                    type: "number",
                                                    format: "decimal",
                                                    min: "0",
                                                    errorMessage:
                                                      _vm.errors
                                                        .valoritensclass,
                                                    right: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dados.valoritensclass
                                                        .value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados
                                                          .valoritensclass,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dados.valoritensclass.value",
                                                  },
                                                }),
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "qtdeitensclass",
                                                    label: "Qtde Itens Class.",
                                                    type: "number",
                                                    format: "integer",
                                                    min: "0",
                                                    errorMessage:
                                                      _vm.errors.qtdeitensclass,
                                                    right: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dados.qtdeitensclass
                                                        .value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados
                                                          .qtdeitensclass,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dados.qtdeitensclass.value",
                                                  },
                                                }),
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "totalpicking",
                                                    label: "Qtde Total Picking",
                                                    type: "number",
                                                    format: "integer",
                                                    min: "0",
                                                    errorMessage:
                                                      _vm.errors.totalpicking,
                                                    right: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dados.totalpicking
                                                        .value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados.totalpicking,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dados.totalpicking.value",
                                                  },
                                                }),
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "totalpacking",
                                                    label: "Qtde Total Packing",
                                                    type: "number",
                                                    format: "integer",
                                                    min: "0",
                                                    errorMessage:
                                                      _vm.errors.totalpacking,
                                                    right: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dados.totalpacking
                                                        .value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados.totalpacking,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dados.totalpacking.value",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-6 pl-2" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card mb-3" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card-header" },
                                              [_vm._v("Totalizadores")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row mt-3 mx-3 justify-content-end",
                                              },
                                              [
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "faturamento",
                                                    label: "Faturamento",
                                                    type: "number",
                                                    format: "decimal",
                                                    min: "0",
                                                    step: "0.01",
                                                    errorMessage:
                                                      _vm.errors.faturamento,
                                                    right: "",
                                                    readonly: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dados.faturamento
                                                        .value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados.faturamento,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dados.faturamento.value",
                                                  },
                                                }),
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "totalreversa",
                                                    label: "Total Reversa",
                                                    type: "number",
                                                    format: "decimal",
                                                    min: "0",
                                                    step: "0.01",
                                                    errorMessage:
                                                      _vm.errors.totalreversa,
                                                    right: "",
                                                    readonly: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dados.totalreversa
                                                        .value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados.totalreversa,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dados.totalreversa.value",
                                                  },
                                                }),
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "totalitensclass",
                                                    label: "Total Itens Class.",
                                                    type: "number",
                                                    format: "decimal",
                                                    min: "0",
                                                    step: "0.01",
                                                    errorMessage:
                                                      _vm.errors
                                                        .totalitensclass,
                                                    right: "",
                                                    readonly: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dados.totalitensclass
                                                        .value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados
                                                          .totalitensclass,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dados.totalitensclass.value",
                                                  },
                                                }),
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "excedente",
                                                    label: "Excedente",
                                                    type: "number",
                                                    format: "decimal",
                                                    min: "0",
                                                    step: "0.01",
                                                    errorMessage:
                                                      _vm.errors.excedente,
                                                    right: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dados.excedente.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados.excedente,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dados.excedente.value",
                                                  },
                                                }),
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "movarm",
                                                    label: "Mov. / Arm.",
                                                    type: "number",
                                                    format: "decimal",
                                                    min: "0",
                                                    step: "0.01",
                                                    readonly: "",
                                                    right: "",
                                                  },
                                                  model: {
                                                    value: _vm.dados.movarm,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados,
                                                        "movarm",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "dados.movarm",
                                                  },
                                                }),
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "insumos",
                                                    label: "Insumos",
                                                    type: "number",
                                                    format: "decimal",
                                                    min: "0",
                                                    step: "0.01",
                                                    errorMessage:
                                                      _vm.errors.insumos,
                                                    right: "",
                                                    readonly: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dados.insumos.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados.insumos,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dados.insumos.value",
                                                  },
                                                }),
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "totalocorrencias",
                                                    label: "Ocorrências",
                                                    type: "number",
                                                    format: "decimal",
                                                    min: "0",
                                                    step: "0.01",
                                                    errorMessage:
                                                      _vm.errors
                                                        .totalocorrencias,
                                                    right: "",
                                                    readonly: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dados.totalocorrencias
                                                        .value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados
                                                          .totalocorrencias,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dados.totalocorrencias.value",
                                                  },
                                                }),
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "valoradicional",
                                                    label: "Valor Adicional",
                                                    type: "number",
                                                    format: "decimal",
                                                    min: "0",
                                                    step: "0.01",
                                                    errorMessage:
                                                      _vm.errors.valoradicional,
                                                    right: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dados.valoradicional
                                                        .value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados
                                                          .valoradicional,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dados.valoradicional.value",
                                                  },
                                                }),
                                                _c("InputAndLabel", {
                                                  attrs: {
                                                    column: "3",
                                                    name: "total",
                                                    label: "Total",
                                                    type: "number",
                                                    format: "decimal",
                                                    min: "0",
                                                    step: "0.01",
                                                    errorMessage:
                                                      _vm.errors.total,
                                                    right: "",
                                                    readonly: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dados.total.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dados.total,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dados.total.value",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("div", { staticClass: "col-lg-6 mt-4" }, [
                                _vm.operation == "update"
                                  ? _c("div", { staticClass: "card shadow" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "card-header bg-secondary py-3",
                                        },
                                        [
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "m-0 font-weight-bold text-white row",
                                            },
                                            [
                                              _vm._m(0),
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: _vm.carregando,
                                                      expression: "carregando",
                                                    },
                                                  ],
                                                  staticClass: "spinner-grow",
                                                  attrs: { role: "status" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "sr-only" },
                                                    [_vm._v("Loading...")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "p-0 card-body" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-lg-12 table-responsive",
                                            },
                                            [
                                              _c(
                                                "table",
                                                {
                                                  staticClass:
                                                    "table table-sm table-striped table-bordered my-3",
                                                },
                                                [
                                                  _c("thead", [
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticStyle: {
                                                          "white-space":
                                                            "nowrap",
                                                        },
                                                      },
                                                      [
                                                        _c("th", [
                                                          _vm.podemud
                                                            ? _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn btn-primary",
                                                                  attrs: {
                                                                    type: "button",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function () {
                                                                        return _vm.abrirModalMov(
                                                                          "insert"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Lançar "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]),
                                                        _c("th", [
                                                          _vm._v("Tipo"),
                                                        ]),
                                                        _c("th", [
                                                          _vm._v("Nome"),
                                                        ]),
                                                        _c("th", [
                                                          _vm._v("Custo"),
                                                        ]),
                                                        _c("th", [
                                                          _vm._v("Qtde Valor"),
                                                        ]),
                                                        _c("th", [
                                                          _vm._v("Total"),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "tbody",
                                                    [
                                                      _vm._l(
                                                        _vm.dados.movimentos
                                                          .dados,
                                                        function (line) {
                                                          return _c(
                                                            "tr",
                                                            {
                                                              key:
                                                                "movimento-" +
                                                                line.idcobranca_mov,
                                                            },
                                                            [
                                                              _c("td", [
                                                                _vm.podemud
                                                                  ? _c(
                                                                      "a",
                                                                      {
                                                                        staticClass:
                                                                          "text-primary",
                                                                        attrs: {
                                                                          "data-toggle":
                                                                            "tooltip",
                                                                          title:
                                                                            "Editar/Visualizar",
                                                                          role: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function () {
                                                                              return _vm.abrirModalMov(
                                                                                line.idcobranca_mov
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "fa fa-edit",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.podemud
                                                                  ? _c(
                                                                      "a",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger",
                                                                        attrs: {
                                                                          "data-toggle":
                                                                            "tooltip",
                                                                          title:
                                                                            "Excluir",
                                                                          role: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function () {
                                                                              return _vm.abrirModalDeleteMov(
                                                                                line.idcobranca_mov
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "fas fa-trash-alt",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]),
                                                              line.nomeindex ==
                                                              0
                                                                ? _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-center align-middle",
                                                                      attrs: {
                                                                        rowspan:
                                                                          line.totalspan,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            line.tipo
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    line.nome
                                                                  )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-right",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.decimalFormat(
                                                                          line.custo
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-right",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.decimalFormat(
                                                                          line.qtdeval
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-right",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.decimalFormat(
                                                                          line.total
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      _c(
                                                        "tr",
                                                        {
                                                          staticClass:
                                                            "table-success",
                                                        },
                                                        [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "text-center align-middle",
                                                              attrs: {
                                                                colspan: 5,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.dados
                                                                      .movimentos
                                                                      .totais
                                                                      .tipo
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "text-right",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.decimalFormat(
                                                                      _vm.dados
                                                                        .movimentos
                                                                        .totais
                                                                        .total
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "col-lg-6 mt-4" }, [
                                _vm.operation == "update"
                                  ? _c("div", { staticClass: "card shadow" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "card-header bg-secondary py-3",
                                        },
                                        [
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "m-0 font-weight-bold text-white row",
                                            },
                                            [
                                              _vm._m(1),
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: _vm.carregando,
                                                      expression: "carregando",
                                                    },
                                                  ],
                                                  staticClass: "spinner-grow",
                                                  attrs: { role: "status" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "sr-only" },
                                                    [_vm._v("Loading...")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "p-0 card-body" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-lg-12 table-responsive",
                                            },
                                            [
                                              _c(
                                                "table",
                                                {
                                                  staticClass:
                                                    "table table-sm table-striped table-bordered my-3",
                                                },
                                                [
                                                  _c("thead", [
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticStyle: {
                                                          "white-space":
                                                            "nowrap",
                                                        },
                                                      },
                                                      [
                                                        _c("th", [
                                                          _vm.podemud
                                                            ? _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn btn-primary",
                                                                  attrs: {
                                                                    type: "button",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function () {
                                                                        return _vm.abrirModalInsumo(
                                                                          "insert"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Lançar "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]),
                                                        _c("th", [
                                                          _vm._v("Insumo"),
                                                        ]),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-right",
                                                          },
                                                          [_vm._v("Qtde.")]
                                                        ),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-right",
                                                          },
                                                          [_vm._v("Valor")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "tbody",
                                                    [
                                                      _vm._l(
                                                        _vm.dados._insumos
                                                          .dados,
                                                        function (line) {
                                                          return _c(
                                                            "tr",
                                                            {
                                                              key:
                                                                "insumo-" +
                                                                line.idcobranca_insumos,
                                                            },
                                                            [
                                                              _c("td", [
                                                                _vm.podemud
                                                                  ? _c(
                                                                      "a",
                                                                      {
                                                                        staticClass:
                                                                          "text-primary",
                                                                        attrs: {
                                                                          "data-toggle":
                                                                            "tooltip",
                                                                          title:
                                                                            "Editar/Visualizar",
                                                                          role: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function () {
                                                                              return _vm.abrirModalInsumo(
                                                                                line.idcobranca_insumos
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "fa fa-edit",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.podemud
                                                                  ? _c(
                                                                      "a",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger",
                                                                        attrs: {
                                                                          "data-toggle":
                                                                            "tooltip",
                                                                          title:
                                                                            "Excluir",
                                                                          role: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function () {
                                                                              return _vm.abrirModalDeleteInsumo(
                                                                                line.idcobranca_insumos
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "fas fa-trash-alt",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    line.insumo
                                                                  )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-right",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.decimalFormat(
                                                                          line.qtde
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-right",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.decimalFormat(
                                                                          line.valor
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      _c(
                                                        "tr",
                                                        {
                                                          staticClass:
                                                            "table-success",
                                                        },
                                                        [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "text-center align-middle",
                                                              attrs: {
                                                                colspan: 3,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.dados
                                                                      ._insumos
                                                                      .totais
                                                                      .tipo
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "text-right",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.decimalFormat(
                                                                      _vm.dados
                                                                        ._insumos
                                                                        .totais
                                                                        .total
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "col-lg-6 mt-4" }, [
                                _vm.operation == "update"
                                  ? _c("div", { staticClass: "card shadow" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "card-header bg-secondary py-3",
                                        },
                                        [
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "m-0 font-weight-bold text-white row",
                                            },
                                            [
                                              _vm._m(2),
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: _vm.carregando,
                                                      expression: "carregando",
                                                    },
                                                  ],
                                                  staticClass: "spinner-grow",
                                                  attrs: { role: "status" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "sr-only" },
                                                    [_vm._v("Loading...")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "p-0 card-body" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-lg-12 table-responsive",
                                            },
                                            [
                                              _c(
                                                "table",
                                                {
                                                  staticClass:
                                                    "table table-sm table-striped table-bordered my-3",
                                                },
                                                [
                                                  _c("thead", [
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticStyle: {
                                                          "white-space":
                                                            "nowrap",
                                                        },
                                                      },
                                                      [
                                                        _c("th", [
                                                          _vm.podemud
                                                            ? _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn btn-primary",
                                                                  attrs: {
                                                                    type: "button",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function () {
                                                                        return _vm.abrirModalOcorrencia(
                                                                          "insert"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Lançar "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]),
                                                        _c("th", [
                                                          _vm._v("Ocorrencia"),
                                                        ]),
                                                        _c("th", [
                                                          _vm._v("Motivo"),
                                                        ]),
                                                        _c("th", [
                                                          _vm._v("Data"),
                                                        ]),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-right",
                                                          },
                                                          [_vm._v("Qtde.")]
                                                        ),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-right",
                                                          },
                                                          [_vm._v("Valor")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "tbody",
                                                    [
                                                      _vm._l(
                                                        _vm.dados._ocorrencias
                                                          .dados,
                                                        function (line) {
                                                          return _c(
                                                            "tr",
                                                            {
                                                              key:
                                                                "ocorrencia-" +
                                                                line.idcobranca_ocorrencias,
                                                            },
                                                            [
                                                              _c("td", [
                                                                _vm.podemud
                                                                  ? _c(
                                                                      "a",
                                                                      {
                                                                        staticClass:
                                                                          "text-primary",
                                                                        attrs: {
                                                                          "data-toggle":
                                                                            "tooltip",
                                                                          title:
                                                                            "Editar/Visualizar",
                                                                          role: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function () {
                                                                              return _vm.abrirModalOcorrencia(
                                                                                line.idcobranca_ocorrencias
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "fa fa-edit",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.podemud
                                                                  ? _c(
                                                                      "a",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger",
                                                                        attrs: {
                                                                          "data-toggle":
                                                                            "tooltip",
                                                                          title:
                                                                            "Excluir",
                                                                          role: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function () {
                                                                              return _vm.abrirModalDeleteOcorrencia(
                                                                                line.idcobranca_ocorrencias
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "fas fa-trash-alt",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    line.ocorrencia
                                                                  )
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    line.motivo
                                                                  )
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.dateFormat(
                                                                      line.data
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-right",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.decimalFormat(
                                                                          line.qtde
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-right",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.decimalFormat(
                                                                          line.valor
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      _c(
                                                        "tr",
                                                        {
                                                          staticClass:
                                                            "table-success",
                                                        },
                                                        [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "text-center align-middle",
                                                              attrs: {
                                                                colspan: 5,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.dados
                                                                      ._ocorrencias
                                                                      .totais
                                                                      .tipo
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "text-right",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.decimalFormat(
                                                                      _vm.dados
                                                                        ._ocorrencias
                                                                        .totais
                                                                        .total
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm.operation == "update"
        ? _c(
            "Modal",
            {
              attrs: {
                id: "modalOcorrencia",
                title: "Ocorrencia",
                confirmClass: "btn btn-danger d-none",
                confirmFunction: function () {
                  return null
                },
                noFooter: "",
              },
            },
            [
              _vm.isDataLoaded()
                ? _c("CobrancaOcorrencia", {
                    attrs: {
                      id: _vm.ocorrenciaSelecionado,
                      parentsetOcorrenciaSelecionado:
                        _vm.setOcorrenciaSelecionado,
                      idparent: _vm.dados[_vm.idname].value,
                      parentBaseUrl: _vm.baseUrl,
                      parentGetData: _vm.getData,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.operation == "update"
        ? _c(
            "Modal",
            {
              attrs: {
                id: "modalInsumo",
                title: "Insumo",
                confirmClass: "btn btn-danger d-none",
                confirmFunction: function () {
                  return null
                },
                noFooter: "",
              },
            },
            [
              _vm.isDataLoaded()
                ? _c("CobrancaInsumo", {
                    attrs: {
                      id: _vm.insumoSelecionado,
                      parentsetInsumoSelecionado: _vm.setInsumoSelecionado,
                      idparent: _vm.dados[_vm.idname].value,
                      parentBaseUrl: _vm.baseUrl,
                      parentGetData: _vm.getData,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.operation == "update"
        ? _c(
            "Modal",
            {
              attrs: {
                id: "modalMov",
                title: "Movimento",
                confirmClass: "btn btn-danger d-none",
                confirmFunction: function () {
                  return null
                },
                noFooter: "",
              },
            },
            [
              _vm.isDataLoaded()
                ? _c("CobrancaMov", {
                    attrs: {
                      id: _vm.movSelecionada,
                      parentSetMovSelecionada: _vm.setMovSelecionada,
                      idparent: _vm.dados[_vm.idname].value,
                      parentBaseUrl: _vm.baseUrl,
                      parentGetData: _vm.getData,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "Modal",
        {
          attrs: {
            id: "modalDeleteInsumo",
            title: "Confirmar Exclusão",
            confirmClass: "btn btn-danger",
            confirmText: "Excluir",
            confirmFunction: _vm.deleteInsumo,
          },
        },
        [_vm._v(" Tem certeza que deseja excluir este insumo? ")]
      ),
      _c(
        "Modal",
        {
          attrs: {
            id: "modalDeleteOcorrencia",
            title: "Confirmar Exclusão",
            confirmClass: "btn btn-danger",
            confirmText: "Excluir",
            confirmFunction: _vm.deleteOcorrencia,
          },
        },
        [_vm._v(" Tem certeza que deseja excluir este ocorrencia? ")]
      ),
      _c(
        "Modal",
        {
          attrs: {
            id: "modalDeleteMov",
            title: "Confirmar Exclusão",
            confirmClass: "btn btn-danger",
            confirmText: "Excluir",
            confirmFunction: _vm.deleteMov,
          },
        },
        [_vm._v(" Tem certeza que deseja excluir este movimento? ")]
      ),
      _c(
        "Modal",
        {
          attrs: {
            id: "modalExcluir",
            title: "Confirmar Exclusão",
            confirmClass: "btn btn-danger",
            confirmText: "Excluir",
            confirmFunction: _vm.deletar,
          },
        },
        [_vm._v(" Tem certeza que deseja excluir esta cobrança? ")]
      ),
      _c("ForbiddenModal"),
      _c("LogoutModal"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "align-self-center" }, [
      _c("i", { staticClass: "fas fa-exchange-alt" }),
      _vm._v(" Movimentos "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "align-self-center" }, [
      _c("i", { staticClass: "fas fa-pallet" }),
      _vm._v(" Insumos "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "align-self-center" }, [
      _c("i", { staticClass: "fas fa-tags" }),
      _vm._v(" Ocorrências "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }