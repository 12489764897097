var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home-view" },
    [_c("Topbar"), _vm._m(0), _c("LogoutModal")],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "container-img", staticStyle: { "padding-top": "90px" } },
      [
        _c("img", {
          attrs: { src: require("../assets/logo.svg"), alt: "logo" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }