<template>
  <div class="login-view">
    <div class="card bg-light">
      <div class="logo w-50">
        <img src="../assets/logo.svg" alt="logo" width="108"/>
      </div>
      <div class="container-formulario">
        <div class="formulario">
          <fieldset :disabled="carregando">
            <h3>Identificação de Usuário</h3>
            <div v-show="errorMessage" class="alert alert-danger" role="alert">
              {{ errorMessage }}
            </div>
            <div class="form-group">
              <input
                @keydown.enter="(e) => enterKey(e)"
                v-model="data.login"
                type="text"
                id="login"
                name="login"
                :class="
                  'form-control text-center ' +
                  (errors.login ? 'is-invalid' : '')
                "
                placeholder="Login"
              />
              <div v-if="errors.login" class="invalid-feedback d-block">
                {{ errors.login[0] }}
              </div>
            </div>
            <div class="form-group">
              <input
                @keydown.enter="(e) => enterKey(e)"
                v-model="data.senha"
                type="password"
                id="password"
                name="password"
                :class="
                  'form-control text-center ' +
                  (errors.senha ? 'is-invalid' : '')
                "
                placeholder="Senha"
              />
              <div v-if="errors.senha" class="invalid-feedback d-block">
                {{ errors.senha[0] }}
              </div>
            </div>
            <button
              type="button"
              class="btn btn-primary btn-block"
              @click="handleLogin"
            >
              Entrar
              <div v-show="carregando" class="spinner-grow" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { enterKey, focar } from "../utils";
import {
  DEFAULT_ERROR_MESSAGE,  
  ServerErrorException,
  ValidationException,
} from "../services/exceptions";
import api, { getRotaDeRetorno, setAdminToken, setUsername } from "../services/api";

export default {
  name: "Login",
  data: function () {
    return {
      carregando: false,
      data: {
        login: "",
        senha: "",
      },
      errors: {},
      errorMessage: "",
    };
  },

  mounted() {
    focar('#login')
  },

  methods: {
    enterKey,

    async handleLogin() {
      this.carregando = true;

      try {
        let data = await this.apiLogin();
        setAdminToken(data.token);
        setUsername(data.username);

        let pushRoute = getRotaDeRetorno();
        if (!pushRoute || pushRoute == '/login') {
          pushRoute = "/home";
        }
        this.$router.push(pushRoute);
      } catch (e) {
        if (e instanceof ValidationException) {
          this.handleValidationException(e);          
          this.data.senha = "";
          focar('#password');
        } else if (e instanceof ServerErrorException) {
          this.errorMessage = e.message;
        } else {
          this.errorMessage = DEFAULT_ERROR_MESSAGE;
        }
      } finally {
        this.carregando = false;
      }
    },

    async apiLogin() {
      let response = await api.post("login", this.data);
      let data = response.data;

      return data;
    },

    handleValidationException(e) {
      this.errors = e.errors;
      this.errorMessage = e.errorMessage;

      let campos = Object.keys(e.errors);
      if (campos.length > 1) {
        focar("#" + campos[0]);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.login-view {
  background-color: #20466B;
  height: 100%;
  padding-top: 7.5vh;
}

.login-view .card {
  flex-direction: row !important;

  margin: 0 7.5vw;
}

.logo {
  display: none;
}

img {
  filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.5));
}

.container-formulario {
  width: 100%;
}

.formulario {
  margin: 3em;
}

h3 {
  margin-bottom: 1em !important;
}

input,
button {
  font-size: 1.2em !important;
  border-radius: 25px !important;
}

.spinner-grow {
  width: 1em;
  height: 1em;
}

@media only screen and (min-width: 768px) {
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;

    border-right: 1px solid rgba(0, 0, 0, 0.125);
  }

  .container-formulario {
    width: 50%;
  }

  .login-view .card {
    flex-direction: row !important;

    margin: 0 24vw;
  }
}
</style>
