var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.message == null || _vm.message != "",
          expression: "message == null || message != ''",
        },
      ],
      class: "alert alert-" + _vm.type,
      attrs: { id: "mensagem" },
    },
    _vm._l(_vm.generateTokens(), function (token, index) {
      return _c("span", { key: "quadro-texto-" + index }, [
        token.match(/(\[.*\]\(.*\))/g)
          ? _c(
              "span",
              [
                _c(
                  "router-link",
                  {
                    attrs: { to: token.match(/\[(.*)\]\((.*)\)/)[2] },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.$router.go()
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(token.match(/\[(.*)\]\((.*)\)/)[1]) + " "
                    ),
                  ]
                ),
              ],
              1
            )
          : _c("span", [_vm._v(" " + _vm._s(token) + " ")]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }