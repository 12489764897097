var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.carregando
      ? _c("input", {
          attrs: { type: "checkbox" },
          domProps: { checked: _vm._aprov },
          on: { change: _vm.handleAprovadoChange },
        })
      : _c(
          "div",
          {
            staticClass: "spinner-grow text-primary",
            attrs: { role: "status" },
          },
          [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }